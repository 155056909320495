import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BANK_DETAILS } from 'src/app/services/constants';

@Component({
  selector: 'app-quotation-popup',
  templateUrl: './quotation-popup.component.html',
  styleUrls: ['./quotation-popup.component.scss']
})
export class QuotationPopupComponent implements OnInit {
  quotationForm: any = FormGroup;
  error: boolean = false;
  amount: any = 0;
  amountAdded: boolean = true;
  minAmount: number = 5000;
  invalid: boolean = false;
  bankDetails: any;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.quotationForm = this.formBuilder.group({
      quotationAmount: ['', Validators.pattern(/^\d+(\.\d{1,2})?$/)],
    });
    this.bankDetails = BANK_DETAILS
  }

  onNoClick(click): void {
    let data = {
      type: click,
      quotationAmount: this.amount
    }
    this.dialogRef.close(data);
    this.quotationForm.get('quotationAmount').reset();
  }

  somethingChanged(event: any): void {
    this.amount = event.target.value;
    this.amountAdded = true;
    this.invalid = false;
    const pattern = /^(\d+(\.\d{0,2})?)$/;
    if (this.amount < this.minAmount) {
      this.error = false;
      this.invalid = true;
    }
    else if (this.amount) {
      if (!pattern.test(this.amount)) {
        this.error = true;
      } else this.error = false;
    } else {
      this.amountAdded = true;
      this.error = false;
    }
  }

}
