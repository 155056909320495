
    <div class="main-wrapper">
    <div class="navigation-wrapper">
      <div class="keen-slider keen-slider--vertical" #sliderRef>
        <div class="keen-slider__slide number-slide1">
          <h1>Monitoring earth from space<br/>
           through Sensor-as-a-service
          </h1>
        </div>
        <div class="keen-slider__slide number-slide2">
          <h1>On-demand satellite data<br/> at your fingertips</h1>  
        </div>
        <div class="keen-slider__slide number-slide3">
          <h1>Responsive satellite tasking <br/>at your fingertips</h1>  
        </div>
      </div>
      
    </div>

    <div class="main-page">
      <div class="left mobile-view">
        <div class="slider-bottom">
          <div class="bottom" *ngIf="!isInIframe">
              <img src="../../../assets/images/slider-logo.png" alt="logo" />
            </div> 
          <div class="dots">
            <button
              (click)="slider.moveToIdx(i)"
              *ngFor="let slide of dotHelper; let i = index"
              [class]="'dot ' + (i === currentSlide ? 'active' : '')"
            ></button>
          </div>
        </div>
      </div>
    <main class="flex-shrink-0 container">
      <div class="left desktop-view">
        <div class="slider-bottom">
          <div class="bottom" *ngIf="!isInIframe">
              <img src="../../../assets/images/slider-logo.png" alt="logo" />
            </div> 
          <div class="dots">
            <button
              (click)="slider.moveToIdx(i)"
              *ngFor="let slide of dotHelper; let i = index"
              [class]="'dot ' + (i === currentSlide ? 'active' : '')"
            ></button>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="container-form">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
    <footer class="footer">
      <app-footer></app-footer>
    </footer>
    </div>
  </div>
