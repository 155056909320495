<div class="table-head buttons">
    <button mat-flat-button style="height: 40px;" (click)="allocateSensor()" [disabled]="totalAllocation >= 100 || topUpAmount === 0">
        <span fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="5px">
            <span class="material-icons-outlined">
                add
            </span>
            <span>Add</span>
        </span>
    </button>
</div>

<div class="division">

    <div id="scrollcustom" class="scrollbar">
        <div *ngIf="tablelength === 0" class="container no-task">
            <img *ngIf="!darkTheme" src="../../assets/images/notask.svg">
            <img *ngIf="darkTheme" src="../../assets/images/whitelogo.png" width="80px">
            <div style="display: flex; flex-direction: column; padding-top: 10px;">
                <span class="logo">You are yet to allocate funds to your preferred sensors.<br/>
                     You can start the process by clicking on the Add button.</span>
            </div>
        </div>
        <mat-card class="card mat-elevation-z1" *ngIf="tablelength > 0">
            <table mat-table [dataSource]="dataSource" matSort class="table-class">

                <ng-container matColumnDef="sensorType">
                    <th mat-header-cell *matHeaderCellDef>Sensor Type</th>
                    <td mat-cell *matCellDef="let row">{{ row.sensor }}</td>
                </ng-container>

                <ng-container matColumnDef="sensorName">
                    <th mat-header-cell *matHeaderCellDef>Sensor Name</th>
                    <td mat-cell *matCellDef="let row">{{ row.operator }} {{row.sensor === "Stereo" && row.isTristereo ? ' - Tristereo ' : row.sensor === "Stereo" ? ' - Stereo ': ''}}  <span *ngIf="row.resolution"> ({{
                        row.resolution
                        }}) </span></td>
                </ng-container>

                <ng-container matColumnDef="taskingTier">
                    <th mat-header-cell *matHeaderCellDef>Tasking Tier</th>
                    <td mat-cell *matCellDef="let row" style="text-transform: capitalize;">{{ row.taskingTier }}</td>
                </ng-container>

                <ng-container matColumnDef="allocation">
                    <th mat-header-cell *matHeaderCellDef>Allocation</th>
                    <td mat-cell *matCellDef="let row">{{ row.amountPercentage }} %</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>Amount</th>
                    <td mat-cell *matCellDef="let row">$ {{ row.amountDollar | customNumberFormat }}</td>
                </ng-container>

                <ng-container matColumnDef="numberOfTasks">
                    <th mat-header-cell *matHeaderCellDef>Number of Tasks</th>
                    <td mat-cell *matCellDef="let row"> {{ row.numberOfTasks }}</td>
                </ng-container>


                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="align-text">Edit</th>
                    <td mat-cell *matCellDef="let row" class="align-text">
                        <mat-icon class="icons material-icons-outlined" (click)="editAllocation(row)" style="cursor: pointer;">
                            edit
                        </mat-icon>
                    </td>
                </ng-container>


                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="align-text">Delete</th>
                    <td mat-cell *matCellDef="let row" class="align-text">
                        <img [src]="deletedImagePath" alt="" (click)="deleteAllocation(row)" style="cursor: pointer;">
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
    </div>

    <div class="btns" *ngIf="tablelength > 0">
        <button class="custom-button" mat-flat-button routerLink="/task" (click)="dropAllocation();removeItem()">
            Cancel
        </button>
        <button class="proceed-button" mat-flat-button (click)="allocate();removeItem()" [disabled]="tablelength === 0 || click || allocationDisabled">
            Proceed
        </button>
    </div>
</div>