<div class="container">
    <div class="card">
      <div class="task-head">
        <h4>Warning!</h4>
      </div>
      <p>{{data.message}}</p>
     
    </div>

    <div class="btns">
      <button mat-flat-button (click)="onNoClick()">Ok, Thanks</button>
    </div>
  </div>