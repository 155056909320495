<div class="container">
  <div class="card">
    <div class="task-head">
      <h4 *ngIf="!outComeSubmit">Required!</h4>
      <h4 *ngIf="outComeSubmit">Please Note</h4>
    </div>

    <form [formGroup]="newTaskForm" class="form-vertical" *ngIf="!outComeSubmit">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutAlign="center center" style="align-items: start;">
          <div style="align-items: start; display: flex;flex-direction: column;">
            <div>
              <mat-label class="card-label">Selected Target<span>*</span></mat-label>
              <mat-form-field appearance="outline" class="optionDetails">
                <input matInput formControlName="target" class="selectMatDetails" readonly #inputField />
              </mat-form-field>
              <span matTooltipClass="custom-point-tooltip" matTooltip="{{poiWkt}}" style="padding-left: 3px;">
                <img width="20px" height="20" [src]="tooltipImagePath" />
              </span>
            </div>

            <div>
              <mat-label class="card-label">Sensor Type<span>*</span></mat-label>
              <mat-form-field appearance="outline" class="optionDetails">
                <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes"
                  placeholder=" Select sensor type " class="selectDetails" formControlName="sensorType"
                  (selectionChange)="sensorChange($event.value)">
                  <mat-option *ngFor="let option of filteredSensorTypes" [value]="option"
                  [disabled]="(option.name === 'AIS')">{{option.name}}
                    <img style="margin-left: 10px;" width="17px" height="17" [src]="tooltipImagePath"
                    [matTooltip]="option.description"/>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <mat-label class="card-label">Cloud Cover<span>*</span></mat-label>
              <mat-form-field appearance="outline" class="optionDetails">
                <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes" placeholder="Select cloud cover"
                  formControlName="cloudCover" style="color: rgba(0, 0, 0, 0.42)" class="selectDetails">
                  <mat-option *ngFor="let option of this.outComesensor?.cloudCover; let i = index" [value]="option.key">
                    {{ option.key }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div style="align-items: end; display: flex;flex-direction: column; padding-left: 20px;">
            <div>
              <mat-label class="card-label">Task Name<span>*</span></mat-label>
              <mat-form-field appearance="outline" class="optionDetails">
                <input matInput placeholder="Your task name" formControlName="taskName" class="selectMatDetails"
                  #inputField (ngModelChange)="onKeyChange('taskName')" />
                  <mat-error *ngIf="newTaskForm.get('taskName').hasError('minlength') && newTaskForm.get('taskName').touched" class="error">
                    At least 5 characters long
                  </mat-error>  
              </mat-form-field>
            </div>
            <div>
              <mat-label class="card-label">Resolution<span>*</span></mat-label>
              <mat-form-field appearance="outline" class="optionDetails">
                <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes" placeholder="Select resolution"
                  formControlName="resolution" style="color: rgba(0, 0, 0, 0.42)" class="selectDetails">
                  <mat-option *ngFor="let option of this.outComesensor?.resolution; let i = index" [value]="option">
                    {{ option.key }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-label class="card-label">How Soon ?<span>*</span></mat-label>
              <mat-form-field appearance="outline" class="optionDetails">
                <input matInput placeholder="Hours" formControlName="latency" maxlength="5" class="selectMatDetails"
                  #inputField (ngModelChange)="onKeyChange('latency')" />
                <mat-error *ngIf="newTaskForm.get('latency').hasError('min') && newTaskForm.get('latency').touched" class="error">
                  Minimum hours should be 24
                </mat-error>
                <mat-error *ngIf="newTaskForm.get('latency').hasError('pattern') && newTaskForm.get('latency').touched" class="error">
                  Invalid hours
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Cancel</button>
          <button style="margin-left: 13px !important;" mat-stroked-button [disabled]="newTaskForm.invalid || outComeError
          || outComeSubmit"
            (click)="onSubmit()" class="submit">Submit</button>
        </div>
      </div>
    </form>

    <p *ngIf="outComeSubmit" style="padding-top: 25px;">Please note that once you create the task, you cannot cancel it, as the task will be ordered with the next available pass. <br>
      We will embargo an additional {{opticalValue}} for optical and {{sarValue}} for SAR, {{stereoValue}} for Stereo, {{hsiValue}} for HSI and {{videoValue}} for Video sensors <br>
      additionally to cover any real-time cost updates from the operators. Any unused amount would be returned to your account after the automatic ordering.<br>
      The task will be rejected if there are insufficient funds.
    </p>   


    <div *ngIf="outComeSubmit" class="btns" fxLayoutAlign="center center" fxLyout="row"
    style="padding-bottom: 10px;">
      <button mat-stroked-button (click)="onNoClick()" style="width: 120px;">I do not agree</button>
      <button style="margin-left: 13px !important;width: 120px;" mat-stroked-button
      (click)="onClick()" class="submit" [disabled]="agree">I agree</button>
    </div>

  </div>
</div>