<div class="container">

  <div class="divider">
    <div class="scrollbar">

      <div class="wrapper">
        <div class="card p-0 cart-items" id="scrollcustom">
          <div class="cart-items-box">
            <div class="task-head">
              <h4>Cart Items</h4>
              <p style="color: #98A2B3;" *ngIf="tableDataLength > 1">Select a Task Name at a time to pay </p>
            </div>
            <div class="left">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox color="primary" value="{{row.id}}" [checked]="!row.cartdata[0]?.checked"
                      (change)="onItemChange(row);select(row.cartdata[0]);" [disabled]="!row.cartdata[0]?.checked">
                    </mat-checkbox>
                  </td>
                </ng-container>


                
                <ng-container matColumnDef="projectname">
                  <mat-accordion>
                    <th mat-header-cell *matHeaderCellDef class="cart-head"> Project Name </th>
                    <mat-expansion-panel hideToggle>
                      <td class="projectName" mat-cell *matCellDef="let element"
                        [ngClass]="!element.cartdata[0]?.checked ? 'selected' : 'normal'">
                        <span
                          [matTooltip]="element?.project?.length > 15 ? element?.project : ''">{{element.project}}</span>
                      </td>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-container>


                <ng-container matColumnDef="taskname">
                  <th mat-header-cell *matHeaderCellDef class="cart-head"> Task Name </th>
                  <mat-expansion-panel hideToggle>
                    <td class="projectName" mat-cell *matCellDef="let element">
                      <span
                        [matTooltip]="element?.taskname?.length > 15 ? element?.taskname : ''">{{element.taskname}}</span>
                    </td>
                  </mat-expansion-panel>
                </ng-container>


                <ng-container matColumnDef="startdue">
                  <th mat-header-cell *matHeaderCellDef class="cart-head"> Start Date (UTC) </th>
                  <td mat-cell *matCellDef="let element"> {{element.openWindow | date: "dd/MM/yyyy" : "GMT" }} </td>
                </ng-container>

                <ng-container matColumnDef="enddue">
                  <th mat-header-cell *matHeaderCellDef class="cart-head"> End Date (UTC) </th>
                  <td mat-cell *matCellDef="let element">{{element.closeWindow | date: "dd/MM/yyyy" : "GMT" }}
                  </td>
                </ng-container>



                <ng-container matColumnDef="total">
                  <th mat-header-cell *matHeaderCellDef class="cart-head" [ngClass]="{'allocated-class': currentUser?.allocated}">
                    <ng-container *ngIf="!currentUser?.pointsEligible && currentUser?.allocated; else elseTemplate">
                      <span> Task Total</span>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <span> Total </span>
                    </ng-template> 
                  </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'allocated-class': currentUser?.allocated}">
                    <span *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">{{ currencySign }}{{getTotalCost(element.cartdata)  | customNumberFormat }}</span>
                    <span *ngIf="currentUser?.pointsEligible">{{getTotalPoint(element.cartdata)  | customNumberFormat }} points</span>
                    <span *ngIf="!currentUser?.pointsEligible && currentUser.allocated">{{ getTotalTaskCount(element.cartdata) }}</span>
                  </td>
                </ng-container>


                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef>

                  </th>
                  <td mat-cell *matCellDef="let row">
                    <img *ngIf="row.cartdata[0]?.checked" src="../../../assets/images/delete.png" alt="">
                    <img *ngIf="!row.cartdata[0]?.checked" alt=""
                    [src]="deletedImagePath"
                      style="cursor: pointer;" (click)="deleteDialog(row, 99, 99)">
                  </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <app-no-cart *ngIf="dataSource.length === 0"></app-no-cart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card no-padding" style="margin: 0 0 0 10px;">
      <div class="right">
        <div class="task-head">
          <h4 class="order-head">Order Summary</h4>
        </div>
        <p *ngIf="dataSource.length === 0" class="taskNoData">
          No sensors available
        </p>
        <p class="taskStyle" style="width: max-content;font-size: 14px">
          Task Name: {{selectedTask?.taskname}}
        </p>
      </div>





      <div class="table-responsive" *ngIf="CARTDATA?.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="tdhead">Sensor Name</th>
              <th scope="col" class="tdhead">Task ID</th>
              <th scope="col" class="tdhead" [ngClass]="{'allocated-summary': currentUser?.allocated}">
                <ng-container *ngIf="!currentUser?.pointsEligible && currentUser?.allocated; else elseTemplate">
                  <span> Task Total</span>
                </ng-container>
                <ng-template #elseTemplate>
                  <span> Total </span>
                </ng-template> 
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody class="rows">
            <tr *ngFor="let el of cartData; let i = index">
              <td class="noBorder">{{el.name}} - {{el.sensor}} <span *ngIf="el.resolution"> ({{
                el.resolution
                }}) </span>
              <img *ngIf="el?.resolutionTooltip" style="margin-left: 5px;margin-top: -4px;" width="14px" [src]="deletedImagePath"
              [matTooltip]="el.resolutionTooltip"/>
              </td>
              <td class="noBorder" id="taskField">{{el.uuid}}</td>
              <td class="noBorder" [ngClass]="{'allocated-summary': currentUser?.allocated}">
                <span *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">{{ currencySign }}{{ el.cost | customNumberFormat }}</span>
                <span *ngIf="currentUser?.pointsEligible">{{ el?.point }} points</span>
                <span *ngIf="!currentUser?.pointsEligible && currentUser.allocated">{{ el.taskNumber }}</span>
              </td>
              <td class="noBorder"><img src="../../../assets/images/delete.png" alt=""
                  (click)="deleteDialog(selectedTask,i,el)" style="cursor: pointer;"></td>
            </tr>

            <tr class="costamt">
              <td class="noBorder">
                <ng-container *ngIf="!currentUser?.pointsEligible && currentUser?.allocated; else elseTemplate">
                  <span> Total tasking number</span>
                </ng-container>
                <ng-template #elseTemplate>
                  <span>  Total tasking cost </span>
                </ng-template> 
              </td>
              <td class="noBorder"></td>
              <td class="noBorder" [ngClass]="{'allocated-summary': currentUser?.allocated}">
                <span *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">{{ currencySign }}{{totalAmount | customNumberFormat }}</span>
                <span *ngIf="currentUser?.pointsEligible">{{totalAmount ? getPoints(totalAmount) : 0 }} points</span>
                <span *ngIf="!currentUser?.pointsEligible && currentUser.allocated">{{ totalTaskCount }}</span>
              </td>
              <td class="noBorder"></td>

            </tr>

          </tbody>
        </table>

        <div class="pay-bottom-btn">
          <button mat-flat-button color="primary" (click)="ProceedToPay(selectedTask)" [disabled]="satellogic">Proceed
            to Pay</button>
        </div>
      </div>



    </div>
  </div>
</div>