<div class="container">
  <div class="card" *ngIf="paymentType === 'subscription'">
    <div class="task-head">
      <h4>Confirmation Request</h4>
  
      <p>
        Do you want to allocate your topped-up amount across a select number of operators?
      </p>

      <div class="btns">
        <button mat-stroked-button routerLink="/deposits" style="width: 125px;" (click)="removeItem()">No, please</button>
        <button mat-flat-button routerLink="/allocation" style="margin: 0;width: 125px;" (click)="removeItem()">Yes, please</button>
      </div>
    </div>
  </div>
  </div>