<div class="container">
  <div class="flex" *ngIf="!login">
    <div id="instructions">
      <div>
        <iframe width="1000" height="500" src="https://www.youtube.com/embed/lVZqz4omXnQ" title="YouTube video player"
          frameborder="0" class="responsive-iframe"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
      <div style="padding-top: 6%;">
        <iframe width="1000" height="500" src="https://www.youtube.com/embed/sS2EhExwtFE" title="YouTube video player"
          frameborder="0" class="iframe-responsive"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <div *ngIf="login">
    <iframe src="../../../../../assets/files/Eartheye_doc.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
      height="77%"> </iframe>
  </div>
</div>