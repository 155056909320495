<div (keydown)="submitForm($event)" *ngIf="data.type === 'normal'">
  <div class="row">
    <div *ngIf="successMsg.length===0;">
      <span style="text-align: center"><img src="assets/images/load-indicator.gif" /></span>
    </div>
    <div *ngIf="successMsg.length>0;">
      <div class="d-flex justify-content-center">
        <span class="lbl-Sucess">Success!</span>
      </div>
      <hr />
      <mat-dialog-content class="mat-typography" style="overflow: hidden;">
        <div class="row">
          <span class="lbl-msg">
            <span>Your task <strong>"{{data.taskname}}"</strong> has been
              successfully submitted for approval to the chosen satellite operators.<br />You will
              receive an email notification based on your tasking urgency
              when your selections have been processed.</span><br /><br />
              <span>All individual sensor total costs computed as shown on the dashboard are indicative, 
                with the final price available from operators prior to ordering.</span>
          </span>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="center">
        <div class="d-flex justify-content-center" style="margin-top: 15px; padding: 18px;">
          <button class="custom-button" (keydown)="onKeyPress($event, 1)" (click)="showTasks(1)"
            style="height: 41px;padding: 2px 15px; margin-left: 1px; margin-right: 1px;border-radius: 4px;">
            My Tasks
          </button>
          <button *ngIf="data?.from !=='budget'" mat-flat-button color="primary" (keydown)="onKeyPress($event, 2)" (click)="showTasks(2)"
            style="background-color: #1059a9; border-radius: 3px; padding: 2px 10px; margin-left: 16px; height: 39px;">
            Next New Task
          </button>
          <button *ngIf="data?.from ==='budget'" mat-flat-button color="primary" (keydown)="onKeyPress($event, 2)" (click)="showTasks(2)"
            style="background-color: #1059a9; border-radius: 3px; padding: 2px 10px; margin-left: 16px; height: 39px;">
            Next New Budget
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</div>

<div (keydown)="submitForm($event)" *ngIf="data.type === 'outcome' || data.type === 'maptask'">
  <div class="row">
    <div>
      <div class="d-flex justify-content-center">
        <span class="lbl-Sucess">Success!</span>
      </div>
      <hr />
      <mat-dialog-content class="mat-typography" style="overflow: hidden;">
        <div class="row">
          <span class="lbl-msg">
            <span>Your requirement has been submitted to the relevant operators. <br />
              You will be notified when the platform selects the most optimal sensor for ordering.</span><br />
          </span>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="center">
        <div class="d-flex justify-content-center" style="margin-top: 15px; padding: 18px;">
          <button class="custom-button" (keydown)="onKeyPress($event, 1)" (click)="showTasks(1)"
            style="height: 41px;padding: 2px 15px; margin-left: 1px; margin-right: 1px;border-radius: 4px;">
            My Tasks
          </button>
          <button *ngIf="data.type === 'outcome'" mat-flat-button color="primary" (keydown)="onKeyPress($event, 2)" (click)="showTasks(2)"
            style="background-color: #1059a9; border-radius: 3px; padding: 2px 10px; margin-left: 16px; height: 39px;">
            Next New Task
          </button>
          <button *ngIf="data.type === 'maptask'" mat-flat-button color="primary" (click)="onClick()"
          style="background-color: #1059a9; border-radius: 3px; padding: 2px 10px; margin-left: 16px; height: 39px;width: 105px;">
           New Task
        </button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</div>

<style rel="stylesheet" type="text/css">
  .lbl-check {
    text-align: center;
    color: #71bf45;
  }

  .txt-center {
    text-align: center;
  }

  .lbl-Sucess {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: center;
    font-size: 30px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .lbl-msg {
    text-align: center;
    font-size: 16px;
    padding: 0px 0;
    line-height: 1.5;
    font-weight: 400;
    font-family: "Barlow", sans-serif;
  }

  .lbl-msg strong {
    color: #1059a9;
    font-weight: 500;
  }

  .btn-details {
    float: right;
    background: #026fc2 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000033;
    border-radius: 5px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .btn-new {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000033;
    border-radius: 5px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: center;

    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  ::ng-deep .cdk-overlay-pane {
    max-width: 60vw !important;
  }

  ::ng-deep .mat-dialog-actions {
    margin-bottom: 0;
  }

  ::ng-deep .mat-dialog-content {
    margin: 0;
  }

  .custom-button {
    background-color: white;
    color: black; 
    border: 1px solid rgba(0,0,0,.12);; 
    height: 41px;
    border-radius: 5px;
    font-weight: 500;
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
</style>