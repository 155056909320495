<div class="container">
  <div class="card">
    <div class="task-head">
      <h4>Success!</h4>

    </div>
    <p *ngIf="message == 'contacts'">Your message has been sent. A representative will contact you shortly. Thank you.
    </p>
    <div *ngIf="message == 'contacts'" class="btns">
      <button class="custom-button" (click)="onNoClick()" style="font-weight: 500;">Write Another Inquiry</button>
      <button mat-flat-button routerLink="/task" (click)="onNoClick()">Back to Home</button>
    </div>
    <p *ngIf="message == 'demo'">Your request has been sent. You will receive an email confirmation. Thank you. </p>
    <div *ngIf="message == 'demo'" class="btns">
      <button class="custom-button" (click)="onNoClick()">Close</button>
      <button mat-flat-button routerLink="/task" (click)="onNoClick()">Back to Home</button>
    </div>
  </div>
</div>