<div class="table-container">
    <div class="header-cell">Metadata</div>
    <div style="padding-top: 5px;">
        <table>
            <tr *ngFor="let entry of getObjectEntries(metadata)" class="key">
                <td class="data">{{ entry[0] }}</td>
                <td class="data" style="width: 22px; padding-left: 5px;">:</td>
                <td class="data" style="max-width: 300px !important;">{{ entry[1] }}</td>
            </tr>
        </table>
    </div>
</div>