import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[appCustomTooltip]',
})
export class CustomTooltipDirective {
  @Input('appCustomTooltip') tooltipTemplate: TemplateRef<any>;
  @Input() appCustomTooltipContext: any; // New input to receive the context object

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.elementRef.nativeElement.addEventListener('mouseenter', () =>
      this.showTooltip()
    );
    this.elementRef.nativeElement.addEventListener('mouseleave', () =>
      this.hideTooltip()
    );
  }

  showTooltip() {
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipPortal = new TemplatePortal(
        this.tooltipTemplate,
        this.viewContainerRef,
        {
          $implicit: this.appCustomTooltipContext, // Pass the context object
        }
      );
      this.overlayRef.attach(tooltipPortal);
    }
  }

  hideTooltip() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  ngOnDestroy() {
    this.overlayRef.dispose();
  }
}
