import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { PopupService } from 'src/app/services/popup.service';
import { CollectionPopupComponent } from '../collection-popup/collection-popup.component';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {
  formGroup: FormGroup | any;
  aisFormGroup: FormGroup | any;
  error: boolean;
  isLeftHighlighted: boolean = false;
  isRightHighlighted: boolean = true;
  maxValue = 10080;
  minValue = 10;
  clydeAisFormGroup: FormGroup | any;
  duration: any;
  isPlaying: boolean = false;
  downloadImage: string;
  darkTheme: any;
  previousIndex: number | null = null;
  currentAudio: HTMLAudioElement | null = null;
  audioFiles: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: DataService,
    private apiService: ApiService,
    private popupService: PopupService
  ) {
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    this.downloadImage = this.darkTheme ? '../../../assets/images/icon-dark.svg' : '../../../assets/images/icon-img.svg';
    });

    if(data.type === 'audio') {
      this.audioFiles = this.data.signals
      .flatMap(item => item.audioFiles || []);
    }

  }

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      vesselLength: new FormControl('')
    });

    this.aisFormGroup = new FormGroup({
      historicalTime: new FormControl('')
    });

    this.clydeAisFormGroup = new FormGroup({
      historicalTime: new FormControl('')
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.error = false;
      const length = this.formGroup.get('vesselLength').value;

      if (length < 0) {
        this.error = true;
      }
    });

    this.aisFormGroup.valueChanges.subscribe(() => {
      this.error = false;
      const duration = this.aisFormGroup.get('historicalTime').value;
      if (duration < this.minValue || duration > this.maxValue || !this.isPositiveWholeNumber(duration)) {
        this.error = true;
      }
    });

    this.clydeAisFormGroup.valueChanges.subscribe(() => {
      this.error = false;
      this.duration = '';
      this.duration = this.clydeAisFormGroup.get('historicalTime').value;
      if (this.selectedOption === 'minutes') {
        const minutesPerDay = 1440;
        this.duration = Math.ceil(this.duration/minutesPerDay);
      }
      
      if (this.duration < 0 || this.duration > this.data?.historicalDays || !this.isPositiveWholeNumber(this.duration)) {
        this.error = true;
      }
    });
  }

  onClick(e: any, option: any) {
    let result: any = undefined;
    if (option == 2) {
      result = {
        length: this.formGroup.value.vesselLength,
        isGreater: this.isRightHighlighted
      }
    }
    this.dialogRef.close(result);
  }

  selectGreaterThan() {
    this.isLeftHighlighted = false;
    this.isRightHighlighted = true;
  }

  selectLessThan() {
    this.isLeftHighlighted = true;
    this.isRightHighlighted = false;
  }

  onSubmit(e: any, option: any) {
    let result: any = undefined;
    if (option == 2) {
      if(this.data.type == 'ais') {
        result = {
          duration: this.aisFormGroup.value.historicalTime,
        }
      } else {
        result = {
          duration: this.clydeAisFormGroup.value.historicalTime,
          type: this.selectedOption
        }
      }
    }
    this.dialogRef.close(result);
  }

  selectedOption: string | null = 'minutes';

  onCheckboxChange(event: any, option: string) {
    if (event.checked) {
      this.selectedOption = option;
    } else {
      this.selectedOption = null;
    }
    this.clydeAisFormGroup.get('historicalTime').setValue(null);
  }

  isPositiveWholeNumber(value: number): boolean {    
    return value !== null && value >= 1 && value % 1 === 0;
  }

  togglePlayPause(item: any, index: number) {
    // Check if the current audio is already playing
    if (item.isPlaying) {
      this.pauseAudio();
      item.isPlaying = false; // Set the current item's isPlaying to false
    } else {
      // If a previous audio was playing, pause it and set its isPlaying to false
      if (this.previousIndex !== null && this.previousIndex !== index) {
        this.audioFiles[this.previousIndex].isPlaying = false;
        this.pauseAudio();
      }
  
      // Get the audio URL from API and play the current audio
      this.getAudioUrlFromApi(item).then((audioUrl) => {
        this.playAudio(audioUrl, item, index);
      });
    }
  }
  
  playAudio(url: string, item: any, index: number) {
    this.currentAudio = new Audio(url); // Create a new audio element
    this.currentAudio.play();
  
    // Set the current audio's isPlaying state to true
    item.isPlaying = true;
    this.previousIndex = index;
  
    // When the audio ends, reset the isPlaying state
    this.currentAudio.onended = () => {
      item.isPlaying = false;
    };
  }

  pauseAudio() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0; // Reset audio to the beginning
    }
  }

  getAudio(item: any, action: string) {
    if (action === 'download') {
      // Fetch the audio file URL and trigger download
      this.getAudioUrlFromApi(item).then((audioUrl) => {
        this.downloadAudio(audioUrl,item);
      });
    }
  }

  getAudioUrlFromApi(item: any): Promise<string> {
    let taskId = this.data.sensorData.taskId;
    let sensorObj = this.data.sensorData;
    sensorObj.fileName = item.fileName;
  
    return new Promise((resolve, reject) => {
      this.apiService.getCollectionImage(taskId, sensorObj).subscribe(
        (res: any) => {
          if (res?.msg === 'Failed') {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            reject(message);
          } else if (res?.msg === 'user storage') {
            const message = 'We cannot access the audio as the delivery was made to your storage. You can access the audio via your storage.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            reject(message);
          } else if (res?.result?.audioUrl) {
            resolve(res.result.audioUrl);
          }
        },
        (error) => reject('API error occurred')
      );
    });
  }

  getFileNameFromS3SignedUrl(signedUrl) {
    const filename = signedUrl.split('/')[signedUrl.split('/').length - 1].split('?')[0];    
    return `${filename}`;
  }
  

  downloadAudio(url: string,item) {  
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    item.isDownloading = true;

    xhr.onload = () => {
      if (xhr.status === 200) {        
        const blob = xhr.response;
        const fileName = this.getFileNameFromS3SignedUrl(url);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      }
      item.isDownloading = false;
    };

    xhr.onerror = () => {
      item.isDownloading = false;
    };
    xhr.send();
  }
  
}
