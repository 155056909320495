import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = 'https://eartheye-eetaskapi-dev.xminds.in/api';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  url: any;
  server: any;
  isDownloading: any;
  constructor(private http: HttpClient) {
    this.server = environment.eeTaskServer;
    this.url = environment.eeTaskServer;
  }

  getTask(taskParams: any) {    
    let userId = taskParams?.userid, tabType = taskParams?.tabSelected, pageIndex = taskParams?.currentIndex,
    searchKey = taskParams?.searchKey;
    const searchKeyParam = searchKey ? `/${searchKey}` : '';
    const req = this.http.get(`${this.url}/index/${tabType}/${userId}/${pageIndex}${searchKeyParam}/`);
    return req;
  }

  getOrders(userId, orderObj) {
    return this.http.post(`${this.server}/order/getorder/${userId}`, orderObj);
  }
  
  getCartItems(userId: any) {
    const req = this.http.get(`${this.url}/cart/getcart/${userId}/`);
    return req;
  }
  getCollections(userId: any) {
    const req = this.http.get(`${this.url}/index/collections/${userId}/`);
    return req;
  }

  getdeposit(userId: any, status: any) {    
    const params = JSON.stringify(status);
    const res = this.http.get(
      `${this.server}/order/getdeposit/${userId}/${params}`);
    return res;
  }

  getBudgets(userId: any) {    
    const res = this.http.get(
      `${this.server}/budget/listBudget/${userId}`);
    return res;
  }

  getQuotes(userId: any) {    
    const res = this.http.get(
      `${this.server}/tasks/getQuotations/${userId}`);
    return res;
  }
}
