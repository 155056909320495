import {
  Component,
  OnInit,
  Input,
  Inject,
  Renderer2,
  ElementRef,
} from '@angular/core';
import * as Cesium from 'cesium';
import { Data } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

const cesiumContainer = document.createElement('div');
cesiumContainer.id = 'cesiumGlobeContainer';
document.getElementsByTagName('body')[0].appendChild(cesiumContainer);
@Component({
  selector: 'app-feasibility-preview',
  templateUrl: './feasibility-preview.component.html',
  styleUrls: ['./feasibility-preview.component.scss']
})
export class FeasibilityPreviewComponent implements OnInit { 

  @Input() target: any = { geoJSON: '', area: 0 };
  roll: any = 0;
  viewer: any = null;
  scene: any = null;
  longLatStr: string = '';
  latlonOverlay = document.createElement('div');
  taskTarget: any;
  taskid: any;
  taskname: any;
  coordinates: string;
  cesiumToken: any;
  opportunity: any;

  constructor(
    private dialogRef: MatDialogRef<FeasibilityPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private el: ElementRef, private renderer: Renderer2
  ) {
    this.cesiumToken = environment.cesiumAccessToken;
    this.taskid = data['taskid'];
    this.taskname = data['taskname'];
    this.taskTarget = JSON.parse(this.data['target']);
    this.opportunity = data['opportunity'];
  }

  ngOnInit(): void {
    var west = 40.0;
    var south = -20.0;
    var east = 120.0;
    var north = 70.0;

    var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
    Cesium.Ion.defaultAccessToken = this.cesiumToken;
    Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

    const imageryViewModels: any = [];
    const terrainViewModels: any = [];
    
    //Bing Maps Aerial
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Aerial',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingAerial.png'
        ),
        tooltip: 'Bing Maps Aerial',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 2 });
        },
      })
    );

    //Sentinel data
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      })
    );
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Blue Marble',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/blueMarble.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3845 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      })
    );
    const worldTerrain = Cesium.createWorldTerrain({ requestWaterMask: false });
    terrainViewModels.push(worldTerrain);
    const viewer = new Cesium.Viewer('cesiumContainer', {
      imageryProviderViewModels: imageryViewModels,
      terrainProviderViewModels: [],
      terrainProvider: worldTerrain,
      baseLayerPicker: true,
      geocoder: false,
      homeButton: true,
      fullscreenButton: false,
      selectionIndicator: true,
      shouldAnimate: false,
      animation: false,
      timeline: false,
      sceneModePicker: true,
      sceneMode: Cesium.SceneMode.SCENE2D,
    });
    this.viewer = viewer

    this.showLatLon();

    this.scene = this.viewer.scene;
    if (!this.scene.pickPositionSupported) {
      window.alert('This browser does not support pickPosition.');
    }
    this.plotTarget();

    function zoomToLocation() {
      var entities = viewer.entities;
      var entityArray = entities.values;
      var myEntity = entityArray[0];

      if (myEntity) {
        for (var i = 0; i < entityArray.length; i++) {
          var entity = entityArray[i];
          if (entity.name === 'polyline') {
            viewer.flyTo(entity);
          }
        }
      } else {
        var dataSourceCollection = viewer.dataSources;
        var dataSource = dataSourceCollection.get(0);

        if (dataSource) {
          viewer.flyTo(dataSource);
        }
      }
    }

    const homeButton = this.el.nativeElement.querySelector('.cesium-home-button');
           
    if (homeButton) {
      this.renderer.listen(homeButton, 'click', function () {        
        zoomToLocation();
      });
    }
  }

  plotTarget() {
    let target: any,targets: any,
      viewer = this.viewer;
        const fpPos = this.taskTarget.coordinates;
        const fpPoints = this.opportunity.footprint[0].toString().split(',').map(Number);
        

        const lng = fpPos[0],lat = fpPos[1];
  
        target = viewer.entities.add({
          id: this.taskname,
          position: Cesium.Cartesian3.fromDegrees(lng, lat),
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true, // default
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 30,
          },
        });

        targets = this.viewer.entities.add({
          name: 'polyline',
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray(fpPoints),
            clampToGround: true,
            material: new Cesium.PolylineOutlineMaterialProperty({
              outlineWidth: 3,
              outlineColor: Cesium.Color.YELLOW,
            }),
          },
        });
        viewer.zoomTo(targets); 
  }

  flyHome() {
    console.log(
      this.viewer.camera.heading,
      this.viewer.camera.pitch,
      this.viewer.camera.roll
    );
    this.viewer.camera.flyHome(2); // take 2 seconds
  }

  showLatLon() {
    const viewer = this.viewer;
    const latlonOverlay = this.latlonOverlay;
    const scene = this.viewer.scene;
    const canvas = this.viewer.canvas;
    viewer.container.appendChild(latlonOverlay);
    latlonOverlay.style.position = 'absolute';
    latlonOverlay.style['z-index'] = '10';
    latlonOverlay.style.right = '3px';
    latlonOverlay.style.bottom = '88px';
    latlonOverlay.style.height = '30px';
    latlonOverlay.style.width = 'fit-content';
    latlonOverlay.style['pointer-events'] = 'none';
    latlonOverlay.style.textAlign = 'center';
    latlonOverlay.style.justifyContent = 'center';
    latlonOverlay.style.padding = '5px 4px 0 4px';
    latlonOverlay.style.color = 'rgba(255, 255, 255, 1)';
    latlonOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
    let mousePosition;
    const accuracy = 15;
    const handler = new Cesium.ScreenSpaceEventHandler(canvas);

    handler.setInputAction((movement) => {
      mousePosition = movement.endPosition;
      const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);

        const longString = Cesium.Math.toDegrees(cartographic.longitude).toFixed(accuracy);
        const latString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(accuracy);
        const latitude = parseFloat(latString);
        const longitude = parseFloat(longString);

        const latitudeDMS = convertDecimalToDMS(latitude) + (latitude >= 0 ? 'N' : 'S');
        const longitudeDMS = convertDecimalToDMS(longitude) + (longitude >= 0 ? 'E' : 'W');

        function convertDecimalToDMS(decimal) {
          const degrees = Math.floor(Math.abs(decimal));
          const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
          const minutes = Math.floor(minutesDecimal);
          const seconds = (minutesDecimal - minutes) * 60;
                
          const formattedDegrees = degrees !== 0 ? `${degrees}°` : '';
          const formattedMinutes = minutes !== 0 ? `${minutes}'` : '';
          const formattedSeconds = `${seconds.toFixed(1)}"`;
        
          return `${formattedDegrees}${formattedMinutes}${formattedSeconds}`;
        }

        this.longLatStr = 'Lng: ' + longitudeDMS + ', ' + 'Lat: ' + latitudeDMS;
        
        latlonOverlay.innerHTML =
          "<div style='padding:0px'>" + this.longLatStr + '</div>';
      } else {
        this.longLatStr = '';
        latlonOverlay.innerHTML = "<div style='padding:0px'>Out of Globe</div>";
      }
      this.roll = Cesium.Math.toDegrees(this.viewer.camera.heading).toFixed(0);
      if (this.roll > 359.5) this.roll = 0;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  onKeyPress(e: any, msg: string) {
    if (e.key === 'Enter') {
      this.onNoClick(msg);
    }
  }
  onNoClick(msg: any): void {
    this.dialogRef.close('Done');
  }
}
