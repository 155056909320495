import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {
  message: SafeHtml;

  constructor(private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer) { 
      this.message = this.sanitizer.bypassSecurityTrustHtml(data.message);
    }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
