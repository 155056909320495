<div class="container">
  <img src="../../../assets/images/logo.png" alt="logo" style="width: 40%;" />
  <h4>Verification</h4>
  <p>Copy the verification code in your authy application to verify this account recovery</p>
  <form class="form-vertical">
    <div>
      <label>Verification Code</label>
      <div class="splitter">
        <input type="text" id="digit-1" name="digit-1" data-next="digit-2" />
        <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
        <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />

        <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
        <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />


      </div>
      <button mat-raised-button color="primary">NEXT</button>
    </div>

  </form>
</div>