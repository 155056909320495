<div class="container">
    <div *ngIf="data.type === 'vessel'" class="card" [formGroup]="formGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            Filter by vessel length
            <span>
                <mat-icon (click)="selectGreaterThan()"
                    [class.highlight]="isRightHighlighted">keyboard_arrow_right</mat-icon>
                <mat-icon (click)="selectLessThan()"
                    [class.highlight]="isLeftHighlighted">keyboard_arrow_left</mat-icon>
            </span>
            <span style="padding-left: 5px;">
                <mat-form-field appearance="outline" class="option" style="width: 15%;">
                    <input matInput formControlName="vesselLength" class="matOption" type="number" #inputField />
                </mat-form-field>
            </span>
            <span style="padding-left: 10px;">meters</span>
        </p>

        <div class="btns">
            <button (click)="onClick($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="onClick($event, 2)" style="width: 120px;"
                [disabled]="this.error || (formGroup.value.vesselLength <= 0)" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'ais'" class="card" [formGroup]="aisFormGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            What is the historical period required for the trail in the past seven days?
        </p>

        <span style="display: flex;flex-direction: column;align-items: center;">
            <div>
                <mat-form-field appearance="outline" style="width: 75%;">
                    <input matInput formControlName="historicalTime" class="matOption" type="number" #inputField />
                </mat-form-field>
                <span style="padding-left: 10px;">minutes</span>
            </div>

        </span>

        <div class="btns">
            <button (click)="onSubmit($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="onSubmit($event, 2)" style="width: 120px;"
                [disabled]="this.error || (!aisFormGroup.value.historicalTime)" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'clyde'" class="card" [formGroup]="clydeAisFormGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            What is the historical period required for the trail?
        </p>

        <span style="display: flex;flex-direction: column;align-items: center;">

            <div>
                <mat-checkbox
                    [checked]="selectedOption === 'minutes'"
                    (change)="onCheckboxChange($event, 'minutes')"
                    style="margin: 0 0 8px 10px">
                    <mat-label class="label">Minutes</mat-label>
                </mat-checkbox>
                <mat-checkbox
                    [checked]="selectedOption === 'days'"
                    (change)="onCheckboxChange($event, 'days')"
                    style="margin: 0 0 8px 10px">
                    <mat-label class="label">Days</mat-label>
                </mat-checkbox>
            </div>

            <div>
                <div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="historicalTime" class="matOption" type="number" #inputField />
                    </mat-form-field>
                    <span style="padding-left: 10px;">{{selectedOption}}</span>
                </div>
                <div *ngIf="this.duration > this.data?.historicalDays" class="error-container">
                    <span class="error-message">
                        Your historical data request exceeds the historical period included in the
                        tasking. You can access historical data within the past {{this.data?.historicalDays}} days.
                    </span>
                </div>
            </div>

        </span>

        <div class="btns">
            <button (click)="onSubmit($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="onSubmit($event, 2)" style="width: 120px;"
                [disabled]="this.error || (!clydeAisFormGroup.value.historicalTime)" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'audio'" class="card">
        <div class="task-head">
            <h4>Audio Control</h4>
        </div>

        <div *ngFor="let item of audioFiles; let i = index">
            <p style="display: flex; align-items: center; justify-content: center;" fxLayoutGap="30px">
                <span>
                    <img width="20px" src="../../../assets/images/audio.png" />
                   {{data?.signals.product }}
                </span>
                <span>
                   {{item?.timeStamp}}
                </span>
                <!-- Play/Pause button -->
                <button mat-icon-button class="align-icon" (click)="togglePlayPause(item, i)">
                    <mat-icon style="color: #1059A9; font-size: 26px;">
                        {{ item?.isPlaying ? 'pause_circle' : 'play_circle' }}
                    </mat-icon>
                </button>

                <!-- Download button -->
                <button mat-icon-button class="align-icon" (click)="getAudio(item, 'download')">
                    <mat-icon *ngIf="item.isDownloading" style="color: #1059A9; font-size: 26px;">download</mat-icon>
                    <img *ngIf="!item.isDownloading" style="cursor: pointer;" [src]="downloadImage" />
                </button>
            </p>
        </div>
    
        <div class="btns">
            <button mat-flat-button (click)="onClick($event, 2)" style="width: 120px;padding: 2px 30px;height: 40px;" [disabled]="this.error"
                type="submit">Close</button>
        </div>
    </div>
</div>