export const OPERATORS = {
    "HEAD": "Head",
    "CAPELLA": "Capella",
    "SATELLOGIC": "Satellogic",
    "AT21": "21AT",
    "UMBRA": "Umbra",
    "STE": "STE",
    "KOMPSAT": "KOMPSAT",
    "USL": "USL",
    "ISI" : "ISI",
    "AXELSPACE" : "AxelSpace",
    "GHG" : "GHG",
    "ORORATECH": "Ororatech",
    "SATVU": "SatVu",
    "BLACKSKY": "Blacksky",
    "WYVERN" : "Wyvern",
    "SPIRE": "Spire",
    "CLYDE": "ClydeSpace",
    "SYNSPECTIVE": "Synspective",
    "GOKTURK": "Gokturk",
    "LBAND": "Spire-RF"
}

export const STATUS_MAP = {
    completed: 'done',
    approved: 'done',
    ordered: 'done',
    archived: 'done',
    restored: 'done',
    delivering: 'wip',
    pending: 'wip',
    awaiting: 'wip',
    'cancel requested': 'wip',
    rejected: 'canceled',
    canceled: 'canceled',
    failed: 'canceled'
  };

  export const CONFIG_VALUES = {
   "AxelCustomerId" : "659b53fd474336f361dfeb06",
   "numberFormat": '1.2-2',
  };

  export const AT21_RESPONSE_TIME = {
    'standard': 45,
    'priority': 25,
    'rush': 1
  }

  export const supportEmail = "support@eartheye.space"

  export const BANK_DETAILS = {
    'accountHolder' : 'EARTHEYE SPACE TECH PTE LTD',
    'accountNumber' : '261-021273-178',
    'SWIFTcode' : 'HSBCSGSG',
    'bankName' : 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED SINGAPORE',
    'bankAddress' : 'MARINA BAY FINANCIAL CENTRE TOWER 2 48-01 10 MARINA BOULEVARD SINGAPORE 018983'
  }

  export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?!gmail|yahoo|outlook|hotmail)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/; 

  export const STRIPE_CHARGE = 3.9;

  export const PAYPAL_CHARGE = 4.1;

  export const RF_RADIUS = 1000; //In km
  
  export const OPTICAL_EMBARGO_AMOUNT = 1000;

  export const SAR_EMBARGO_AMOUNT = 2000;

  export const STEREO_EMBARGO_AMOUNT = 5000;

  export const HSI_EMBARGO_AMOUNT = 1000;

  export const Video_EMBARGO_AMOUNT = 6000;

  export  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  export const polarization = {
    "Capella": 'HH',
    "KOMPSAT": 'HH',
    "Umbra" : 'VV',
    "STE" : 'HH,VV,HV,VH'
  }

  export const CAPELLA_MODES = {
    'spotlight_ultra': 'POI',
    'spotlight': 'POI',
    'spotlight_wide': 'POI',
    'stripmap_20': 'POI',
    'stripmap_50': 'POI',
    'stripmap_100': 'AOI'
  }
