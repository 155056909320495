import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  showPassword: boolean = false;
  registerForm: any = FormGroup;
  submitted = false;
  warnMsg: any;
  clicked: boolean = false;
  isInIframe: boolean;

  constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService,
    private themeService: ThemeService
    ) {
    this.isInIframe = this.themeService.isInIframe();
   }


  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    });
  }
  keyPress(event: any) {
    this.warnMsg = '';
  }
  get f() { return this.registerForm.controls; }
  forgotPswrd() {
    this.clicked = true;
    const email = this.registerForm.value.email.toLowerCase();
    const userEmail = encodeURIComponent(this.registerForm.value.email.toLowerCase());
    const pswrd = encodeURIComponent(this.registerForm.value.password || "");

    const loginparams = { userEmail, pswrd };

    this.userService.chkRegisteredUser(loginparams).subscribe(
      (result: any) => {
        // if (result.Msg !== 'User not registered')
        if (result) {
          this.warnMsg = 'You will receive an e-mail to ' + email + ' in few minutes with further instructions if you are a registered user.';
          if (result.Msg === 'User not registered') {
          }
          else {
            this.userService.forgotPassword(email).subscribe(async (res: any) => {
            });
          }
        }
      },
    );
  }

}
