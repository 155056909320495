import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appScrollTracker]'
})
export class ScrollTrackerDirective {
  @Output() scrolledBottom = new EventEmitter();
  private lastScrollTop = 0;

  constructor(private elementRef: ElementRef) {}

  @HostListener('scroll', ['$event.target'])
  onScroll(target: HTMLElement) {
    const scrollTop = target.scrollTop;
    if (scrollTop > this.lastScrollTop && this.isBottomReached(target)) {
      this.scrolledBottom.emit();
    }
    this.lastScrollTop = scrollTop;
  }

  isBottomReached(target: HTMLElement): boolean {
    const threshold = 1;
    return (target.scrollTop + target.clientHeight) >= (target.scrollHeight - threshold);
  }
}