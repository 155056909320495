import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(private dialog: MatDialog) { }

  openPopup(component: any, message: string, type: any, width: any): MatDialogRef<any> {
    const dialogConfig: MatDialogConfig = {
      width: width,
      backdropClass: 'blurred',
      disableClose: true,
      data: { message, type }
    };

    return this.dialog.open(component, dialogConfig); // Return MatDialogRef
  }
}
