import { Directive, ElementRef, Component, OnInit } from '@angular/core';
import { Viewer } from 'cesium';

@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrls: ['./globe.component.scss']
})
export class GlobeComponent implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    const viewer = new Viewer(this.el.nativeElement);
  }

}
