import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Inject,
  Renderer2,
} from '@angular/core';
import * as Cesium from 'cesium';
import { Data } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { OPERATORS, RF_RADIUS } from 'src/app/services/constants';
import { Color } from 'cesium';
var turf = require('@turf/turf');

const cesiumContainer = document.createElement('div');
cesiumContainer.id = 'cesiumGlobeContainer';
document.getElementsByTagName('body')[0].appendChild(cesiumContainer);
@Component({
  selector: 'app-previescreen',
  templateUrl: './previescreen.component.html',
  styleUrls: ['./previescreen.component.scss']
})
export class PreviescreenComponent implements OnInit {

  @Input() target: any = { geoJSON: '', area: 0 };
  mapSnap: any;
  defaultLL: any[] = [82.9739, 25.3176];
  roll: any = 0;
  viewer: any = null;
  scene: any = null;
  longLatStr: string = '';
  latlonOverlay = document.createElement('div');
  taskTarget: any;
  taskid: any;
  taskname: any;
  coordinates: any = [];

  title = 'Confirmation';
  cancelBtn = 'Cancel Task';
  btnParams = 'CANCEL';
  successMsg: any;
  currentUser: any;
  taskMap: any;
  cesiumToken: any;
  sensors: any;
  targetPoints: any;

  constructor(

    private dialogRef: MatDialogRef<PreviescreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private el: ElementRef, private renderer: Renderer2
  ) {
    this.cesiumToken = environment.cesiumAccessToken;
    this.taskid = data['taskid'];
    this.taskname = data['taskname'];
    this.taskTarget = JSON.parse(this.data['target']);
    this.sensors = data['sensors'];
    this.targetPoints = this.data['targetPoints'] ? JSON.parse(this.data['targetPoints']) : null;

    this.coordinates = [];
    if (this.taskTarget)
      if (this.taskTarget.type === 'Polygon') {
        this.coordinates = this.taskTarget.coordinates[0]
          .toString()
          .split(',')
          .map(Number);
      } else if (this.taskTarget?.length > 1) {
        this.taskTarget.type = this.taskTarget[0].type;
        for (const points of this.taskTarget) {
          this.coordinates.push(points.coordinates)
        }
      } else {
        this.coordinates = this.taskTarget.coordinates;
      }
  }

  ngOnInit(): void {
    var west = 40.0;
    var south = -20.0;
    var east = 120.0;
    var north = 70.0;

    var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
    Cesium.Ion.defaultAccessToken = this.cesiumToken;
    Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

    const imageryViewModels: any = [];
    const terrainViewModels: any = [];

    //Bing Maps Aerial
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Aerial',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingAerial.png'
        ),
        tooltip: 'Bing Maps Aerial',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 2 });
        },
      })
    );

    //Sentinel data
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      })
    );
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Blue Marble',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/blueMarble.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3845 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      })
    );
    const worldTerrain = Cesium.createWorldTerrain({ requestWaterMask: false });
    terrainViewModels.push(worldTerrain);
    const viewer = new Cesium.Viewer('cesiumContainer', {
      imageryProviderViewModels: imageryViewModels,
      terrainProviderViewModels: [],
      // terrainProvider: worldTerrain,
      baseLayerPicker: true,
      geocoder: false,
      homeButton: true,
      fullscreenButton: false,
      selectionIndicator: true,
      shouldAnimate: false,
      animation: false,
      timeline: false,
      sceneModePicker: true,
      sceneMode: Cesium.SceneMode.SCENE2D,
    });
    this.viewer = viewer

    this.showLatLon(); 

    var target = this.target;

    this.scene = this.viewer.scene;
    if (!this.scene.pickPositionSupported) {
      window.alert('This browser does not support pickPosition.');
    }
    this.plotTarget();

    function zoomToLocation() {
      var entities = viewer.entities;
      var entityArray = entities.values;
      var myEntity = entityArray[0];

      if (myEntity) {
        var entities = viewer.entities;
        var entityArray = entities.values;
        var entity = entityArray[0];
        viewer.flyTo(entity);
      } else {
        var dataSourceCollection = viewer.dataSources;
        var dataSource = dataSourceCollection.get(0);

        if (dataSource) {
          viewer.flyTo(dataSource);
        }
      }
    }

    const homeButton = this.el.nativeElement.querySelector('.cesium-home-button');
           
    if (homeButton) {
      this.renderer.listen(homeButton, 'click', function () {        
        zoomToLocation();
      });
    }
  }

  plotTarget() {
    let target: any,
      viewer = this.viewer;
      const isLBandIncluded = this.sensors.some(item => (item.operatorKey === OPERATORS.LBAND));
      
    if (this.taskTarget) {
      if (this.taskTarget.type === 'Polygon') {
        const fpPos = this.taskTarget.coordinates[0]
          .toString()
          .split(',')
          .map(Number);
        var bbox = turf.bbox(this.taskTarget);
        var rectangle = Cesium.Rectangle.fromDegrees(
          bbox[0],
          bbox[1],
          bbox[2],
          bbox[3]
        );
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;
        target = viewer.entities.add({
          name: this.taskname,
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(fpPos),
            },
            fill: false,
            height: 0,
            outlineColor: Cesium.Color.YELLOW,
            outline: true,
          },
        });
        viewer.flyTo(target, { duration: 3 });
      } else if (this.taskTarget?.length > 1) {
        this.createGeoJSONFile(this.taskTarget);
      } else if (this.taskTarget.type === 'Point') {
        const fpPos = this.taskTarget.coordinates;
        const lng = fpPos[0],
          lat = fpPos[1];
        var rectangle = Cesium.Rectangle.fromDegrees(
          lng - 1,
          lat - 1,
          lng + 1,
          lat + 1
        );
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;
        target = viewer.entities.add({
          id: this.taskname,
          position: Cesium.Cartesian3.fromDegrees(lng, lat), //  bbPosition,
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true, // default
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
            alignedAxis: Cesium.Cartesian3.ZERO, // default
            width: 20, // default: undefined
            height: 30, // default: undefined
          },
        });
        this.defaultLL = [lng, lat];
        this.viewer.camera.flyTo(
          {
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 100000.0),
            orientation: {
              heading: Cesium.Math.toRadians(0),
              pitch: Cesium.Math.toRadians(-90.0),
              roll: 0.0,
            },
          },
          { duration: 3 }
        );
      }
    }

  }

  drawCircles(coordinates) {
    const radius = RF_RADIUS * 1000;
    const lng = coordinates[0],
      lat = coordinates[1];

      // Create a circle entity for each point
      this.viewer.entities.add({
        name:'circles',
        position: Cesium.Cartesian3.fromDegrees(lng, lat),
        ellipse: {
          semiMajorAxis: radius,
          semiMinorAxis: radius,
          material: Color.BLUE.withAlpha(0.4)
        },
        billboard: {
          image: 'assets/images/marker-icon.png',
          show: true, // default
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
          alignedAxis: Cesium.Cartesian3.ZERO, // default
          width: 20, // default: undefined
          height: 30, // default: undefined
        },
      });
  }

  createGeoJSONFile(features: any[]) {
    const geoJSON = {
      type: 'FeatureCollection',
      features: features.map((feature) => ({
        type: 'Feature',
        geometry: {
          type: feature.type,
          coordinates: feature.coordinates,
        },
      })),
    };
  
  let selectData: any;
  selectData = Cesium.GeoJsonDataSource.load(geoJSON);

  selectData.then((dataSource) => {
    const entities = dataSource.entities.values;
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      entity.billboard = new Cesium.BillboardGraphics({
        image: 'assets/images/marker-icon.png',
        show: true,
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        alignedAxis: Cesium.Cartesian3.ZERO,
        width: 20,
        height: 30,
      });
      entity.label = new Cesium.LabelGraphics({
        text: (i + 1).toString(), // Set the label to the index + 1
        font: '14pt sans-serif',
        fillColor: Cesium.Color.WHITE,
        outlineColor: Cesium.Color.BLACK,
        outlineWidth: 2,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(0, -35) // Adjust the offset to position the label correctly
      });
    }

    this.viewer.flyTo(dataSource, { duration: 3 });
    this.viewer.dataSources.add(dataSource);
    this.viewer.scene.globe.depthTestAgainstTerrain = false;
  });
  }

  flyHome() {
    console.log(
      this.viewer.camera.heading,
      this.viewer.camera.pitch,
      this.viewer.camera.roll
    );
    this.viewer.camera.flyHome(2); // take 2 seconds
  }

  showLatLon() {
    const viewer = this.viewer;
    const latlonOverlay = this.latlonOverlay;
    const scene = this.viewer.scene;
    const canvas = this.viewer.canvas;
    viewer.container.appendChild(latlonOverlay);
    latlonOverlay.style.position = 'absolute';
    latlonOverlay.style['z-index'] = '10';
    latlonOverlay.style.right = '3px';
    latlonOverlay.style.bottom = '88px';
    latlonOverlay.style.height = '30px';

    latlonOverlay.style.width = 'fit-content';
    latlonOverlay.style['pointer-events'] = 'none';
    latlonOverlay.style.textAlign = 'center';
    latlonOverlay.style.justifyContent = 'center';
    latlonOverlay.style.padding = '5px 4px 0 4px';
    latlonOverlay.style.color = 'rgba(255, 255, 255, 1)';
    latlonOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
    let mousePosition;
    const accuracy = 15;
    const handler = new Cesium.ScreenSpaceEventHandler(canvas);

    handler.setInputAction((movement) => {
      mousePosition = movement.endPosition;
      const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);

        const longString = Cesium.Math.toDegrees(cartographic.longitude).toFixed(accuracy);
        const latString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(accuracy);
        const latitude = parseFloat(latString);
        const longitude = parseFloat(longString);

        const latitudeDMS = convertDecimalToDMS(latitude) + (latitude >= 0 ? 'N' : 'S');
        const longitudeDMS = convertDecimalToDMS(longitude) + (longitude >= 0 ? 'E' : 'W');

        function convertDecimalToDMS(decimal) {
          const degrees = Math.floor(Math.abs(decimal));
          const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
          const minutes = Math.floor(minutesDecimal);
          const seconds = (minutesDecimal - minutes) * 60;
                
          const formattedDegrees = degrees !== 0 ? `${degrees}°` : '';
          const formattedMinutes = minutes !== 0 ? `${minutes}'` : '';
          const formattedSeconds = `${seconds.toFixed(1)}"`;
        
          return `${formattedDegrees}${formattedMinutes}${formattedSeconds}`;
        }

        this.longLatStr = 'Lng: ' + longitudeDMS + ', ' + 'Lat: ' + latitudeDMS;

        latlonOverlay.innerHTML =
          "<div style='padding:0px'>" + this.longLatStr + '</div>';
      } else {
        this.longLatStr = '';
        latlonOverlay.innerHTML = "<div style='padding:0px'>Out of Globe</div>";
        // latlonOverlay.style.display = 'none';
      }
      this.roll = Cesium.Math.toDegrees(this.viewer.camera.heading).toFixed(0);
      if (this.roll > 359.5) this.roll = 0;
      // this.compassOverlay.style.transform = `rotate(${360-this.roll}deg)`;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  onKeyPress(e: any, msg: string) {
    if (e.key === 'Enter') {
      this.onNoClick(msg);
    }
  }
  onNoClick(msg: any): void {
    this.dialogRef.close('Done');   
  }
}
