// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: true,
  gst: 9,
  payCurrency: "USD",
  payCurrencySign: "$",
  eeDownloadUrl: process.env['APP_EE_DOWNLOAD_URL'] || "https://demo-tasking.eartheye.space/api/task/",
  eeTaskServer: process.env['APP_EE_TASK_URL'] || "https://demo-tasking.eartheye.space/api/task",
  usersServer: process.env['APP_EE_USER_URL'] || "https://demo-tasking.eartheye.space/api/user",
  mailServer: process.env['APP_EE_MAIL_URL'] || "https://demo-tasking.eartheye.space/api/email",
  demoView: process.env['APP_EE_DEMO_VIEW'] || "false",
  cesiumAccessToken: process.env['APP_EE_CESIUM_TOKEN'] || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxODA2ZjI1MC00MmYwLTRjNGQtOGE2OC1kZmQzNDlhOTQ2ZTgiLCJpZCI6MTAwMzM1LCJpYXQiOjE2OTY0ODEzMDN9.UobVI-Om2xJzEp8CRgBByPr1FCmhJjIdPL68S0HFWpw",
  openCageApiKey: process.env['APP_EE_OPENCAGE_APIKEY'] || "bf3c9110504f44e9b482a71ec157552a",
  eeAssetsUrl: process.env['APP_EE_ASSETS_URL'] || "https://ee-website-assets.s3.amazonaws.com/develop/",
   recaptcha: {
    siteKey: '6LcVdV8jAAAAANHBPwg5UUI7upnVQrtsCoCUpUrr',
  },
  minAllocationAmt: 150
};

// //(environment)
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
