import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent {
  title = 'Modify Sensors detail?';
  actionBtn = 'Modify';
  btnEnabled = false;
  keyInword: string;
  successMsg: any;
  currentUser: any;
  id = '';
  subitem = -9;
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemdata: any; action: string, userId: any, sensorId: any }
  ) {
    this.id = this.data.itemdata.taskid;
    if (data.itemdata.id.includes('#')) {
      this.subitem = parseInt(data.itemdata.id.split('#')[1]) + 1;
    }
    if (this.data.action === 'order') {
      this.title = 'Place order?';
      this.actionBtn = 'Modify';
    } else {
      this.title = 'Delete this item from cart?';
      this.actionBtn = 'Delete';
    }
  }

  valChng(keyInword: any): void {
    this.btnEnabled = false;
    const keyword = this.id.slice(this.id.length - 4);
    if (keyword === keyInword.target.value) this.btnEnabled = true;
  }

  onKeyPress(e: any, action: string) {
    if (e.key === 'Enter') {
      this.onNoClick(action);
    }
    if (e.keyCode === 13) {
      this.onNoClick('No');
    }
  }

  submitForm(event: any) {
    if (event.keyCode === 13) {
      this.onNoClick('No');
    }
  }
  
  onNoClick(action: any): void {
    this.successMsg = '';
    if (action === 'Delete') {
      let subtxt = '';
      if (this.subitem > 0) subtxt = `(#${this.subitem})`;
      const toast: any = {
        type: 'error',
        title: '',
        msg: `Task ${this.id}${subtxt} is removed from Your Cart`,
      };
      this.authService
        .delCartdata(this.data.itemdata.id, this.data.userId, this.data.sensorId)
        .subscribe((res: any) => {
          this.successMsg = JSON.stringify(res.Msg);
          this.actionBtn = 'Ok';
          this.title = 'Delete confirmation';
          this.dialogRef.close('Deleted');
        });
    } else {
      this.dialogRef.close('Done');
    }
  }
}
