import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  paymentType: string | null;

  constructor() { }

  ngOnInit() {
    this.paymentType = localStorage.getItem('keyType');
  }

}
