<div>
    <h4 mat-dialog-title style="text-align: center" class="head">{{title}}</h4>
    <hr />
    <mat-dialog-content class="mat-typography">
      <p *ngIf="actionBtn === 'Ok'">
        <span *ngIf="successMsg">{{successMsg}}</span>
        <span *ngIf="!successMsg"
          ><p style="text-align: center">
            <img src="assets/images/load-indicator.gif" /></p
        ></span>
      </p>
      <div *ngIf="actionBtn === 'Delete'" style="text-align: center">
        <p class="sub">
          To delete this <span *ngIf="subitem > 0">sub-item(#{{subitem}}) </span
          ><span *ngIf="0 > subitem">item </span>
          <label> from cart, please type <strong>'{{id | slice:-4}}'</strong></label>
        </p>
        <input type="text" value="" (keyup)="valChng($event)" class="input-class" />
      </div>
    </mat-dialog-content>
    <br /><br />

    <div class="btns">
      <button (keydown)="onKeyPress($event, 'NO')" (click)="onNoClick('NO')" class="custom-button">
        No, Thanks</button>
      <button mat-flat-button (keydown)="onKeyPress($event, actionBtn)" (click)="onNoClick(actionBtn)" style="width: 114px;"
        [disabled]="!btnEnabled" class="delete">Delete</button>
    </div>
  </div>
  