import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BANK_DETAILS } from 'src/app/services/constants';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-registersuccess',
  templateUrl: './registersuccess.component.html',
  styleUrls: ['./registersuccess.component.scss']
})
export class RegistersuccessComponent implements OnInit {

  registerForm: any = FormGroup;
  darkTheme: any;
  bankDetails: any;

  constructor(private dialogRef: MatDialogRef<RegistersuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: DataService
    ) { 
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;
      });
      this.bankDetails = BANK_DETAILS
    }

  ngOnInit(): void { }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(): void {
    this.dialogRef.close('yes');
  }
}
