import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-openconfirmwarning',
  templateUrl: './openconfirmwarning.component.html',
  styleUrls: ['./openconfirmwarning.component.scss']
})
export class OpenconfirmwarningComponent implements OnInit {
  targetArea: any;
  htmlStr: string;
  formGroup: FormGroup | any;
  error: boolean = true;

  constructor(
    private router: Router, public dialogRef: MatDialogRef<OpenconfirmwarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data != 'rule') {
    this.targetArea = data['area'];
    if (this.targetArea > 2000)
      this.htmlStr = `<span class="lbl-msg">Your tasking area of <strong>${this.targetArea} sqkm</strong> within the collection window requested may be rejected due to a large area capture request within a short period. Please either reduce the area or increase the period or do both.<br><br>Alternatively, you can proceed with your request as it is. You will be notified of the status after feasibility by the chosen operators.<br><br>But RFs do not have size restrictions .</span>`;
    if (10 > this.targetArea)
      this.htmlStr = `<span class="lbl-msg">Your tasking area is smaller than the minimum scene size of any operators. Please select an area larger than <strong>25 sqkm</strong> or you will still be charged minimum scene sizes as defined by operators based on your selection of operators. 
      </span>`;
    }
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      cloudCover: new FormControl('')
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.error = false;
      const cloudValue = this.formGroup.get('cloudCover').value;

      if (cloudValue > 100 || cloudValue < 0 || !this.isPositiveWholeNumber(cloudValue)) {
        this.error = true;
      }
    });
  }

  isPositiveWholeNumber(value: number): boolean {
    return ((value !== null && value >= 1 && value % 1 === 0) || value === 0);
  }

  onKeyPress(e: any, option: number) {
    let result: any = undefined;
    if (option == 2) result = 'Ok';
    this.dialogRef.close(result);
  }

  onClick(e: any, option: any) {
    let result: any = undefined;
    if (option == 2) {
      result = this.formGroup.value.cloudCover;
    }
    this.dialogRef.close(result);
  }
}
