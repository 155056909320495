<div class="container">
  <div class="tabset">
    <!-- Tab 1 -->
    <input
      type="radio"
      name="tabset"
      id="tab1"
      aria-controls="recent"
      checked
    />
    <label for="tab1">Recent</label>
    <!-- Tab 2 -->
    <input type="radio" name="tabset" id="tab2" aria-controls="important" />
    <label for="tab2">Important</label>
    <!-- Tab 3 -->
    <input type="radio" name="tabset" id="tab3" aria-controls="all" />
    <label for="tab3">All</label>

    <div class="tab-panels">
      <section id="recent" class="tab-panel">
        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert1" />
            <label class="close" title="close" for="alert1">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0H14C15.103 0 16 0.897 16 2V20L8 15.428L0 20V2C0 0.897 0.897 0 2 0Z"
                    fill="#1059A9"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert2" />
            <label class="close" title="close" for="alert2">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H2C0.897 0 0 0.897 0 2V20L8 15.428L16 20V2C16 0.897 15.103 0 14 0ZM14 16.553L8 13.125L2 16.553V2H14V16.553Z"
                    fill="#344054"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert3" />
            <label class="close" title="close" for="alert3">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H2C0.897 0 0 0.897 0 2V20L8 15.428L16 20V2C16 0.897 15.103 0 14 0ZM14 16.553L8 13.125L2 16.553V2H14V16.553Z"
                    fill="#344054"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="completed" class="tab-panel">
        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert4" />
            <label class="close" title="close" for="alert4">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H2C0.897 0 0 0.897 0 2V20L8 15.428L16 20V2C16 0.897 15.103 0 14 0ZM14 16.553L8 13.125L2 16.553V2H14V16.553Z"
                    fill="#344054"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert5" />
            <label class="close" title="close" for="alert5">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0H14C15.103 0 16 0.897 16 2V20L8 15.428L0 20V2C0 0.897 0.897 0 2 0Z"
                    fill="#1059A9"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cancelled" class="tab-panel">
        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert6" />
            <label class="close" title="close" for="alert6">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0H14C15.103 0 16 0.897 16 2V20L8 15.428L0 20V2C0 0.897 0.897 0 2 0Z"
                    fill="#1059A9"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 100%; margin: 0 auto">
          <!-- DEMO CONTAINER -->
          <div class="alert error">
            <input type="checkbox" id="alert7" />
            <label class="close" title="close" for="alert7">
              <i class="icon-remove"></i>
            </label>
            <div class="notification">
              <div class="flex">
                <div class="box">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9987 25.6668C17.2203 25.6668 20.137 24.361 22.2483 22.2497C24.3595 20.1385 25.6654 17.2218 25.6654 14.0002C25.6654 10.7785 24.3595 7.86186 22.2483 5.75058C20.137 3.63933 17.2203 2.3335 13.9987 2.3335C10.7771 2.3335 7.8604 3.63933 5.74912 5.75058C3.63787 7.86186 2.33203 10.7785 2.33203 14.0002C2.33203 17.2218 3.63787 20.1385 5.74912 22.2497C7.8604 24.361 10.7771 25.6668 13.9987 25.6668Z"
                      fill="#F5F5F5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 14L12.832 17.5L19.832 10.5"
                      stroke="#1059A9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="notification-content">
                  <h5>Successfully applied</h5>
                  <p>Message form provider here</p>

                  <a href="">Learn More</a>
                </div>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H2C0.897 0 0 0.897 0 2V20L8 15.428L16 20V2C16 0.897 15.103 0 14 0ZM14 16.553L8 13.125L2 16.553V2H14V16.553Z"
                    fill="#344054"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
