<div class="container">
    <div class="card" *ngIf="data != 'rule'">
        <div class="task-head">
            <h4>Warning!</h4>
        </div>

        <p [innerHTML]="htmlStr">
        </p>

        <div class="btns">
            <button (keydown)="onKeyPress($event, 1)" (click)="onKeyPress($event, 1)"
                class="custom-button">
                No, Thanks</button>
            <button mat-flat-button (keydown)="onKeyPress($event, 2)" (click)="onKeyPress($event, 2)">Proceed</button>
        </div>
    </div>

    <div class="card" *ngIf="data === 'rule'" [formGroup]="formGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
        Please select the Cloud Cover % above which a SAR satellite must be tasked
        <span style="padding-left: 5px;">
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="cloudCover" placeholder="0 - 100" class="matOption" type="number" #inputField/>
              </mat-form-field>
        </span>
       </p>
        <p>
        By clicking on 'I agree' next, you authorise the platform to automatically task the SAR satellite of your choice when the cloud cover threshold is exceeded. 
        Please also ensure there is a minimum balance of $10,000 in your account to avoid rejection of the automated tasking.
        </p>
        <div class="btns">
            <button (keydown)="onClick($event, 1)" (click)="onClick($event, 1)"
                class="custom-button">
                I do not agree</button>
            <button mat-flat-button (keydown)="onClick($event, 2)" (click)="onClick($event, 2)" style="width: 120px;"
            [disabled]="this.error || (formGroup.value.cloudCover === null)" type="submit">I agree</button>
        </div>
    </div>
</div>