<div class="container">
  <div class="card">
    <div class="task-head">
      <h4>Warning!</h4>
    </div>

    <p *ngIf="!data.satellogicProcess">
      {{data.name}} does not support AOI recurrence at this time. Please change your time of interest or choose an
      alternative provider.
    </p>
    <p *ngIf="data.satellogicProcess">
      We are unable to accept this task as {{data.name}} requires all taskings to be within the next two weeks. Please
      choose a time within two weeks or choose an alternative provider.
    </p>
  </div>

  <div class="btns">
    <button mat-flat-button (click)="onNoClick()">Continue</button>
  </div>
</div>