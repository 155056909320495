import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { OPERATORS } from 'src/app/services/constants';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  OPERATOR: any;
  darkTheme: boolean;
  tooltipImagePath: string;
  activeUser: any;

  constructor(private dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private localStorageService: DataService,
    private authService: AuthService
  ) {
      this.OPERATOR = OPERATORS;
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;
  
        this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
      });
      this.authService.user.subscribe((user) => (this.activeUser = user));
     }

  ngOnInit(): void {
  }

  onNoClick(click): void {
    this.dialogRef.close(click);
  }

  convertCostToPoints(cost: number): string {
    if (this.activeUser.pointsEligible) {
      const [part1, part2] = this.activeUser.sensorConversionFactor.split(':').map(parseFloat);
      const conversionFactor = part2 / part1;
      const points = Math.round(cost * conversionFactor);
      return `${points} Points`;
    } else {
      return `$${cost}`;
    }
  }
}
