import {
  Component,
  Inject
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'ConfirmDialog',
  templateUrl: 'confirmdialog.html',
})
export class ConfirmDialog {
  title = 'Modify Sensors detail?';
  actionBtn = 'Modify';
  btnEnabled = false;
  keyInword: string;
  successMsg = '';
  currentUser: any;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: { taskid: string; taskname: string; response: any; type: any ; from:any}
  ) {
    this.successMsg = data?.response?.msg;
  }

  onKeyPress(e: any, option: number) {
    if (e.key === 'Enter') {
      this.showTasks(option);
    }
    if (e.keyCode === 13) {
      this.showTasks(1);
    }
  }

  submitForm(event: any) {
    if (event.keyCode === 13) {
      this.showTasks(1);
    }
  }
  showTasks(option: number): void {
    if (option === 1) {
      this.router.navigate(['/task']);
    }
    else {
      if(this.data?.from === 'budget'){
        this.router.navigate(['/newbudget']).then(() => {
          window.location.reload();
        });
      }else{
        this.router.navigate(['/newtask'])
        .then(() => {
          window.location.reload();
        });
      }
     
    }
    this.dialogRef.close('Done');
  }

  onClick() {
    this.dialogRef.close();
  }
}