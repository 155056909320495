<div class="container">
    <div class="card">
        
        <div  *ngIf="!isTask ">
            <div class="task-head">
                <h4>Success!</h4>
            </div>
            <p *ngIf="data?.data?.budget && isBudgetEqual && !data?.pointUser">Generated budget : $ {{data.data.budget[0].minRange}}</p>
            <p *ngIf="data?.data?.budget && !isBudgetEqual && !data?.pointUser">Generated budget : $ {{data.data.budget[0].minRange}} - $ {{data.data.budget[0].maxRange}}</p>

            <p *ngIf="data?.data?.budget && isBudgetEqual && data?.pointUser">Generated budget : {{data.data.budgetPoints[0].minPointRange}} points</p>
            <p *ngIf="data?.data?.budget && !isBudgetEqual && data?.pointUser">Generated budget : {{data.data.budgetPoints[0].minPointRange}} points - {{data.data.budgetPoints[0].maxPointRange}} points</p>

          <div [formGroup]="formGroup" class="task-text">
            <div class="align form-lble">
              <label class="form-label">Budget Name<span>*</span></label>
              <input matInput type="name" formControlName="budgetName"  placeholder="Your budget name"
              maxlength="25" #inputField  (focus)="onFocus('budgetName')"  (input)="onFocusOrInput()" (blur)="onBlur()"  tabindex="-1"/>
           
                
          
            <div class="error-container">
              <mat-error *ngIf="formGroup.get('budgetName').hasError('required') && formGroup.get('budgetName').touched" class="error-messages">
                Budget Name is required
              </mat-error>
              <mat-error *ngIf="formGroup.get('budgetName').hasError('minlength') && formGroup.get('budgetName').touched" class="error-messages">
                At least 5 characters long
              </mat-error>
              <mat-error *ngIf="formGroup.get('budgetName').hasError('maxlength') && formGroup.get('budgetName').touched" class="error-messages">
                Maximum 25 characters long
              </mat-error>
            </div>
          </div>
        </div>
        </div>
        <div *ngIf="isTask" >
          <div class="task-head">
            <h4>Required!</h4>
        </div>
          <div [formGroup]="formTaskGroup" class="task-text">
            
              <div class="align form-lble">
                  <label class="form-label">Project Name<span>*</span></label>
                      <input  maxlength="25" type="name" matInput placeholder="Your project name" (keyup)="nameChng($event, 1)"  
                      formControlName="project" (keydown)="Space($event)"   (focus)="onFocusProject('project')" tabindex="-1"/>
                      <div class="error-container">
                        <mat-error *ngIf="formTaskGroup.get('project').hasError('required') && formTaskGroup.get('project').touched" class="error-messages">Project
                          Name is required</mat-error>
                        <mat-error *ngIf="formTaskGroup.get('project').hasError('minlength')&& formTaskGroup.get('project').touched" class="error-messages">At
                          least 5 characters long</mat-error>
                        <mat-error *ngIf="formTaskGroup.get('project').hasError('maxlength')&& formTaskGroup.get('project').touched"
                        class="error-messages">Maximum 25 characters long</mat-error>
                      </div>
              </div>
              
              <div class="align form-lble">
                  <label class="form-label">Task Name<span>*</span></label>
                      <input matInput type="name" (keyup)="nameChng($event, 2)" placeholder="Your task name" formControlName="taskname"
                      (keydown)="Space($event)" maxlength="35" (focus)="onFocusProject('taskname')"  tabindex="-1"/>
                      <div class="error-container">
                        <mat-error *ngIf="formTaskGroup.get('taskname').hasError('required') && formTaskGroup.get('taskname').touched" class="error-messages">Task
                          Name is required</mat-error>
                        <mat-error *ngIf="formTaskGroup.get('taskname').hasError('minlength')&& formTaskGroup.get('taskname').touched" class="error-messages">At
                          least 5 characters long</mat-error>
                        <mat-error *ngIf="formTaskGroup.get('taskname').hasError('maxlength')&& formTaskGroup.get('taskname').touched"
                        class="error-messages">Maximum 35 characters long</mat-error>
                </div>
              </div>
          </div>
      </div>
      
        <div class="btns">
          <button class="custom-button " (click)="onNoClick('cancel')" *ngIf="isTask" >Cancel</button>
            <button class="custom-button " (click)="onNoClick('close')" *ngIf="!isTask" >Ok,Thanks</button>
            <button class="custom-button" (click)="onNoClick('save')" mat-flat-button
            [disabled]="!isBudgetNameFocusedOrHasValue || isSaveClicked || resetButton" *ngIf="!isTask"  [ngClass]="{' budget-button': isBudgetNameFocusedOrHasValue}"> Save</button>
            <button class="custom-button budget-button" (click)="onNoClick('task')" *ngIf="!isTask">Task</button>
            <button mat-flat-button [ngClass]="{'budget-button': !isBudgetNameFocusedOrHasValue, 'white-button': isBudgetNameFocusedOrHasValue}"  class="custom-button  budget-button"  (click)="onNoClick('save_task')" *ngIf="isTask"  [disabled]="!isFormTaskValid || isSaveClicked">Save</button>
        </div>
    </div>
</div>