<div class="container">
    <div class="card pt-0 pr-0">
      <h4 class="main-head" *ngIf="!this.cancel">
        Capture Opportunities
      </h4>
      <h4 class="main-head" *ngIf="this.cancel">
        Cancel Opportunities
      </h4>
      <div class="tab-panels scrollbar" id="scrollcustom">
        <span *ngIf="this.load" class="load">{{loadMessage}}</span>
        <div [ngClass]="sensorDetails?.length > 1 ? 'table-container' : ''" *ngIf="!this.load">
        <div *ngFor="let reqElement of sensorDetails; let index = index" class="table-wrapper">
          <table mat-table [dataSource]="dataSource[index]" class="mat-elevation-z8"
          *ngIf="!reqElement?.loadMessage">
            <ng-container matColumnDef="sensorName" style="flex: 1;">
              <mat-header-cell *matHeaderCellDef>
                Sensor Name
              </mat-header-cell>
              <mat-cell class="frequency-cell" *matCellDef="let element">
                <span style="min-width: 90px;">{{element?.sensorName }}</span>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="captureStart" style="flex: 1;">
              <mat-header-cell *matHeaderCellDef>
                Date/time of Capture Start (UTC)
              </mat-header-cell>
              <mat-cell class="frequency-cell" *matCellDef="let element">
                <span *ngIf="reqElement?.operatorKey != this.OPERATORS.SATELLOGIC"
                >{{element?.captureStart | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
                </span>
                <span *ngIf="reqElement?.operatorKey === this.OPERATORS.SATELLOGIC">{{element?.captureStart | date: "dd/MM/yyyy - hh.mm a"}}            </span>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="captureEnd" style="flex: 1;">
              <mat-header-cell *matHeaderCellDef>
                Date/time of Capture End (UTC)
              </mat-header-cell>
              <mat-cell class="frequency-cell" *matCellDef="let element">
                <span style="width: 150px !important;" *ngIf="reqElement?.operatorKey != this.OPERATORS.SATELLOGIC">{{element?.captureEnd | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
                </span>
                <span style="width: 150px !important;" *ngIf="reqElement?.operatorKey === this.OPERATORS.SATELLOGIC">{{element?.captureEnd | date: "dd/MM/yyyy - hh.mm a"}}            </span>
  
                  <span *ngIf="element?.metadata">
                    <img style="cursor: pointer;" width="15px" [src]="tooltipImagePath"
                      (click)="getMetadata(element?.metadata)"
                      [matMenuTriggerFor]="tooltipMenu" />
                  </span>
              </mat-cell>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </ng-container>
  
            <ng-container matColumnDef="preference" style="flex: 1;">
              <div class="preference">
                <mat-header-cell *matHeaderCellDef>
                  Preference
                    <span *ngIf="reqElement.SAR"><img style="cursor: pointer;" width="15px" src="../../../assets/images/union-copy.svg"
                        matTooltip="The capture is from one of the following opportunities" /></span>
                </mat-header-cell>
              </div>
              <mat-cell class="frequency-cell" *matCellDef="let element; let i = index">
                <input *ngIf="!reqElement.singleInput" class="matOptionPreference"
                  type="text" [(ngModel)]="element.preference" maxlength="2" appNumericOnly (input)="assignPreferences(index)" />
                <input *ngIf="reqElement.singleInput" class="matOptionPreference" [disabled]="reqElement.SAR"
                  type="text" [(ngModel)]="element.preference" maxlength="1" #preferenceInput
                  (click)="handleInputClick(preferenceInput, element, index)" (keydown)="handleKeyDown($event)"
                  [matTooltip]="reqElement.SAR ? 'Capella opportunities within your time of interest (TOI) are for information only' : ''" />
                <div *ngIf="element.validPreferenceOrder" class="error-message">
                  {{element.error}}
                </div>
              </mat-cell>
            </ng-container>
  
          </table>
          <span *ngIf="reqElement?.loadMessage">
            Sorry, no opportunities are available for the selected time of interest (TOI).<br>
            Please change the TOI and try again.
          </span>
        </div>
        </div>
      </div>
      <div *ngIf="this.cancel" class="btns">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button style="margin-left: 16px !important;" class="submit" (click)="cancelOpportunity()" [disabled]="!this.enabled">Yes,
          Cancel</button>
      </div>
  
      <div *ngIf="!this.cancel" class="btns">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button style="margin-left: 16px !important;" class="submit" (click)="save()"
          [disabled]="this.disable || (isData != sensorDetails?.length)">Save</button>
      </div>
    </div>
  </div>
  
  <mat-menu #tooltipMenu="matMenu"  class="mat-menu-style">
    <app-custom-tooltip [metadata]="metaDataTooltip"></app-custom-tooltip>
  </mat-menu>