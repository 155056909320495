<div class="container">
  <div class="card">
    <div class="task-head">
      <h4 style="font-size: 22px !important;">Please select from the list of available feeds
        <img
        style="margin-left: 2px; margin-top: -1px;"
        width="30px"
        src="../../../assets/images/beta-version.png"
      />
      </h4>
    </div>

    <div style="padding-top: 20px;">
    <span *ngIf="this.load" class="load">{{ loadMessage }}</span>
    <div class="scrollbar">
      <mat-card class="card mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort class="table-class">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>
              Information Feeds
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span style="width: 80% !important;
              display: flex;align-items: baseline;">
              <mat-checkbox
              [checked]="row.isChecked"
              color="primary"
              (change)="onItemChange(row)"
              [disabled]="!row.enabled"
            >
            </mat-checkbox>
            <span style="display: flex;align-items: center;">
              <span class="td-first" style="margin-left: 15px;">{{ row?.name }}</span>
              <span>
                <img style="margin-left: 10px; cursor: pointer;" width="14px" [src]="tooltipImagePath"
                  [matTooltip]="row?.description ? getIsToolTip(row) : ''" />
              </span>
              <mat-icon *ngIf="row?.f1Score" style="margin-left: 10px; cursor: pointer;" class="f1Score"
                [matTooltip]="'F1 Score: ' + row?.f1Score">
                functions
              </mat-icon>
            </span>
            </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="price">         
            <mat-header-cell *matHeaderCellDef style="display: flex; flex: none;">
              Cost
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="display: flex; flex: none;">
              <!-- <span>${{ row.price }}</span> -->
              <span *ngIf="activeUser.pointsEligible">{{ row.points }} Points</span>
              <span *ngIf="!activeUser.pointsEligible">${{ row.price }}</span>
            </mat-cell>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </ng-container>
        </table>
      </mat-card>
    </div>
   </div>

   <div *ngIf="error" class="error-message">
    The feed(s) selected do not apply to the area of interest (AOI)/ point of interest (POI) selected.<br>
    Please select another feed.
   </div>

    <div class="btns">
      <button class="custom-button" (click)="onNoClick('no')">Cancel</button>
      <button
        mat-stroked-button
        class="submit"
        (click)="onNoClick('yes')"
        [disabled]="catlogData.length === 0 || error"
      >
        Add
      </button>
    </div>
  </div>
</div>
