<div class="container" style="position: relative">
  <div class="tabset">
    <!-- Tab 1 -->
    <input type="radio" name="tabset" id="all" aria-controls="all" checked (click)="onItemChange('all')" />
    <label for="all" style="padding-top: 19px;">All</label>
    <!-- Tab 2 -->
    <input type="radio" name="tabset" id="success" aria-controls="success"
      (click)="onItemChange('success')" />
    <label for="success">Success</label>
    <!-- Tab 3 -->
    <input type="radio" name="tabset" id="failed" aria-controls="failed"
      (click)="onItemChange('failed')" />
    <label for="failed">Failed</label>
    <div class="tab-panels scrollbar" id="scrollcustom" appScrollTracker (scrolledBottom)="balanceRecords > 0 ? getOrderData(this.tabType, currentIndex) : ''">
      <div class="tab-panel">
        <app-notask *ngIf="filteredData?.length === 0" [order]=true></app-notask>
        <div class="card" *ngIf="filteredData?.length > 0">
          <span>Order History</span>
        </div>
        <div class="table-overflow-box">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
            class="mat-elevation-z8" *ngIf="filteredData?.length > 0">
            <ng-container matColumnDef="orderplace">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                <span class="th-first"> Order/Credit Note Date (UTC)</span>

              </th>
              <td mat-cell *matCellDef="let element" id="orderdate">
                <span class="td-first">{{ ((element?.partialPayment && element?.refundApproved) ? element.updatedAt :  element.createdAt) | date: "dd/MM/yyyy - hh.mm a" : "GMT" }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="projectname">
              <mat-accordion>
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                  Project Name
                </th>
                <mat-expansion-panel hideToggle>
                  <td class="projectName" mat-cell *matCellDef="let element">
                    <span
                      [matTooltip]="element?.projectname?.length > 12 ? element?.projectname : ''">{{element?.projectname}}</span>
                  </td>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>

            <ng-container matColumnDef="taskname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
                Task Name
              </th>
              <td class="projectName" mat-cell *matCellDef="let element">
                <span [matTooltip]="element?.taskname?.length > 12 ? element?.taskname : ''">{{element.taskname}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="orderno">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
                Order Number
              </th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>
            <ng-container matColumnDef="receipt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
                <span class="th-width"> Receipt</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="td-width">
                  <img *ngIf="(element.payoption !== 'Pay later') && element.orderstatus === 'success' && !element.refundCheck"
                  [src]="invoiceImagePath"
                   alt="" style="cursor: pointer" (click)="btnAction(element)"/>
                  <img *ngIf="element.payoption === 'Pay later' || element.orderstatus === 'Failed' || element.refundCheck"
                    src="../../../assets/images/receiptdisable.png" alt="" />
                </span>

              </td>
            </ng-container>

            <ng-container matColumnDef="creditNote">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
                <span class="th-width"> Credit Note</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="td-width">
                  <img *ngIf="(element.payoption !== 'Pay later') && element.refundCheck"
                  [src]="invoiceImagePath" alt="" style="cursor: pointer" (click)="btnAction(element)"/>
                  <img *ngIf="element.payoption === 'Pay later' || !element.refundCheck"
                    src="../../../assets/images/receiptdisable.png" alt="" />
                </span>

              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
                <span class="th-width" *ngIf="!currentUser?.allocated">Amount</span>
                <span class="th-width" *ngIf="currentUser?.allocated">Task Number</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <span class="td-width">{{ (element?.refundAmount? element.refundAmount : element.orderamount) | currency}} </span> -->
                <span class="td-width" *ngIf="!activeUser.pointsEligible && !activeUser.allocated">{{ (element?.refundAmount? element.refundAmount : element.orderamount) | currency}} </span>
                <span class="td-width" *ngIf="!activeUser.pointsEligible && activeUser.allocated"> {{  element?.refundTaskNumber ? element?.refundTaskNumber : element?.totalTaskNumber}} </span>
                <span class="td-width" *ngIf="activeUser.pointsEligible">{{ (element?.refundPoint? element.refundPoint : element.orderPoint)}} Points</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
                <span class="th-width"> Invoice</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="td-width">
                  <img [src]="receiptsImagePath"
                    alt=""
                    *ngIf="element.payoption === 'Pay later' && element.orderstatus === 'success'"
                    style="cursor: pointer" (click)="btnAction(element)"/>
                  <img *ngIf="element.payoption !== 'Pay later' || element.orderstatus === 'Failed'"
                    src="../../../assets/images/receipt.svg" alt="" />
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="set">
    <div class="btns">
      <button>
        <mat-form-field appearance="outline" class="dateinput">
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="todayDate">
            <input readonly matStartDate formControlName="start" placeholder="Select Dates"
              (dateChange)="datewiseOrder('start', $event)" (click)="picker.open()" style="cursor: pointer;" />
            <input matEndDate formControlName="end" (dateChange)="datewiseOrder('end', $event)" (click)="picker.open()"
              readonly />
          </mat-date-range-input>

          <mat-datepicker-toggle matPrefix [for]="picker"> <mat-icon matDatepickerToggleIcon matPrefix><img
                src="../../../assets/images/calendar.svg"></mat-icon> </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </button>

      <button mat-raised-button class="add" style="padding: 5px 15px;
        font-family: 'Barlow', sans-serif !important;
        font-size: 14px;" (click)="genOrderReport()" [disabled]="filteredData?.length === 0">
        Order Report
      </button>

      <span style="padding-left: 14px;">
        <button mat-raised-button class="add" style="padding-left: 5px 15px;
        font-family: 'Barlow', sans-serif !important;
        font-size: 14px;" (click)="exportToCSV()" [disabled]="filteredData?.length === 0">
        Export CSV
      </button>
      </span>
    </div>
  </div>
</div>