import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { FootersuccessComponent } from '../shared/footer/footersuccess/footersuccess.component';
import { ThemePalette } from '@angular/material/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RequestDemoComponent implements OnInit {
  @ViewChild('picker') picker: any;
  panelOpenState: boolean = false;
  isFirst = true;
  formGroup: any = FormGroup;
  name = '';
  email = '';
  phone = '';
  messages = '';
  company = '';
  date = '';
  todayDate: Date = new Date();
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = true;
  public minDate: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  clicked: boolean = false;

  constructor(public dialog: MatDialog, private userService: UserService, private recaptchaV3Service: ReCaptchaV3Service) {
    this.formGroup = new FormGroup({
      name: new FormControl(this.name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]
      ),
      email: new FormControl(this.email, [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]
      ),
      company: new FormControl(this.company, [
        Validators.required,
      ]),
      phone: new FormControl(this.phone, [
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(10),
      ]
      ),
      messages: new FormControl(this.messages, [
        Validators.required,
        Validators.maxLength(500),
      ]
      ),
      requested_at: new FormControl(this.date, [
        Validators.required,
      ]
      ),
      actioned: new FormControl("false"),
      created_at: new FormControl(new Date()),
      reCAPTCHAToken: new FormControl(),
    });
  }

  ngOnInit(): void {
  }

  get fields() {
    return this.formGroup.controls;

  }

  async onSubmit() {
    this.clicked = true;
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      const reCAPTCHAToken = token;
      this.formGroup.get('actioned').setValue("false");
      this.formGroup.get('created_at').setValue(new Date());
      this.formGroup.get('reCAPTCHAToken').setValue(reCAPTCHAToken);
      const data = 'demo';
      this.userService.requestDemo(this.formGroup.value).subscribe((res: any) => {
        this.formGroup.reset();
        if (res.Msg != 'Failed captcha verification') {
          this.dialog.open(FootersuccessComponent, { width: '50%', backdropClass: 'blurr', data });
        }
      });
    });
  }

}
