<div class="container">
  <div class="card pt-0 pr-0">
    <h4 class="main-head" *ngIf="!this.cancel">
      Capture Opportunities
    </h4>
    <h4 class="main-head" *ngIf="this.cancel">
      Cancel Opportunities
    </h4>
    <div class="tab-panels scrollbar" id="scrollcustom">
      <span *ngIf="this.load" class="load">{{loadMessage}}</span>
      <table *ngIf="!this.load && !this.cancel" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="sensorName" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Sensor Name
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span style="min-width: 90px;">{{element?.sensorName }}</span>
            <!-- <span *ngIf="element?.lookAngles"><img style="cursor: pointer;" width="15px" src="../../../assets/images/union.svg"
              matTooltip="The opportunity tasking of Capella SAR uses the following default parameters: {{'\n' + element?.lookAngles}}"
              /></span> -->
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="opportunityID" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Opportunity ID
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span>{{element?.opportunityid ? element?.opportunityid : element?.sensorid}}</span>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="captureStart" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Date/time of Capture Start (UTC)
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span *ngIf="this.data.row?.operatorKey != this.OPERATORS.SATELLOGIC"
            >{{element?.captureStart | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
            </span>
            <span *ngIf="this.data.row?.operatorKey === this.OPERATORS.SATELLOGIC">{{element?.captureStart | date: "dd/MM/yyyy - hh.mm a"}}            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="captureEnd" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Date/time of Capture End (UTC)
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span style="width: 150px !important;" *ngIf="this.data.row?.operatorKey != this.OPERATORS.SATELLOGIC">{{element?.captureEnd | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
            </span>
            <span style="width: 150px !important;" *ngIf="this.data.row?.operatorKey === this.OPERATORS.SATELLOGIC">{{element?.captureEnd | date: "dd/MM/yyyy - hh.mm a"}}            </span>

              <span *ngIf="element?.metadata">
                <img style="cursor: pointer;" width="15px" [src]="tooltipImagePath"
                  (click)="getMetadata(element?.metadata)"
                  [matMenuTriggerFor]="tooltipMenu" />
              </span>
          </mat-cell>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </ng-container>

        <ng-container matColumnDef="orbital" style="flex: 1;" *ngIf="this.SAR">
          <mat-header-cell *matHeaderCellDef>
            Orbital Direction
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span style="text-transform: capitalize;">{{element?.orbital }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="preference" style="flex: 1;">
          <div class="preference">
            <mat-header-cell *matHeaderCellDef>
              Preference
                <span *ngIf="this.SAR"><img style="cursor: pointer;" width="15px" src="../../../assets/images/union-copy.svg"
                    matTooltip="The capture is from one of the following opportunities" /></span>
            </mat-header-cell>
          </div>
          <mat-cell class="frequency-cell" *matCellDef="let element; let i = index">
            <input *ngIf="!this.singleInput" class="matOptionPreference"
              type="text" [(ngModel)]="element.preference" maxlength="2" appNumericOnly (input)="assignPreferences()" />
            <input *ngIf="this.singleInput" class="matOptionPreference" [disabled]="this.SAR"
              type="text" [(ngModel)]="element.preference" maxlength="1" #preferenceInput
              (click)="handleInputClick(preferenceInput, element)" (keydown)="handleKeyDown($event)"
              [matTooltip]="this.SAR ? 'Capella opportunities within your time of interest (TOI) are for information only' : ''" />
            <div *ngIf="element.validPreferenceOrder" class="error-message">
              {{element.error}}
            </div>
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="subDaily" style="flex: 1;" *ngIf="this.SAR">
          <mat-header-cell *matHeaderCellDef>
            Multiple Passes
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <mat-checkbox class="example-margin" [checked]="element.subDaily" color="primary" style="width: 20px; height: 20px; margin-left: 34px;"
            [(ngModel)]="element.subDaily">
             </mat-checkbox>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="preview" *ngIf="this.SAR">
          <mat-header-cell class="mat-head-cells" *matHeaderCellDef sortActionDescription="Sort by button">Footprint
          </mat-header-cell>
          <mat-cell *matCellDef="let element"  class="preview-cell overlay">

            <span (click)="OpenPopup(element)"
              [ngClass]="element.canceled > 0 && element.completed < 1 && element.wip === 0 && element.delivering === 0 ? 'material-icons-outlined preview-disabled' : 'material-icons-outlined map-active'">
              map
            </span>

          </mat-cell>
        </ng-container>

      </table>

      <table *ngIf="!this.load && this.cancel" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="sensorName" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Sensor Name
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span>{{element?.sensorName }}</span>
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="opportunityID" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Opportunity ID
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span>{{element?.opportunityid ? element?.opportunityid : element?.sensorid}}</span>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="captureStart" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Date/time of Capture Start (UTC)
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span>{{element?.captureStart | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="captureEnd" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
            Date/time of Capture End (UTC)
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <span style="width: 60%;">{{element?.captureEnd | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
            </span>

            <span *ngIf="element?.metadata">
              <img style="cursor: pointer;" width="15px" [src]="tooltipImagePath"
              (click)="getMetadata(element?.metadata)"
              [matMenuTriggerFor]="tooltipMenu" />
          </span>
          </mat-cell>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </ng-container>

        <ng-container matColumnDef="cancel" style="flex: 1;">
          <mat-header-cell *matHeaderCellDef>
          </mat-header-cell>
          <mat-cell class="frequency-cell" *matCellDef="let element">
            <mat-checkbox class="example-margin" [checked]="element?.cancel" color="primary"
              style="width: 20px; height: 20px; margin-left: 34px;" [(ngModel)]="element.cancel" (change)="onCheck();">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

      </table>
    </div>
    <div *ngIf="this.cancel" class="btns">
      <button class="custom-button" (click)="onNoClick()">Close</button>
      <button mat-stroked-button style="margin-left: 16px !important;" class="submit" (click)="cancelOpportunity()" [disabled]="!this.enabled">Yes,
        Cancel</button>
    </div>

    <div *ngIf="!this.cancel" class="btns">
      <button class="custom-button" (click)="onNoClick()">Close</button>
      <button mat-stroked-button style="margin-left: 16px !important;" class="submit" (click)="save()"
        [disabled]="this.disable">Save</button>
    </div>
  </div>
</div>

<mat-menu #tooltipMenu="matMenu"  class="mat-menu-style">
  <app-custom-tooltip [metadata]="metaDataTooltip"></app-custom-tooltip>
</mat-menu>