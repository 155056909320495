<!-- Registration success popup -->
<div *ngIf="data?.type === 'registration'" style="padding-top: 20px;">
  <div class="container" style="text-align: center;">

    <h4>Success!</h4>
    <hr />

    <div class="task-head">
      <p>Thank you for registering for the Eartheye Sat-Tasking service. We will notify you of your successful
        onboarding via email soon after we have verified the details provided. We may ask for additional information via
        email. You will verify your account by clicking on the link on the email notification before logging in for the
        first time. </p>
    </div>

  </div>

  <div class="btns">
    <button mat-flat-button (click)="onNoClick()" routerLink="/howitworks">Ok, Thanks</button>


  </div>
</div>

<!-- Bank tarnsfe details -->
<div *ngIf="data?.type === 'bank'">
  <div class="container" style="text-align: center">
    <h4>Please Note</h4>
    <hr />

    <div class="align-item">
      <p>An invoice for the total amount will be generated and posted on the Deposits page. <br>
        Please note the telegraphic transfer (TT) details:</p>
      <div fxLayoutAlign="center center">
        <table>
          <tr>
            <td class="bold">A/C Holder</td>
            <td>{{bankDetails.accountHolder}}</td>
          </tr>
          <tr>
            <td class="bold">A/C No</td>
            <td>{{bankDetails.accountNumber}}</td>
          </tr>
          <tr>
            <td class="bold">SWIFT Code</td>
            <td>{{bankDetails.SWIFTcode}}</td>
          </tr>
          <tr>
            <td class="bold">Bank Name</td>
            <td>{{bankDetails.bankName}}</td>
          </tr>
          <tr>
            <td class="bold">Bank Address</td>
            <td>{{bankDetails.bankAddress}}</td>
          </tr>
        </table>
      </div>
      <p>Telegraphic transfer (TT) details will also be emailed to <b style="color: #1059a9">{{ data.user.email
          }}.</b>
      </p>

      <p *ngIf="data.process !== 'deposit'">
        Once you have made the TT, please wait for an email notifying you of the deposit receipt and full access to the platform before logging back in.
      </p>
    </div>
  </div>

  <div class="buttons" fxLayoutAlign="center center" fxLayoutGap="15px"
  style="padding-bottom: 15px !important;">
    <button class="custom-button" (click)="onNoClick()">
      Close
    </button>
    <button mat-flat-button (click)="onClick()">Ok, Thanks</button>
  </div>
</div>

<!-- Allocation confirmation -->
<div *ngIf="data?.type === 'confirmation' && !this.data.user?.pointsEligible">
  <div class="container" style="text-align: center;">

    <h4>Confirmation Request</h4>
    <hr />

    <div style="font-size: 16px;">
      <p>Do you want to allocate your topped-up amount across a select number of operators?  </p>
    </div>

  </div>

  <div class="buttons" fxLayoutAlign="center center" fxLayoutGap="15px" style="padding-top: 15px;padding-bottom: 12px;">
    <button class="custom-button" (click)="onNoClick()" style="width: 125px;">
      No, please
    </button>
    <button mat-flat-button (click)="onClick()" style="width: 125px;">Yes, please</button>
  </div>
</div>

<!-- Allocation success -->
<div *ngIf="data?.type === 'success'">
  <div class="container" style="text-align: center;">

    <h4>Success!</h4>
    <hr />

    <div style="font-size: 16px;">
      <p>Well done, you have successfully allocated funds to your preferred sensors.  </p>
    </div>

  </div>

  <div class="buttons" fxLayoutAlign="center center" fxLayoutGap="15px" style="padding-top: 15px;padding-bottom: 12px;">
    <button class="custom-button" routerLink="/task" style="margin: 0;width: 125px;" (click)="onNoClick()"
    [ngClass]="{ 'button-color': darkTheme === true }">
      My Task
    </button>
    <button mat-flat-button routerLink="/profile" style="width: 125px;" (click)="onNoClick()">My Profile</button>
  </div>
</div>