import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentUser: any;
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const BearerToken = sessionStorage.getItem('jwttoken') ? JSON.parse(sessionStorage.getItem('jwttoken') || "") : undefined;
        if(!request.url.includes('ee-collection-assets'))
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${BearerToken}`
                }
            });

        return next.handle(request);
    }
}