<div class="container">
    <div class="card">
      <div class="task-head">
        <h4 *ngIf="data?.type === 'AIS' || data?.type === 'pleaseNote'">Please Note</h4>
        <h4 *ngIf="data?.type === 'download'">Success!</h4>
      </div>

      <!-- <p>{{data.message}}</p> -->
      <p [innerHTML]="message"></p>

    </div>
  
    <div class="btns">
      <button mat-flat-button (click)="onClose()">Ok, Thanks</button>
    </div>
  </div>