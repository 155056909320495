import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { OPERATORS } from 'src/app/services/constants';


@Component({
  selector: 'app-advance-settings',
  templateUrl: './advance-settings.component.html',
  styleUrls: ['./advance-settings.component.scss']
})
export class AdvanceSettingsComponent implements OnInit {
  formGroup: FormGroup | any;
  advanceForm: FormGroup | any;
  umbraAdvanceForm: FormGroup | any;
  orbitalPlaneOptions: any;
  orbitalStateOptions: any;
  observationDirections: any;
  polarizations: any;
  imagingMode: any;
  productCategory: any;
  numOfLooks: any;
  minNumber = 5;
  maxNumber = 50;
  minWidth = 5;
  maxWidth = 10;
  minLength = 20;
  maxLength = 200;
  spotLooks = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  slideLooks = [1, 2, 3, 4, 5];
  firstInput: number;
  secondInput: number;
  error: boolean = false; maxValue: boolean;
  wholeNumber: boolean;
  OPERATOR: any;
  allocationForm: FormGroup | any;
  allocationError: boolean = true;
  sensorData: any;
  actualValue: number;
  SensorsTypes: any;
  selectedSensorType: any;
  resolution: any;
  top_up: number = 0;
  sensorList: any;
  taskId: string | null;
  saveClick: boolean = false;
  availableAmount: number = 0;
  selectedSensor: any;
  taskingTiers: any;
  selectedOperator: any;
  selectedResolution: any;
  selectedTaskingTier: any;
  selectedTaskingTierCost: any;
  exceededAmount: boolean = false;
  insufficientData: any;
  percentageOfTopUp: number = 0;
  selectedResolutionCost: any;
  availableBalance: any;
  resolutionValue: any;
  constructor(private dialogRef: MatDialogRef<AdvanceSettingsComponent>, private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.OPERATOR = OPERATORS;
    this.imagingMode = this.data?.selectedRow?.selectedModes[0]?.key;
    this.productCategory = this.data?.selectedRow?.selectedONAOption?.category;
    this.availableAmount = this.data?.balance;
    this.availableBalance = this.data?.topUpBalance;
    this.resolutionValue = this.data?.selectedRow.selectedResolution?.value;

    this.formGroup = new FormGroup({
      orbitalPlane: new FormControl(''),
      orbitalState: new FormControl(''),
      observationDirection: new FormControl(''),
      polarization: new FormControl(''),
      numOfLooks: new FormControl(''),
      lookAngleStart: new FormControl('', [Validators.min(this.minNumber), Validators.max(this.maxNumber), Validators.pattern(/^[0-9]\d*$/)]),
      lookAngleEnd: new FormControl('', [Validators.max(this.maxNumber), Validators.pattern(/^[0-9]\d*$/)]),
      lookAngle: new FormControl('')
    });

    this.advanceForm = new FormGroup({
      sarPolarization: new FormControl(''),
      sarOrbitDirection: new FormControl(''),
      productAnciallary: new FormControl('')
    });

    this.umbraAdvanceForm = new FormGroup({
      targetAzimuthAngleMin: new FormControl('', [Validators.min(0), Validators.max(360), Validators.pattern(/^[0-9]\d*$/)]),
      targetAzimuthAngleMax: new FormControl('', [Validators.max(360), Validators.pattern(/^[0-9]\d*$/)]),
      sarPolarization: new FormControl('')
    });

    this.allocationForm = new FormGroup({
      id: new FormControl(''),
      sensorType: new FormControl(''),
      sensorName: new FormControl(),
      actualAmount: new FormControl(''),
      resolution: new FormControl(''),
      operator: new FormControl(''),
      taskingTier: new FormControl(''),
      unitCost: new FormControl('', [Validators.min(1), Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      amountInPercentage: new FormControl('', [Validators.min(1), Validators.max(this.availableAmount), Validators.minLength(1), Validators.required,
      Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      noOfTasks: new FormControl('', [Validators.min(1), Validators.minLength(1), Validators.required,
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)])
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.error = false;
      this.maxValue = false;
      this.wholeNumber = false;
      const startValue = this.formGroup.get('lookAngleStart').value;
      const endValue = this.formGroup.get('lookAngleEnd').value;
      this.firstInput = startValue;

      if (endValue > 50) this.maxValue = true;
      else this.maxValue = false;

      if ((endValue && !this.isPositiveWholeNumber(endValue)) || (startValue && !this.isPositiveWholeNumber(startValue))) {
        this.wholeNumber = true;
      } else this.wholeNumber = false;

      if ((startValue && startValue >= endValue) || (endValue && !startValue)) {
        this.formGroup.get('lookAngleEnd').setErrors({ 'range': true });
        this.error = true;
      } else {
        this.error = false;
        this.formGroup.get('lookAngleEnd').setErrors(null);
      }
    });

    this.umbraAdvanceForm.valueChanges.subscribe(() => {
      this.error = false;
      this.maxValue = false;
      this.wholeNumber = false;
      const startValue = this.umbraAdvanceForm.get('targetAzimuthAngleMin').value;
      const endValue = this.umbraAdvanceForm.get('targetAzimuthAngleMax').value;
      this.firstInput = startValue;

      if (endValue > 360) this.maxValue = true;
      else this.maxValue = false;

      if ((endValue && !this.isPositiveWholeNumber(endValue)) || (startValue && !this.isPositiveWholeNumber(startValue))) {
        this.wholeNumber = true;
      } else this.wholeNumber = false;

      if (((startValue && startValue >= endValue) || (endValue && startValue === null))) {
        this.umbraAdvanceForm.get('targetAzimuthAngleMax').setErrors({ 'range': true });
        this.error = true;
      } else {
        this.error = false;
        this.umbraAdvanceForm.get('targetAzimuthAngleMax').setErrors(null);
      }
    });

    this.orbitalPlaneOptions = this.apiService.orbitalPlane;
    this.orbitalStateOptions = this.apiService.orbitalState;
    this.observationDirections = this.apiService.observationDirections;
    this.polarizations = this.apiService.polarization;

    if (this.data?.selectedRow?.advanceOptions) {
      this.selectRecord(this.data.selectedRow);
    }

    this.allocationForm.valueChanges.subscribe(() => {
      if (this.isAnyFieldEmpty()) {
        this.allocationError = true;
      } else {
        this.allocationError = false;
      }
    });

    if (this.data?.type === 'allocation') {
      this.taskId = '';
      this.taskId = localStorage.getItem('taskData')
      this.getSensorTypes();

      if (this.data?.row) {
        this.editRecord(this.data.row);
      }

    }
  }

  isPositiveWholeNumber(value: number): boolean {
    return value !== null && value >= 1 && value % 1 === 0;
  }

  selectRecord(advanceOptions: any) {
    this.formGroup.get('orbitalPlane').setValue(advanceOptions?.orbitalPlane);
    this.formGroup.get('orbitalState').setValue(advanceOptions?.orbitalState);
    this.formGroup.get('observationDirection').setValue(advanceOptions?.observationDirection);
    this.formGroup.get('polarization').setValue(advanceOptions?.polarization);
    this.formGroup.get('lookAngleStart').setValue(advanceOptions?.selectedONAOption?.minONA);
    this.formGroup.get('lookAngleEnd').setValue(advanceOptions?.selectedONAOption?.maxONA);
    this.advanceForm.get('sarPolarization').setValue(advanceOptions?.selectedPolarization);
    this.advanceForm.get('sarOrbitDirection').setValue(advanceOptions?.selectedOrbitDirection);
    this.advanceForm.get('productAnciallary').setValue(advanceOptions?.selectedAnciallary);
    this.umbraAdvanceForm.get('targetAzimuthAngleMin').setValue(advanceOptions?.selectedAzimuthAngleMin);
    this.umbraAdvanceForm.get('targetAzimuthAngleMax').setValue(advanceOptions?.selectedAzimuthAngleMax);
    this.umbraAdvanceForm.get('sarPolarization').setValue(advanceOptions?.selectedPolarization);
  }

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  submit() {
    let lookAngle: any;

    if (this.formGroup.value?.lookAngleStart && this.formGroup.value?.lookAngleEnd) {
      lookAngle = {
        category: this.productCategory,
        minONA: parseInt(this.formGroup.value?.lookAngleStart),
        maxONA: parseInt(this.formGroup.value?.lookAngleEnd),
        value: '1',
        key: this.formGroup.value?.lookAngleStart+'-'+this.formGroup.value?.lookAngleEnd+'°'
      }
      this.formGroup.controls.lookAngle.setValue(lookAngle);
    }

    if (this.resolutionValue === '0.25m') {
      this.formGroup.controls.numOfLooks.setValue(5);
    } else if (this.resolutionValue === '0.5m' || this.resolutionValue === '1m') {
      this.formGroup.controls.numOfLooks.setValue(3);
    } else this.formGroup.controls.numOfLooks.setValue(1);

    this.dialogRef.close(this.formGroup.value);
  }

  save() {
    this.dialogRef.close(this.advanceForm.value);
  }

  saveChanges() {
    this.dialogRef.close(this.umbraAdvanceForm.value);
  }

  get fields() { return this.allocationForm.controls; }

  isAnyFieldEmpty(): boolean {
    for (const key of Object.keys(this.allocationForm.controls)) {
      if (key != 'actualAmount' && key != 'resolution' && key != 'id' && key != 'operator') {
        const controlValue = this.allocationForm.get(key).value;
        if (controlValue === null || controlValue === undefined || controlValue === '') {
          return true;
        }
      }
    }
    return false;
  }

  saveData() {
    this.saveClick = true;
    this.allocationForm.value.amountInPercentage = parseFloat(this.allocationForm?.value?.amountInPercentage);
    this.allocationForm.value.actualAmount = this.convertPercentage(this.percentageOfTopUp);
    this.allocationForm.value.operator = this.allocationForm?.value?.sensorName.name;
    this.allocationForm.value.resolution = this.allocationForm?.value?.sensorName.resolution;
    this.allocationForm.value.noOfTasks = this.allocationForm?.value?.noOfTasks;
    this.allocationForm.value.taskingTier = this.allocationForm?.value?.taskingTier?.key;
    this.allocationForm.value.unitCost = this.allocationForm?.value?.unitCost;
    if (this.allocationForm.value.id) {
      this.apiService.editAllocation(this.allocationForm.value).subscribe((res: any) => {
        this.dialogRef.close(this.allocationForm.value);
      });
    } else {
      this.apiService.addAllocation(this.allocationForm.value).subscribe((res: any) => {
        this.dialogRef.close(this.allocationForm.value);
      });
    }
  }

  sensorChange(event: any, edit) {
    this.sensorData = [];
    this.sensorList = [];
    if (!edit) {
      this.resetOnchange();
    }
    this.selectedSensorType = '';
    this.selectedSensor = event;
    this.apiService.getSensorData(event, this.taskId).subscribe((res: any) => {
      for (const [sensorIndex, sensors] of res.sensors.entries()) {
        for (const [idx, items] of sensors.resolution.entries()) {
          const operator = { name: sensors.name, resolution: items.value, cost: items.cost, sensor: sensors.sensor };
          this.sensorData.push(operator);
        }
      }

      if (this.data.allocatedData?.length > 0) {
        for (const sensor of this.sensorData) {
          const isExist = this.data.allocatedData.find(
            tableData => tableData.operator === sensor.name && tableData.resolution === sensor.resolution
          );
          if (isExist) {
            sensor.disabled = true;
          }
          this.sensorList.push(sensor);
        }
      } else {
        this.sensorList = this.sensorData;
      }
    });
  }

  getSensorTypes() {
    this.apiService.getSensorTypes(this.taskId).subscribe((res: any) => {
      if (res) {
        this.SensorsTypes = res.sensorTypes;
      }
    });
  }

  convertPercentage(percentage: number) {
    const decimalValue = percentage / 100;
    const baseValue = this.data?.topUpAmount;
    this.actualValue = decimalValue * baseValue;
    return this.actualValue;
  }

  editRecord(data: any) {
    let event = { name: data?.operator, resolution: data?.resolution }
    this.allocationForm.get('id').setValue(data._id);
    this.allocationForm.get('sensorType').setValue(data?.sensor);
    this.allocationForm.get('sensorName').setValue(event);
    this.allocationForm.get('amountInPercentage').setValue(data?.amountPercentage);
    this.allocationForm.get('actualAmount').setValue(data?.amountDollar);
    this.allocationForm.get('resolution').setValue(data?.resolution);
    this.allocationForm.get('operator').setValue(data?.operator);
    this.allocationForm.get('taskingTier').setValue(data?.taskingTier);
    this.allocationForm.get('noOfTasks').setValue(data?.numberOfTasks);
    this.allocationForm.get('unitCost').setValue(data?.unitCost);
    const edit = true;

    this.sensorChange(data?.sensor, edit);
    const eventData = {
      name: data?.operator,
      resolution: data?.resolution,
      taskingTier: data?.taskingTier,
      isTristereo: data.isTristereo,
      cost: data?.unitCost
    };
    this.selectSensor(eventData, edit);
    if (data.sensor === "Stereo") {
      this.selectedSensorType = event.name + ' - Stereo (' + event.resolution + ')';
    }
    if (data.isTristereo) {
      this.selectedSensorType = event.name + ' - Tristereo (' + event.resolution + ')';
    } else {
      this.selectedSensorType = event.name + ' (' + event.resolution + ')';
    }
  }

  resetOnchange() {
    this.allocationForm.get('taskingTier').setValue('');
    this.allocationForm.get('unitCost').setValue('');
    this.allocationForm.get('amountInPercentage').setValue('');
    this.allocationForm.get('noOfTasks').setValue('');
  }

  selectSensor(event: any, edit) {
    if (!edit) {
      this.resetOnchange();
    }
    this.selectedOperator = event.name;
    this.selectedResolution = event.resolution;
    if (this.selectedOperator == "Umbra") {
      this.selectedResolutionCost = event.cost;
    }
    if (event.sensor === "Stereo") {
      this.selectedSensorType = event.name + ' - Stereo (' + event.resolution + ')';
    } else if (event.sensor === "Tristereo" || event.isTristereo) {
      this.selectedSensorType = event.name + ' - Tristereo (' + event.resolution + ')';
    } else {
      this.selectedSensorType = event.name + ' (' + event.resolution + ')';
    }
    this.apiService.getTaskingTiersData(this.selectedSensor, event).subscribe((res: any) => {
      if (res) {
        this.taskingTiers = res.taskingTiers;
        if (edit) {
          const result = this.taskingTiers.find(tier => tier.key === event?.taskingTier);
          this.allocationForm.get('taskingTier').setValue(result);
          this.taskingTierChange(result, edit);
        }
      }

    });
  }
  taskingTierChange(event: any, edit) {
    this.selectedTaskingTier = event.value;
    this.selectedTaskingTierCost = this.selectedOperator !== "Umbra" ? event.unitcost : this.selectedResolutionCost;
    this.allocationForm.get('unitCost').setValue(this.selectedTaskingTierCost);
    if (!edit) {
      this.allocationForm.get('amountInPercentage').setValue();
      this.allocationForm.get('noOfTasks').setValue();
    }
  }

  valChng(keyInword: any): void {
    const topUpAmount = this.data.topUpAmount;
    const amountInPercentage = this.allocationForm.get('amountInPercentage').value;
    const amount = (amountInPercentage / 100) * topUpAmount;
    // Calculate noOfTasks
    const noOfTasks = amount / this.selectedTaskingTierCost;
    // Round noOfTasks to the nearest whole number
    const roundedNoOfTasks = Math.floor(noOfTasks);

    // Calculate the amount based on the rounded noOfTasks
    const roundedAmount = roundedNoOfTasks * this.selectedTaskingTierCost;
    const percentageOfTopUp = (roundedAmount / topUpAmount) * 100;
    if (percentageOfTopUp > 0) {
      this.percentageOfTopUp = percentageOfTopUp;
    } else {
      this.percentageOfTopUp = 0;
    }
    if (amount < this.selectedTaskingTierCost) {
      this.allocationForm.get('amountInPercentage').setErrors({ exceededAmount: true });
    }
    if (noOfTasks !== null && (isNaN(noOfTasks) || noOfTasks <= 0)) {
      this.allocationForm.get('noOfTasks').setValidators([Validators.min(1), Validators.required]);
    }
    if (noOfTasks !== null && (!isNaN(noOfTasks) || noOfTasks > 0)) {
      this.allocationForm.get('noOfTasks').setValue(roundedNoOfTasks);
    }
    if (this.data?.row) {
      this.availableAmount = 0;
      this.availableAmount = this.data.balance + this.data.row.amountPercentage;
      this.allocationForm.get('amountInPercentage').setValidators([
        Validators.min(1),
        Validators.max(this.availableAmount),
        Validators.minLength(1),
        Validators.required,
        Validators.pattern(/^\d+(\.\d{1,2})?$/)
      ]);
    }
  }

  onFocusOut() {
    if (Number(this.percentageOfTopUp.toFixed(2)) > 0) {
      this.allocationForm.get('amountInPercentage').setValue(Number(this.percentageOfTopUp.toFixed(2)));
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Check if the pressed key is a number
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      // Prevent the default action if it's not a number
      event.preventDefault();
      return false;
    }
    return true;
  }

}
