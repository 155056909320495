import { Component, Inject,Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { OPERATORS } from 'src/app/services/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sensorpreview',
  templateUrl: './sensorpreview.component.html',
  styleUrls: ['./sensorpreview.component.scss']
})
export class SensorpreviewComponent implements OnInit {
  @Input() target: any = { geoJSON: '', area: 0 };
  sensorName: any;
  sensorImage: boolean = true;
  video: boolean = false;
  videoLink: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SensorpreviewComponent>,
    private apiService: ApiService
  ) {
    if (this.data?.operatorKey === OPERATORS.SPIRE) {
      this.sensorName = 'Spire_1';
    } else if (this.data?.operatorKey === OPERATORS.CLYDE) {
      this.sensorName = 'Clyde_1';
    } else this.sensorName = this.data?.imageName;

    if (this.data?.sensorType === 'Video') {
      this.video = true;
      this.videoLink = environment.eeAssetsUrl + this.data?.imageName;
    } else this.video = false;
  }

  ngOnInit(): void {
   
  }
 
  onKeyPress(e: any) {
    if (e.key === 'Enter') {
      this.onNoClick();
    }
  }

  onNoClick(): void {
    this.dialogRef.close('Done');
  }
  
  public handleImageError(): void {
    this.sensorImage = false;
  }

  isMp4(link: string): boolean {
    return link.endsWith('.mp4');
  }

  isGif(link: string): boolean {
    return link.endsWith('.gif');
  }
}
