import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-satellogic-warning',
  templateUrl: './satellogic-warning.component.html',
  styleUrls: ['./satellogic-warning.component.scss']
})
export class SatellogicWarningComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SatellogicWarningComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { satellogicProcess: boolean, name: string }) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
