import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-no-cart',
  templateUrl: './no-cart.component.html',
  styleUrls: ['./no-cart.component.scss']
})
export class NoCartComponent implements OnInit {
  darkTheme: boolean;
  isInIframe: boolean;

  constructor(
    private localStorageService: DataService,
    private themeService: ThemeService
  ) {
    this.isInIframe = this.themeService.isInIframe();

    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    });
  }

  ngOnInit(): void {
  }

}
