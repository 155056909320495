import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CONFIG_VALUES } from 'src/app/services/constants';

@Pipe({
  name: 'customNumberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string | null {
    return this.decimalPipe.transform(value, CONFIG_VALUES.numberFormat);
  }
}
