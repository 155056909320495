<div class="container">
    <div class="card">
      <div class="task-head">
        <h4>Warning!</h4>
      </div>
      <p *ngIf="data?.message === 'toi'">
        Time of your interest (TOI) has expired, and the satellite can no longer be tasked. Please re-task.
      </p>

      <p *ngIf="data?.message === 'umbra'">
        Time of your interest (TOI) has already started, and the operator will reject this order.
      </p>

      <p *ngIf="data?.message === 'feasibility'">
        Please choose capture opportunities from the feasibility list.
      </p>

      <p *ngIf="data?.message === 'cartFeasible'">
        This is an opportunity based task, please proceed from dashboard.
      </p>

      <p *ngIf="data?.message === 'head'">
        The opportunities identified for tasking have expired. Kindly re-task.
      </p>
    </div>
  
    <div class="btns">
      <button mat-flat-button (click)="onNoClick()">Ok, Thanks</button>
    </div>
  </div>