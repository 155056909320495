import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-toi-warning-popup',
  templateUrl: './toi-warning-popup.component.html',
  styleUrls: ['./toi-warning-popup.component.scss']
})
export class ToiWarningPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ToiWarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: any}) { }

    ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
