import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscribeGuard implements CanActivate {
  userDetails: any;
  currentUser: any;
  isAllowed: string | null;
  demoView: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const data = await this.authService.getCurrentUser();
    this.currentUser = await data;
    if (this.currentUser?.verified) {
      try {
        const res = await this.userService
          .personalInfo(this.currentUser?.id)
          .toPromise();
        this.userDetails = res;
        if(environment.demoView === 'true') {
          this.demoView = true;
        }
        else {
          this.demoView = false;
        }
        const isSubscribe = this.userDetails?.subscribed || this.demoView;
        if (!isSubscribe) {
          localStorage.removeItem('routeChange');
        }
        this.isAllowed = localStorage.getItem('routeChange')


        if (isSubscribe && !this.isAllowed) {
          return true; // Allow access to the route
        } else if (this.isAllowed) {
          return false; 
        } else {
          this.router.navigate(['/subscription']);
          return false; // Deny access to the route
        }
      } catch (error) {
        return false;
      }
    }
    return true;
  }
}
