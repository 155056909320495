import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OPERATORS } from 'src/app/services/constants';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-agree',
  templateUrl: './agree.component.html',
  styleUrls: ['./agree.component.scss']
})
export class AgreeComponent {

  title = '';
  targetArea: any;
  currentUser: any;
  operator: any;
  order: any;
  OPERATORS: any;
  eulaPath: boolean;
  userType: string;

  constructor(
    public dialogRef: MatDialogRef<AgreeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilsService: UtilsService
  ) {
    this.OPERATORS = OPERATORS;
    this.operator = data['operator'];
    this.currentUser = data['user'];
    if (this.operator?.operatorKey === OPERATORS.BLACKSKY) {
    let purpose = this.operator?.purpose?.name ? this.operator?.purpose.name : this.operator?.purpose
     if (purpose === 'Defence & Intelligence' || purpose === 'Maritime Security') {
      this.eulaPath = true;
     } else {
      this.eulaPath = false;
     }
    } else if (this.operator?.operatorKey === OPERATORS.AXELSPACE) {
      if (this.currentUser?.organisationType?.key === 'academia') {
       this.userType = 'academia';
      } else if (this.currentUser?.organisationType?.key === 'media') {
        this.userType = 'media';
      } else this.userType = 'others';
     }
  }


  onKeyPress(e: any, option: number) {
    let result: any = undefined;
    if (option == 1) result = ' Ok';
    this.dialogRef.close(result);
  }

  goToEULA(url: string) {
    window.open(url, "_blank");
  }

  savePdf(operatorKey: any) {
    let pdfUrl: any;
    if (operatorKey === OPERATORS.USL) {
      let uslEulaContent = this.utilsService.getContent();
      window.open(uslEulaContent, '_blank');      
    } else if (operatorKey === OPERATORS.BLACKSKY) {
      if (this.eulaPath) pdfUrl = this.utilsService.getPdfUrl(operatorKey);
      else pdfUrl = this.utilsService.getPdfUrl('BlackSky-Commercial');
      window.open(pdfUrl, '_blank');
    } else if (operatorKey === OPERATORS.AXELSPACE) {
      if (this.userType === 'academia') pdfUrl = this.utilsService.getPdfUrl('AxelSpace-Academic');
      else if (this.userType === 'media') pdfUrl = this.utilsService.getPdfUrl('AxelSpace-Media');
      else pdfUrl = this.utilsService.getPdfUrl('AxelSpace-Common');
      window.open(pdfUrl, '_blank');
    } else {
      pdfUrl = this.utilsService.getPdfUrl(operatorKey);
      window.open(pdfUrl, '_blank');
    }
  }
  
}
