<div appCesium></div>
<div
        style="
          position: absolute;
          top: 40px;
          right: 5px;
          color: white;
          z-index: 10;
        "
      >
      <p>Search by Lng, Lat or Location</p>
     </div>
      <div style="position: absolute; z-index: 1; top: 3px">
        <div>
          <button
            class="cesium-button"
         
            title="Place POI as Point"
          >
            <img width="15px"  src="../../assets/images/marker-icon.png" />
          </button>
        </div>
        <div>
          <button
            class="cesium-button"
           
            title="Draw AOI as rectangle"
          >
            <img  src="../../assets/images/rectangle.svg" />
          </button>
        </div>
        <div>
          <button
            class="cesium-button"
            
            title="Draw AOI as plogon"
          >
            <img  src="../../assets/images/polygon.svg" />
          </button>
        </div>
        <div>
          <button
            class="cesium-button"
           
            title="Draw AOI as circle"
          >
            <img  src="../../assets/images/circle.svg" />
          </button>
        </div>
        <div>
          <button
            id="fileInput"
            class="cesium-button"
           
            title="Upload kml/json file for AOI"
            disabled
          >
            <img  src="../../assets/images/upload-file.svg" />
          </button>
        </div>
      </div>
<div style="position: absolute; z-index: 1; bottom: 37px; right: 0px">
    <div>
      <button class="cesium-button">
        <img
          width="29px"
          src="../../assets/images/view-zoom-in.png"
        />
      </button>
    </div>
    <div>
      <button class="cesium-button">
        <img
          width="29px"
          src="../../assets/images/view-zoom-out.png"
        />
      </button>
    </div>
  </div>