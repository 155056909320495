import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-required-popup',
  templateUrl: './required-popup.component.html',
  styleUrls: ['./required-popup.component.scss']
})
export class RequiredPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RequiredPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: any}) { }

  ngOnInit(): void {
  }
  
  onNoClick(click): void {
    this.dialogRef.close(click);
  }

}
