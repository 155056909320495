import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  isInIframe: boolean;

  constructor(
    private themeService: ThemeService
  ) {
    this.isInIframe = this.themeService.isInIframe();
   }

  ngOnInit(): void {
  }

}
