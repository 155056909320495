import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ValidationService } from 'src/app/services/validation.service';
import { SuccessComponent } from './success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ThemeService } from 'src/app/services/theme.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  confirmshowPassword: boolean = true;
  showPassword: boolean = true;
  token: any;
  pswrdMatched = true;
  success = false;
  hide = true;
  pswrdObj: any = [];
  pswrdForm: FormGroup | any;
  validationMessages: any;
  msg = '';
  clicked: boolean = false;
  isInIframe: boolean;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserService,
    private matdialog: MatDialog,
    private vs: ValidationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private themeService: ThemeService
  ) {
    this.isInIframe = this.themeService.isInIframe();
   }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.pswrdForm = this.fb.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(25),
          Validators.pattern('^(?=.*[A-Z])(?=.*[!@#$%^&_+=]).*$'),
        ]),
      ],
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9#%@&*._$!()]+$')])],
      confirmpassword: ['', Validators.required],
    });
    this.validationMessages = this.vs.account_validation_messages;
  }
  areEqual(event: any) {
    this.pswrdMatched = false;
    if (this.pswrdForm.value.password === event.target.value) {
      this.pswrdMatched = true;
    } else {
      this.pswrdMatched = false;
    }
  }
  submitForm(event: any) {
    if (event.keyCode === 13) {
      this.resetPswrd();
    }
  }
  resetPswrd() {
    if (this.pswrdForm.valid) {

      this.clicked = true;
      const pswrd = this.pswrdForm.value.password.trim();
      const re_pswrd = this.pswrdForm.value.confirmpassword.trim();
      if (pswrd != re_pswrd) {
        this.pswrdMatched = false;
        return;
      }

      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        const reCAPTCHAToken = token;
        this.pswrdObj = { password: pswrd, reCAPTCHAToken: reCAPTCHAToken };
        if (this.token) {
          this.userService.resetPswrd(this.token, this.pswrdObj).subscribe(
            (res: any) => {
              if (res.Msg != 'Failed captcha verification') {
                this.msg = 'Your password is reset successfully';
                this.success = true;
                this.OpenPopup();
              }
            },
            (err) => {
              this.msg = err.error;
              this.success = false;
            }
          );
        }
      });

    }
  }
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  public toggleconfirmPasswordVisibility(): void {
    this.confirmshowPassword = !this.confirmshowPassword;
  }

  OpenPopup() {
    this.matdialog.open(SuccessComponent, { width: '50%' });
    backdropClass: 'backdropBackground' // This is the "wanted" line
  }
  get sign() {
    return this.pswrdForm.controls;

  }
}
