import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-cancelinvoice',
  templateUrl: './cancelinvoice.component.html',
  styleUrls: ['./cancelinvoice.component.scss']
})
export class CancelinvoiceComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CancelinvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { taskname: any }) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
