import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

export interface ChangeLog {
  id: number;
  title: string;
  description: string;
  what: string;
  why: string;
  how: string;
  date: string;
  expanded: boolean;
  category: string;
  imageLinks: Array<{ link: string, route: any}>;
  order: number;
}
@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('300ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class WhatsNewComponent implements OnInit {
  features: ChangeLog[];
  activeDate: string = '';
  @ViewChild('timelineContainer') timelineContainer: ElementRef;
  activeIndex = 0;
  categories = [
    {
      featureKey : 'all',
      featureName: 'All Features'
    },
    {
      featureKey : 'platform',
      featureName: 'Platform Features'
    },    {
      featureKey : 'sensors',
      featureName: 'Sensors'
    }
  ];
  selectedIndex = 0;
  filteredFeatures: ChangeLog[];
  darkTheme: boolean;

  constructor(
    private localStorageService: DataService
  ) {
    this.getFeatures();
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    });
  }

  getFeatures() {
    this.features = [
      {
        id: 1,
        title: 'New Tasking Strategy: Help Select Sensors',
        description: `Guiding users to a select set of sensors based on their use case.`,
        what:'A tasking workflow to narrow down the selection of sensors for a given use case.',
        why:'The platform has several sensors, which can overwhelm some users.',
        how:'The platform shows a curated set of pre-defined use cases as users provide their purposes. When a use case is selected, the recommended sensors are down-selected.',
        date: '2024-10-17',
        imageLinks: [
          {link: '../../../assets/feature-images/help-select.png', route: '/newtask'}
        ],
        expanded: false,
        category: 'platform',
        order: 1
      },
      {
        id: 2,
        title: 'Value Added Data Products: Elevation products, Super Resolution and Indices',
        description: 'When tasking, multiple value-added data products (VADPs) can now be ordered.',
        what:'When tasking, users can order multiple value-added data products such as elevation models, super-resolution and indices.',
        why:'Value-added data products enhance the use of Earth observation data. Today, users experience significant friction and time lag for these products, produced internally or through their third-party service provider.',
        how: `Users can add one or more VADPs from the cost section of the new task screen to their tasking workflow. When added, the cost of the products is calculated and made available on the dashboard and further in the payment
         screen with their detailed breakdown, along with the sensor cost. The cost calculation varies based on either sqkm or task. For example, an elevation product is based on sqkm, whereas indices are charged for each delivery.
         Based on their service level agreements, the VADPs are delivered soon after the data is delivered.
         
         It is important to note that users must actively release the data for VADPs after they are happy with the data delivered. If they no longer want the VADPs, they could ask for a refund of the price paid for the VADP. 
         The products can also be returned for credit if they do not meet the requisite quality.`,
        date: '2024-10-07',
        imageLinks: [
          {link: '../../../assets/feature-images/vadp.png', route: '/newtask'},
        ],
        expanded: false,
        category: 'platform',
        order: 2
      },
      {
        id: 3,
        title: 'Tasking over a point of interest on the data delivered',
        description: 'Task from the collection screen.',
        what: `Users can now task from the map in the collection screen, where the data and the feeds delivered necessitate further investigation.`,
        why:`The data and/or feeds delivered, say vessels detected, may have pointed to a specific vessel of interest. Tasking over the vessel now with another sensor, such as a high-resolution optical or SAR, would give the user further information about the vessel, depending on the weather and time.`,
        how: `Only point tasking is enabled so that users can right-click on a coordinate of interest on the tasked- data delivered. They then provide a minimum set of requirements, and the platform will assign tasks instantly based on availability. The ordered task cannot be canceled because it is submitted for immediate tasking.`,
        date: '2024-09-06',
        imageLinks: [
          {link: '../../../assets/feature-images/collection-task.png', route: '/collections'},
        ],
        expanded: false,
        category: 'platform',
        order: 3
      },
      {
        id: 4,
        title: 'Annotation',
        description: 'Make notes on the collection page map.',
        what: `Users can annotate the data delivered on the map with text.`,
        why:`Making notes on objects or features of interest in the tasked data, including imaging and non-imaging data, assists in capturing the user's interpretation of the data for further analysis and collaboration.`,
        how: `Users can right-click on any point in the data and annotate within the pop-up dialogue. When saving the notes made, an icon is placed on the coordinate. Left-clicking the icon brings back the notes for editing or deleting.
         Once annotated, the split screen also shows the notes, comparing an area or point of interest with different sensors or periods.`,
        date: '2024-07-15',
        imageLinks: [
          {link: '../../../assets/feature-images/annotation.png', route: '/collections'},        
        ],
        expanded: false,
        category: 'platform',
        order: 4
      },
      // {
      //   id: 6,
      //   title: 'GokTurk',
      //   description: 'Added a new dashboard feature.',
      //   longDescription: `GokTurk sensor selection.Automated tip-and-cue without sensor selection.Automated tip-and-cue without sensor selection
      //   fggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg
      //   dfggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg`,
      //   date: '2024-07-22',
      //   imageLinks: [
      //     {link: '../../../assets/feature-images/gokturk.png', route: '/newtask'}         
      //   ],
      //   expanded: false,
      //   category: 'sensors'
      // }
    ];

    this.filteredFeatures = [...this.features];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // Listen for scroll events on the timeline container
    this.timelineContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(): void {
    const container = this.timelineContainer.nativeElement;
    const scrollPosition = container.scrollTop;
    const timelineItems = container.querySelectorAll('.update-item');

    timelineItems.forEach((item: any, index: number) => {
      const itemOffsetTop = item.offsetTop;
      const itemOffsetBottom = itemOffsetTop + item.clientHeight;

      // Adjust the scrollPosition based on container's height
      if (scrollPosition + container.clientHeight / 2 > itemOffsetTop && scrollPosition < itemOffsetBottom) {
        this.activeIndex = index;
      }
    });
  }

  // Function to toggle the expand/collapse state for the specific feature
  toggleExpand(index: number) {
    this.filteredFeatures[index].expanded = !this.filteredFeatures[index].expanded;
  }

  selectCategory(index: number,category: any) {
    this.selectedIndex = index;
    // Filter features based on the selected category
    if (category.featureKey === 'all') {
      this.filteredFeatures = [...this.features]; // Show all features
    } else {
      this.filteredFeatures = this.features.filter(feature => feature.category === category.featureKey);
    }
  }

}
