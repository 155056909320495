import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'; 
import { ConfirmedValidator } from '../userregister/ConfirmedValidator'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      phonenumber: ['', Validators.required,],
      email: ['', Validators.required, Validators.email],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    }, { 
      validator: ConfirmedValidator('password', 'confirm_password')
      
    
  });
  
  }

   // convenience getter for easy access to form fields
 get f() { return this.registerForm.controls; }

 onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }

 //True if all the fields are filled
if(this.submitted)
{
this.router.navigate(['/login']);
}
}

}
