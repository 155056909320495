
  <!-- Bank tarnsfe details -->
  <div>
    <div class="container" style="text-align: center">
      <h4>Required!</h4>
      <hr />

      <div class="card">
      <form action="" [formGroup]="quotationForm">
        <p>What is the deposit amount do you need the quotation for?</p>
        <div class="align-field">
          <input type="number" placeholder="Enter amount" formControlName="quotationAmount"
            (keyup)="somethingChanged($event)" />
          <span *ngIf="error"
          style="color: red; text-align: left; font-size: 13px;font-weight: 600;margin-top: -13px">
            Invalid amount format. Only two decimal points allowed
        </span>
        <span *ngIf="invalid"
        style="color: red; text-align: left; font-size: 13px;font-weight: 600;margin-top: -13px">
        Minimum quotation amount should be ${{minAmount}}
      </span>
        </div>
      </form>
  
      <div class="align-item">
        <p>A quotation for the entered amount comprising of the minimum deposit for <br>
          subscription and top-up will be posted on the Quotes page.</p>
        <div fxLayoutAlign="center center">
          <table>
            <tr>
              <td class="bold">A/C Holder</td>
              <td>{{bankDetails.accountHolder}}</td>
            </tr>
            <tr>
              <td class="bold">A/C No</td>
              <td>{{bankDetails.accountNumber}}</td>
            </tr>
            <tr>
              <td class="bold">SWIFT Code</td>
              <td>{{bankDetails.SWIFTcode}}</td>
            </tr>
            <tr>
              <td class="bold">Bank Name</td>
              <td>{{bankDetails.bankName}}</td>
            </tr>
            <tr>
              <td class="bold">Bank Address</td>
              <td>{{bankDetails.bankAddress}}</td>
            </tr>
          </table>
        </div>
        <p>The quotation is valid for a period of 30 days from the date on the quote.<br>
          <ng-container *ngIf="data">Quotation will be emailed to <b style="color: #1059a9">{{ data.user
          }}.</b></ng-container> 
        </p>
      </div>
    </div>
    </div>
  
    <div class="buttons" fxLayoutAlign="center center" fxLayoutGap="15px"
    style="padding-bottom: 15px;">
      <button class="custom-button" (click)="onNoClick(0)">
        No, Thanks
      </button>
      <button mat-flat-button (click)="onNoClick(1)" [disabled]="error || invalid || amount < minAmount">Proceed</button>
    </div>
  </div>