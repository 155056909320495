<div class="container">
   <div class="card">
      <div class="task-head">
         <h4>Success!</h4>
      </div>

      <p>Thank you for your payment. We will immediately process your order.<br>
         You can check your receipt on the Orders Page.
      </p>
      <!-- <p>NB: To see the updated balance, please logout and login again.</p> -->

      <div class="btns">
         <button mat-flat-button routerLink="/orders" (click)="onNoClick()" [ngClass]="!darkTheme ? 'buttons' : 'dark-button'"
            style="background-color: tranparent!important;">Go to Orders</button>
         <button mat-flat-button (click)="closeNow()">Pay Another Task</button>
      </div>
   </div>
</div>