<div class="upload">
  <label>Upload Profile Picture</label>
  <div class="profilepicture-update">
    <img src="../../../assets/images/upload.png" alt="" style="width: 15%" />
    <h5>Select a file or drag and drop here</h5>
    <span>JPG and JPEG file size no more than 20KB</span>
    <div class="btns">
      <label class="btn cancel" style="
          background-color: transparent;
          color: #000;
          border: 1px solid #d0d5dd;
        " (click)="onNoClick()">Cancel</label>
      <label for="imageUpload" class="btn">Select File</label>
      <input type="file" id="imageUpload" accept="image/*" formControlName="photo" style="display: none"
        (change)="onFileSelected($event, 'user')" />
    </div>
    <span *ngIf="oversize" style="color: red; text-align: left">File size must be less than 20 Kb</span>
    <span *ngIf="imageType" style="color: red; text-align: left">
      Selected file is not an allowed image type
    </span>
  </div>
</div>