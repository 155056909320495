<div class="container" *ngIf="data?.type === 'advance'">
  <div class="card pt-0 pr-0">
    <div class="main-head">
      Advanced Settings 
    </div>
    <form [formGroup]="formGroup" fxLayoutAlign="center center" class="form-vertical"  *ngIf="this.data.selectedRow?.key === OPERATOR.CAPELLA">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Orbital Plane </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
                placeholder=" Select orbital plane " class="selectDetails" formControlName="orbitalPlane">
                <mat-option *ngFor="let option of orbitalPlaneOptions"
                  [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Orbit State </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select orbit state"
                class="selectDetails" formControlName="orbitalState">
                <mat-option *ngFor="let option of orbitalStateOptions"
                  [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Observation Direction </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
                placeholder=" Select observation direction " class="selectDetails"
                formControlName="observationDirection">
                <mat-option *ngFor="let option of observationDirections"
                  [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Polarization </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select polarization"
                class="selectDetails" formControlName="polarization">
                <mat-option *ngFor="let option of polarizations" [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign = "start center">
          <div>
            <mat-label class="card-label">Look Angle</mat-label>
            <div fxLayout="row" fxLayoutGap="11px">
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="lookAngleStart" placeholder="5&deg;" class="matOption" type="number" #inputField/>
              </mat-form-field>
              <span class="max-min">-</span>
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="lookAngleEnd" placeholder="50&deg;" class="matOption" type="number"
                [min]="firstInput + 1" #inputField/>
              </mat-form-field>
            </div>
            <div class="error-container">
              <span class="error-message" *ngIf="formGroup.get('lookAngleStart').hasError('min') || formGroup.get('lookAngleStart').hasError('max') || formGroup.get('lookAngleEnd').hasError('max') || this.maxValue">
                Min and max values for Look Angle is between 5 to 50
              </span>
              <span class="error-message" *ngIf="error && (!formGroup.get('lookAngleStart').hasError('min') && !formGroup.get('lookAngleStart').hasError('max') && !formGroup.get('lookAngleEnd').hasError('max') && !this.maxValue)">
                Min value should not be greater than max value
              </span>
              <span class="error-message" *ngIf="this.wholeNumber && (!formGroup.get('lookAngleStart').hasError('min') && !formGroup.get('lookAngleStart').hasError('max') && !formGroup.get('lookAngleEnd').hasError('max') && !this.maxValue) && !error">
                Look Angle should be a whole number
              </span>
            </div>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button [disabled]="formGroup.invalid || maxValue"
           (click)="submit()" class="submit">Save</button>
        </div>
      </div>
    </form>

    <form [formGroup]="advanceForm" fxLayoutAlign="center center" class="form-vertical" *ngIf="this.data.selectedRow?.key === OPERATOR.KOMPSAT">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Polarization </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
                placeholder=" Select polarization " class="selectDetails" formControlName="sarPolarization">
                <mat-option *ngFor="let option of data.selectedRow?.sarPolarization"
                  [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Orbit Direction </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select orbit direction"
                class="selectDetails" formControlName="sarOrbitDirection">
                <mat-option *ngFor="let option of data.selectedRow?.sarOrbitDirection"
                  [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px">
          <div>
            <mat-label class="card-label">Product Ancillary </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpanel" placeholder="Select product ancillary"
                class="selectDetails" formControlName="productAnciallary">
                <mat-option *ngFor="let option of data.selectedRow?.productAnciallary" [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button
           (click)="save()" class="submit">Save</button>
        </div>
      </div>
    </form>

    <form [formGroup]="umbraAdvanceForm" fxLayoutAlign="center center" class="form-vertical" *ngIf="this.data.selectedRow?.key === OPERATOR.UMBRA">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Polarization</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpanel" placeholder="Select polarization"
                class="selectDetails" formControlName="sarPolarization">
                <mat-option *ngFor="let option of data.selectedRow?.sarPolarization" [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Target Azimuth Angle</mat-label>
            <div fxLayout="row" fxLayoutGap="11px">
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="targetAzimuthAngleMin" placeholder="0&deg;" class="matOption" type="number" #inputField/>
              </mat-form-field>
              <span class="max-min">-</span>
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="targetAzimuthAngleMax" placeholder="360&deg;" class="matOption" type="number"
                [min]="firstInput + 1" #inputField/>
              </mat-form-field>
            </div>
            <div class="error-container">
              <span class="error-message" *ngIf="umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('min') || umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('max') || umbraAdvanceForm.get('targetAzimuthAngleMax').hasError('max') || this.maxValue">
                Min and max values for Azimuth Angle is between 0 to 360
              </span>
              <span class="error-message" *ngIf="error && (!umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('min') && !umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('max') && !umbraAdvanceForm.get('targetAzimuthAngleMax').hasError('max') && !this.maxValue)">
                Min value should not be greater than max value
              </span>
              <span class="error-message" *ngIf="this.wholeNumber && (!umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('min') && !umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('max') && !umbraAdvanceForm.get('targetAzimuthAngleMax').hasError('max') && !this.maxValue) && !error">
                Target Azimuth Angle should be a whole number
              </span>
            </div>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button [disabled]="umbraAdvanceForm.invalid || maxValue"
           (click)="saveChanges()" class="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="data?.type === 'allocation'">
  <div class="card pt-0 pr-0">
    <div class="main-head">
      Allocation Settings
    </div>

    <form [formGroup]="allocationForm" fxLayoutAlign="center center" class="allocation-form-vertical">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="">
          <div>
            <mat-label class="balance-label">Balance Amount: <span>{{availableBalance}}</span></mat-label>
            <!-- <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel"
                placeholder=" Select sensor type " class="selectDetails" formControlName="sensorType"
                (selectionChange)="sensorChange($event.value)">
                <mat-option *ngFor="let option of SensorsTypes"
                  [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center" style="margin-top:30px">
          <div>
            <mat-label class="card-label">Sensor Type </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel"
                placeholder=" Select sensor type " class="selectDetails" formControlName="sensorType"
                (selectionChange)="sensorChange($event.value, false)">
                <mat-option *ngFor="let option of SensorsTypes"
                  [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Sensor Name </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel" [placeholder]="selectedSensorType ? selectedSensorType : 'Select sensor name'"
                class="selectDetails" formControlName="sensorName" (selectionChange)="selectSensor($event.value, false)">
                <mat-select-trigger>{{selectedSensorType}}</mat-select-trigger>
                <mat-option *ngFor="let option of sensorList"
                  [value]="option">
                  <div class="align">
                    <span>{{ option.name }}{{option.sensor === "Tristereo" || option.sensor === "Stereo" ? ' - ' + option.sensor : ''}}</span>
                    <span *ngIf="option.resolution"> ({{
                      option.resolution
                      }}) </span>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Tasking Tiers</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel"
                placeholder="Select tasking tiers" class="selectDetails" formControlName="taskingTier"
                (selectionChange)="taskingTierChange($event.value, false)">
                <mat-option *ngFor="let option of taskingTiers"
                  [value]="option">{{ option.name.charAt(0).toUpperCase() + option.name.slice(1) }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Unit Cost</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <input matInput formControlName="unitCost" placeholder="Unit cost" class="matOption" type="name"  readonly/> 
            </mat-form-field>  
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Amount (%)</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <input matInput formControlName="amountInPercentage" placeholder="1% - 100%" class="matOption" type="name" 
              (keyup)="valChng($event)" (keypress)="numberOnly($event)"  (focusout)="onFocusOut()" />
              <mat-error *ngIf="fields.amountInPercentage.touched && fields.amountInPercentage.errors"
                style="color: #EF3061;font-size: 12px; font-weight: 600;margin-left: -10px;">
                <mat-error *ngIf="fields.amountInPercentage.errors.pattern"> Please enter a valid number</mat-error>
                <mat-error *ngIf="fields.amountInPercentage.errors.required"> Please enter a valid number</mat-error>
                <mat-error *ngIf="allocationForm.get('amountInPercentage').hasError('min')"> Minimum amount allocation should be 1%</mat-error>
                <mat-error *ngIf="allocationForm.get('amountInPercentage').hasError('max')"> Maximum amount allocation should be
                  {{this.availableAmount.toFixed(2) }}%</mat-error>
                <mat-error *ngIf="allocationForm.get('amountInPercentage').hasError('exceededAmount')">
                  No sufficient amount to allocate this sensor
                </mat-error>
              </mat-error>
            </mat-form-field>          
          </div>

          <div>
            <mat-label class="card-label">Number of Tasks</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <input matInput formControlName="noOfTasks" placeholder="Number of tasks" class="matOption" type="name" #inputField readonly />
              <mat-error *ngIf="fields.noOfTasks.touched && fields.noOfTasks.errors" style="color: #EF3061;font-size: 12px; font-weight: 600;margin-left: -10px;">
                <mat-error *ngIf="allocationForm.get('noOfTasks').hasError('min')">
                  Number of tasks must be greater than or equal to 1
                </mat-error>
            </mat-error>
            </mat-form-field>
           
          </div>
        </div>
       
        <div class="buttons" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button
           (click)="saveData()" class="submit" [disabled]="allocationForm.invalid || allocationError || saveClick">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>