import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-allocation-notification',
  templateUrl: './allocation-notification.component.html',
  styleUrls: ['./allocation-notification.component.scss']
})
export class AllocationNotificationComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AllocationNotificationComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { message: any}) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
