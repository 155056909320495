<!DOCTYPE html>
<html lang="en-US" id="uslEulaContent">
  <head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta name="description" content="New Account Email Template." />
    <style type="text/css">
      @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
      body {
        font-family: 'Times New Roman', Times, serif;
      }
      a:hover {
        text-decoration: underline !important;
      }
      table {
        font-family: 'Times New Roman', Times, serif;
        border-collapse: collapse;
        width: 100%;
        border-spacing: 30px;
      }
      .no-border {
        border: none;
      }

      td,
      th {
        border: 1px solid rgb(78, 78, 78);
        text-align: left;
        padding: 8px;
        font-size: 14px;
        vertical-align: top;
      }
      td table,
      th table {
        border: none;
      }
      th {
        background-color: #ffffff;
        color: #7e7e7e;
      }
      form > h2 {
        color: #0094ff;
      }
      form > p:first-child {
        font-size: large;
      }
      .createPDF {
        font-size: 14px;
      }
      .td-box {
        display: flex;
        justify-content: space-between;
      }
      .tbd-box {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: space-between;
      }
      p {
        margin: 0;
      }
      .header p {
        line-height: 22px;
      }
      .header label {
        color: red;
        font-weight: 600;
      }
      table.report-container {
        page-break-after: always;
      }
      thead.report-header {
        display: table-header-group;
        min-width: 100%;
      }
      tfoot.report-footer {
        display: table-footer-group;
      }
      .padding {
        padding-top: 10px;
        padding-bottom: 20px;
      }
      .underline {
        text-decoration: underline;
      }
    </style>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/fontawesome.min.css"
      integrity="sha512-xX2rYBFJSj86W54Fyv1de80DWBq7zYLn2z0I9bIhQG+rxIF6XVJUpdGnsNHWRa6AvP89vtFupEPDP8eZAtu9qA=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    />
  </head>

  <body
    marginheight="0"
    topmargin="0"
    marginwidth="0"
    style="margin: 20px; background-color: rgb(50, 54, 57) !important; padding: 12px;"
    leftmargin="0"
    page-break="always"
  >
    <table
      cellspacing="0"
      border="0"
      cellpadding="0"
      width="100%"
      style="
        background-color: #fff;
        font-family: 'Times New Roman', Times, serif;
        width: 100%;
        max-width: 900px;
        margin: auto;
      "
    >
      <thead class="report-header">
        <tr>
          <td style="border: 1px">
            <table>
              <tr>
                <td style="text-align: left;">
                  <div class="header">
                    <img src="../../../assets/images/L1.jpg" alt="">
                  </div>
                </td>
                <td style="text-align: center;">
                  <h3 style="margin: 0">END USE CERTIFICATE <br> NON-RE-TRANSFERT, NON-EXPORT or NON-RE-EXPORT <br> COMMITMENT </h3>
                </td>
                <td style="text-align: right;">
                  <div class="address">
                    <img src="../../../assets/images/L2.jpg" alt="">
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </thead>

      <tbody class="report-content">
        <tr>
          <td class="report-content-cell" style="border: none; padding: 10px 68px;">
            <div class="main">
              <table
                cellspacing="0"
                border="0"
                cellpadding="0"
                width="100%"
                style="
                  background-color: #fff;
                  font-family: 'Times New Roman', Times, serif;
                  max-width: 900px;
                  margin: auto;
                "
              >
              <p style="text-align: justify;">1. Name of  French licensee: UNSEENLABS </p>
              <p style="text-align: justify;"class="padding">2. Name, address of end user: </p>
              <div style="text-align: justify; padding-left: 16px; line-height: 1.5;padding-bottom: 8px;margin-top: -7px;">{{userDetails?.name }} {{ userDetails?.lastName}}
                <br>{{userDetails?.company}}<br>{{userDetails?.address1}}<br>{{userDetails?.address2}}
                <br>{{userDetails?.country}} </div>
              <p style="text-align: justify;padding-bottom: 10px;padding-top: 3px;"class="padding">3. End user's state: {{userDetails?.country}} </p>
              <p style="text-align: justify;">4.	License's reference:  </p>
              <p style="text-align: justify;padding-bottom: 12px;"class="padding">5.	If applicable, reference and date of contract or order: {{currentDate | date: "dd/MM/yyyy"}}</p>
              <p style="text-align: justify;">6.	Products:</p>
                <tr>
                  <td style="padding-top: 0px; border: none;">
                    <table class="font-style"
                      style="
                        padding: 40px;
                        border-radius: 5px;
                        max-width: 100%;
                        margin: 0 auto;
                      "
                      width="100%"
                      border="0"
                      align="center"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tr>
                        <td style="border: 0; height: 10px">&nbsp;</td>
                      </tr>
                      <tr>
                        <td
                          colspan="4"
                          class="no-border"
                          style="padding: 0; border: 0"
                        >
                          <table id="demo">

                            <tr>
                              <td style="width: 30%; font-size: 15px;">Quantity</td>

                              <td colspan="2" class="font-style">
                                <p>Products designation</p>
                                <p style="font-size: 15px;">(All types of equipment, software and technical data, particularly as part of service provision - training,
                                  documentation, technical support, presentation… - whether delivered in tangible or intangible forms).</p>
                              </td>
                            </tr>
                            <tr class="font-style">
                              <td style="font-size: 15px;">2 acquisitions per day between {{ operator?.startDate | date: "dd/MM/yyyy" }} and {{ operator?.endDate | date: "dd/MM/yyyy" }}</td>

                              <td style="font-size: 15px;" colspan="2">{{operator?.resolution[0]?.value ? operator?.resolution[0].value : operator?.resolution }} {{ operator?.resolution[0]?.resolutionTooltip ? operator?.resolution[0].resolutionTooltip : operator?.resolutionTooltip}}<br>
                                UNSEENLABS_SURMARSAT Déf V10 du 07/01/2019
                               ]</td>
                            </tr>
                         </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="border: 0; height: 10px">&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
            <p class="font-style">7. Certification and engagement of first consignee and potential intermediaries </p>
            <p class="padding font-style">7 a) Certification and engagement of first consignee: </p>
            <p class="font-style">We certify that we are the first consignee of the goods defined in section 6. With the exception of
              the end-user specified in section 2, or an intermediary specified in section 7b, we will not to sell, give, lend, transmit to anyone or retransfer and re-export any of these products, without the prior written approval of the French Government.
               </p>
            <p style="padding-bottom: 3px;  padding-top: 15px;" class="padding font-style">Signature: <i class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</i></p>   
            <p style="padding-bottom: 20px;" class="font-style">Name and title of signatory	:<span class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</span>  Date: <span class="signature">{{currentDate | date: "dd/MM/yyyy"}}</span></p>
            <p class="padding font-style">7 b) Certification and engagement of intermediaries:
              We certify that we receive, as an intermediary, some or all of the products specified in section 6.
              With the exception of the end-user specified in section 2 or another intermediary specified in section
              7, we will not sell, give, lend or transmit to anyone or retransfer and re-export any of these products,
              without the prior written approval of the French Government.
              </p>  
            <p style="padding-bottom: 3px;" class="font-style">Signature: <i class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</i></p>
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;padding-bottom: 25px;padding-top: 0px;" class="padding">Name and title of signatory: <span class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</span> Date:<span class="signature"> {{currentDate | date: "dd/MM/yyyy"}}</span></p>
            <p class="font-style">8)	End use (please state the purpose - name and short description of the program,
              the operation or the weapon system - for which the products will be used):</p>
            <p style="text-align: justify; font-size: 16px; line-height: 1.5;" class="padding">8 a)	Data are delivered to be integrated in a value-added product for a demonstration for the end user;</p>
            <p style="text-align: justify;" class="font-style">	Certification and engagement of the end-user: </p>
            <p style="text-align: justify; font-size: 16px; line-height: 1.5;" class="padding">9 a) We certify that we are the end-user of the products specified
              in section 6. We will not sell, give, lend, transmit to any one or retransfer and re-export these products without the prior written approval of the French Government. This engagement covers any new import or new transfer (of specific supplies, spare parts,
              repaired parts, tools and training) related to the operational implementation of these products.</p>
            <p style="text-align: justify;" class="font-style">	We acknowledge that the prior approval of the French Government is not required: </p>
            <ul class="font-style">
              <li>In the case of a permanent or temporary return of the goods to the operator specified in section 1,
                to a facility located in France, for which we certify that, if asked, we will provide the French Government
                with all the elements proving this return;</li>
              <li class="padding">In the case of the use of the products by armed forces, identified as end-users, as part of
                national or international operations, training exercises and maneuvers, as long as they remain at all times under
                their full responsibility;</li>
              <li>When the export or the transfer of the goods from France is only temporary, which allows their restitution
                to the operator specified in section 1</li>
            </ul> 
            <p style="text-align: justify;font-size: 16px;padding-bottom: 3px; line-height: 1.5;" class="padding">Signature: <i class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</i></p>
            <p style="text-align: justify;padding-bottom: 23px;" class="font-style">Name and title of signatory:<span class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</span> Date:<span class="signature"> {{currentDate | date: "dd/MM/yyyy"}}</span></p> 
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;" class="padding">9 b) We certify that the goods specified in section 6 are destined 
              to be integrated into our own manufactured products or to be substantially transformed by us.</p>
            <p style="text-align: justify;" class="font-style">We certify that we will not sell, give, lend, transmit to anyone or retransfer
              and re-export non- integrated or transformed products without the prior written approval of the French Government.</p>
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;" class="padding">We also certify that we will not sell, give, lend, transmit to any third
               party or retransfer and re- export the products specified in section 6 that are not destined to be integrated nor
               transformed (documentation, tools, accessories, spare parts…). <br>
              These engagements cover any new import or new transfer (of specific supplies, spare parts, repaired parts, tools and
              training) related to the operational implementation of these goods.
              </p> 
            <p style="text-align: justify;" class="font-style">We acknowledge that the prior approval of the French Government is not required:</p>
            <ul  class="font-style">
              <li>In the case of a permanent or temporary return of the goods to the operator specified in section 1, on a
                facility located in France, for which we certify that, if asked, we will provide the French Government with
                all the elements proving this return;</li>
              <li class="padding">In the case of the use of the products by armed forces, identified as end-users, as part
                of national or international operations, training exercises and maneuvers, as long as they remain at all
                times under their full responsibility;</li>
              <li>	When the export or the transfer of the goods from France is only temporary, which allows their
                restitution to the operator specified in section 1.</li>
            </ul> 
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;padding-bottom: 3px;" class="padding">Signature: <i class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</i></p>
            <p style="text-align: justify;padding-bottom: 23px;" class="font-style">Name and title of signatory:<span class="signature"> {{userDetails?.name }} {{ userDetails?.lastName}} </span>Date: <span class="signature">{{currentDate | date: "dd/MM/yyyy"}}</span></p> 
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;" class="padding">10)	Certification of the government of the end-user state:</p>
            <p style="text-align: justify;" class="font-style">We certify that the end-user specified in section 2 is authorized to acquire 
              the goods specified in section 6.</p>
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;" class="padding">10 a) We certify that we will not authorize the re-transfer, 
              re-sale, lending, giving or transmission of the products specified in section 6, outside of the state’s 
              territory as specified in section 3, without the prior written approval of the French Government. 
              This engagement covers any new import or new transfer (of specific supplies, spare parts, repaired parts, 
              tools and training) related to the operational implementation of these goods.</p>
            <p style="text-align: justify;padding-bottom: 3px;" class="font-style">Signature: <i class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</i></p>
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;padding-bottom: 23px;padding-top: 0px;" class="padding">Name and title of signatory: <span class="signature">{{userDetails?.name }} {{ userDetails?.lastName}} </span>Date: <span class="signature">{{currentDate | date: "dd/MM/yyyy"}}</span></p>
            <p style="text-align: justify;font-size: 16px; line-height: 1.5;" >10 b) We certify that we take note of the commitments regarding the products in
              section 6, which are subject to the control regime of war equipment, equivalent products and defence-related
              products under French rules and regulations</p>
            <p style="text-align: justify;font-size: 16px; line-height: 1.5; padding-bottom: 0px; padding-top: 20px;"class="padding" >Signature: <i class="signature">{{userDetails?.name }} {{ userDetails?.lastName}}</i></p>
            <p style="text-align: justify;" class="font-style">Name and title of signatory:<span class="signature"> {{userDetails?.name }} {{ userDetails?.lastName}} </span>Date: <span class="signature">{{currentDate | date: "dd/MM/yyyy"}}</span></p>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>
