import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<SuccessComponent>, private router: Router,) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['login'])
  }
}
