import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentUser: any;
  userFlag: boolean = false
  date: number = new Date().getFullYear();
  darkTheme: boolean;
  deletedImagePath: string;
  isInIframe: boolean;

  constructor(private authService: AuthService,
    private localStorageService: DataService,
    private themeService: ThemeService
    ) {
      this.isInIframe = this.themeService.isInIframe();

    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    });
  }

  ngOnInit(): void {
    this.authService.getCurrentUser();
    this.getUser();
  }

  getUser() {
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;
      //(this.currentUser, "getCurrentUser")
      if (this.currentUser) {
        this.userFlag = true;
      } else {
        this.userFlag = false;
      }
      castUser.unsubscribe();
    });
  }
}
