import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { CountrycheckComponent } from '../../taskcost/countrycheck/countrycheck.component';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { ConfirmDialog } from '../../taskcost/confirmdialog';
import { dollarUS, HSI_EMBARGO_AMOUNT, OPTICAL_EMBARGO_AMOUNT, SAR_EMBARGO_AMOUNT, STEREO_EMBARGO_AMOUNT, Video_EMBARGO_AMOUNT } from 'src/app/services/constants';
import { UtilsService } from 'src/app/services/utils.service';
var geoParse = require('wellknown');

@Component({
  selector: 'CreateTaskConfirmDialog',
  templateUrl: 'confirmdialog.html',
  styleUrls: ['confirmdialog.scss'],
})
export class CreateTaskConfirmDialog {
  message = '';
  latitude = '';
  longitude = '';
  task: any;
  title = 'Create New Task';
  newTaskForm: any = FormGroup;
  outComeError: boolean = true;
  outComesensor: any;
  outComeSubmit: boolean = false;
  demoView: boolean = false;
  SensorsTypes = this.apiService.SENSORSTYPE;
  filteredSensorTypes: any[];
  place: any;
  country: any;
  poiWkt: string;
  geoJson: string;
  darkTheme: boolean;
  tooltipImagePath: string;
  openWater: boolean = false;
  agree: boolean = false;
  opticalValue: any = '';
  sarValue: any = '';
  stereoValue: any = '';
  hsiValue: any = '';
  videoValue: any = '';
  purposeOption: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<CreateTaskConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    public dialog: MatDialog,
    private http: HttpClient,
    private dataService: DataService,
    private utilsService: UtilsService
  ) {
    this.message = data.message;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.task = data.task;
    let accuracy = 15;
    this.geoJson = `POINT (${data.longitude.toFixed(accuracy)} ${data.latitude.toFixed(accuracy)})`;
    this.poiWkt = 'Lng: ' + data.longitude.toFixed(accuracy) + '  ' + 'Lat: ' + data.latitude.toFixed(accuracy);
    if (environment.demoView === 'true') {
      this.demoView = true;
    }
    else {
      this.demoView = false;
    }

    this.dataService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
    });


    this.filteredSensorTypes = this.SensorsTypes.filter(item => item.feasibility === true);

    this.newTaskForm = new FormGroup({
      taskName: new FormControl('',[Validators.minLength(5)]),
      sensorType: new FormControl(),
      resolution: new FormControl(),
      cloudCover: new FormControl(),
      target: new FormControl(),
      latency: new FormControl('', [Validators.required, Validators.min(24), Validators.pattern(/^[0-9]\d*$/)]),
    });

    this.newTaskForm.valueChanges.subscribe(() => {
      if (this.isAnyFieldEmpty()) {
        this.outComeError = true;
      } else {
        this.outComeError = false;
      }
    });

    this.newTaskForm.get('target').setValue(this.geoJson);
    this.getCountryAndPlace(this.latitude,this.longitude);
    this.setEmbargoData();
    this.apiService.getPurpose().subscribe((res: any) => {
      this.purposeOption = this.utilsService.sortPurposeOptions(res?.purposeList);
    });
  }

  isAnyFieldEmpty(): boolean {
    for (const key of Object.keys(this.newTaskForm.controls)) {
      if (key != 'cloudCover') {
        const controlValue = this.newTaskForm.get(key).value;
        if (controlValue === null || controlValue === undefined || controlValue === '') {
          return true;
        }
      }

      if (key === 'cloudCover') {
        const controlValue = this.newTaskForm.get(key).value;
        if ((controlValue === null || controlValue === undefined || controlValue === '') && this.outComesensor?.cloudCover?.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  get fields() {
    return this.newTaskForm.controls;
  }

  sensorChange(event: any) {
    this.newTaskForm.patchValue({
      resolution: '',
      cloudCover: ''
    });

    let intent = 'Internal Use';
    let target = 'POI';

    this.apiService.getSensorDetails(event.value, target, intent).subscribe((res: any) => {
      this.outComesensor = res;
      const cloudC: any = [];
      if (res.cloudCover) {
        for (var key in res.cloudCover) {
          cloudC.push({ key, value: res.cloudCover[key] });
        }
        this.outComesensor.cloudCover = cloudC;

        if (this.outComesensor?.cloudCover?.length === 0) {
          this.newTaskForm.get('cloudCover').disable();
        } else {
          this.newTaskForm.get('cloudCover').enable();
        }
      }
    });
  }

  submit() {
    let newTaskParams = {};
    newTaskParams['project'] = this.task.project;
    newTaskParams['projectid'] = this.task.projectId;
    newTaskParams['aoiCountry'] = this.country;
    newTaskParams['aoiPlace'] = this.place;
    newTaskParams['taskname'] = this.newTaskForm?.value?.taskName;
    newTaskParams['purpose'] = this.purposeOption[0];
    newTaskParams['area'] = 0;
    newTaskParams['target'] = JSON.stringify(geoParse(this.geoJson));
    newTaskParams['taskStrategy'] = 'Task From Collection';
    newTaskParams['intent'] = this.apiService.intentOption[0];
    newTaskParams['outComeCloudCover'] = this.newTaskForm?.value?.cloudCover;
    newTaskParams['outComeLatency'] = this.newTaskForm?.value?.latency;
    newTaskParams['outComeResolution'] = this.newTaskForm?.value?.resolution;
    newTaskParams['outComesensorType'] = this.newTaskForm?.value?.sensorType.name;
    newTaskParams['openWater'] = this.openWater

    if (this.agree) {
      this.apiService.outcomeTaskSubmit(newTaskParams).subscribe((res: any) => {
        if (res?.msg === 'Success') {
          let taskid;
          taskid = res?.taskid;
          this.dialogRef.close();
          taskid = res?.taskid;

          const dialogRef = this.dialog.open(ConfirmDialog, {
            backdropClass: 'blurr', disableClose: true, width: '40%',
            data: { taskid: taskid, taskname: newTaskParams['taskname'], response: res, type: 'maptask',from:'maptask' },
          });
        } else if (res?.msg === 'Failed' && res?.message) {
          const dialogRef = this.dialog.open(CountrycheckComponent, {
            width: '40%', backdropClass: 'blurred', disableClose: true,
            data: { message: "outcomeFail", response: res?.message ,url:"/task"}
          });
        } else {
          this.dialogRef.close();
          const dialogRef = this.dialog.open(CountrycheckComponent, {
            width: '40%', backdropClass: 'blurred', disableClose: true,
            data: { message: "taskFail",url:"/task" }
          });
        }
      }, (error: any) => {
        this.dialogRef.close();
        const dialogRef = this.dialog.open(CountrycheckComponent, {
          width: '40%', backdropClass: 'blurred', disableClose: true,
          data: { message: "taskFail",url:"/task" }
        });
      });
    }
    }

  onNoClick(): void {
    this.dialogRef.close();
    this.agree = false;
  }

  onClick() {
    this.agree = true;
    this.submit();
  }

  onSubmit() {
    this.outComeSubmit = true;
  }

  getCountryAndPlace(latitude, longitude) {
    const apiKey = environment.openCageApiKey;
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
    let place: any, country: any;
    let location = {
      longitude : longitude,
      latitude : latitude
    }

    this.apiService.checkIsWater(location).subscribe((res: any) => {
    this.openWater = res?.water;
    this.http.get(url).subscribe((response: any) => {
      if (response.results.length > 0) {
        const firstResult = response.results[0];
                
        if (firstResult.components?.body_of_water) {
           country = firstResult.components.body_of_water;
           place = firstResult.components.body_of_water;
        } else {
           country = firstResult.components.country;
           place = firstResult.components.city || firstResult.components.state || firstResult.components.county;
        }

        this.place = place;
        this.country = country;
      }
    });
  });
  }

  onKeyChange(key): void {
    const keyControl = this.newTaskForm.get(key);
    keyControl?.markAsTouched();
    keyControl?.updateValueAndValidity();
  }

  setEmbargoData() {
    this.opticalValue = dollarUS.format(OPTICAL_EMBARGO_AMOUNT);
    this.sarValue = dollarUS.format(SAR_EMBARGO_AMOUNT);
    this.stereoValue = dollarUS.format(STEREO_EMBARGO_AMOUNT);
    this.hsiValue = dollarUS.format(HSI_EMBARGO_AMOUNT);
    this.videoValue = dollarUS.format(Video_EMBARGO_AMOUNT);


    if (this.data?.user?.pointsEligible) {
      const parts = this.data.user.sensorConversionFactor.split(':');
      const conversionFactor =  parseFloat(parts[1]) / parseFloat(parts[0]);
      this.opticalValue = Math.round(OPTICAL_EMBARGO_AMOUNT * conversionFactor) + ' Points';
      this.sarValue = Math.round(SAR_EMBARGO_AMOUNT * conversionFactor) + ' Points';
      this.stereoValue = Math.round(STEREO_EMBARGO_AMOUNT * conversionFactor) + ' Points';
      this.hsiValue = Math.round(HSI_EMBARGO_AMOUNT * conversionFactor) + ' Points';
      this.videoValue = Math.round(Video_EMBARGO_AMOUNT * conversionFactor) + ' Points';
    }
  }
}
