<div>
    <div id="scrollcustom" class="scrollbar">
        <div *ngIf="filteredData?.length === 0" class="container no-task">
            <img *ngIf="!darkTheme && !isInIframe" src="../../assets/images/notask.svg">
            <img *ngIf="darkTheme && !isInIframe" src="../../assets/images/whitelogo.png" width="80px">
            <h3>You have no quotes</h3>
        </div>
        <mat-card *ngIf="tableDataLength > 0" class="card mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" matSort class="table-class">

                <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef>Quotation No</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">{{row?.quotationNumber}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 9px !important;">Quotation Amount</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 130px; display: block;">
                            $ {{ (row.unitPrice + row.topUpAmount)| customNumberFormat }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="quoteEndDate">
                    <th mat-header-cell *matHeaderCellDef>Valid Until (UTC)</th>
                    <td mat-cell *matCellDef="let row"> <span class="cell-width">{{row?.quoteValidityEndDate | date :
                            "dd/MM/yyyy - hh.mm a" : "GMT"}}
                        </span></td>
                </ng-container>

                <ng-container matColumnDef="copy">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                        <span class="th-width">Quotation Copy</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span style="display: flex;justify-content: center;">
                            <img [src]="invoiceImagePath" alt="" style="cursor: pointer"
                                (click)="generateReceipt(row)" />
                        </span>

                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
    </div>
</div>