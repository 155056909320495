<div class="container">
    <div class="card">
        <div class="task-head">
            <h4>Please Note</h4>
        </div>

        <p>We have accepted your cancelation of the ordered <b style="color: #1059a9"> "{{ data.taskname }}" </b> with
            applicable cancelation charges stipulated by the sensor owners. We will email you a receipt for the
            deduction if you have already paid or an invoice if you have a credit term.
        </p>
        <div class="btns">
            <button mat-flat-button color="primary" (click)="onNoClick()">Ok, Thanks</button>
        </div>
    </div>
</div>