import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ConfirmedValidator } from './ConfirmedValidator';
import { MatDialog } from '@angular/material/dialog';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { ValidationService } from 'src/app/services/validation.service';
import { RegistersuccessComponent } from './registersuccess/registersuccess.component';
import { countries } from 'country-list-json';
import * as isoCountries from 'i18n-iso-countries';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ThemeService } from 'src/app/services/theme.service';
import { ApiService } from 'src/app/services/api.service';
import { QuotationPopupComponent } from './quotation-popup/quotation-popup.component';
import { UtilsService } from 'src/app/services/utils.service';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?!gmail|yahoo|outlook|hotmail)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

interface country {
  value: any;
  viewValue: string;
}
@Component({
  selector: 'app-userregister',
  templateUrl: './userregister.component.html',
  styleUrls: ['./userregister.component.scss']
})
export class UserregisterComponent implements OnInit {
  confirmshowPassword: boolean = true
  showPassword: boolean = true;
  registerForm: any = FormGroup;
  signupform: any = FormGroup;
  submitted = false;
  notRegistered = false;
  warnMsg: any;
  imgUrl = '';
  userPhotoFile: any;
  imageType = false;
  oversize = false;
  validationMessages: any;
  uploaded: boolean = false;
  validPhone = false;
  upload: boolean = false;
  check: boolean = false;
  successMsg: boolean = false;
  dialog: any;
  countries: any;
  isInIframe: boolean;
  organisationTypes: any;
  quotation: boolean = false;
  quotationAmount: number = 0;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private matdialog: MatDialog,
    private vs: ValidationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private themeService: ThemeService,
    private apiService: ApiService,
    private utilsService: UtilsService
    ) {
      this.isInIframe = this.themeService.isInIframe();
     }


  ngOnInit(): void {
    this.countries = countries;
    this.apiService.getOrganisations().subscribe((res: any) => {
      this.organisationTypes = this.utilsService.sortPurposeOptions(res?.organisationtypes);
    });
    this.registerForm = this.formBuilder.group({
      fullName: ['',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          this.vs.userNameValidator,
        ],
      ],
      lastName: ['',
      [
        Validators.required,
        Validators.maxLength(50),
        this.vs.userNameValidator,
      ],
     ],
      displayName: ['',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
          this.vs.userNameValidator,
        ],
      ],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],

    });
    this.signupform = this.formBuilder.group({
      companyName: [
        '',
        [
          Validators.required
        ],
      ],
      organisationType: [
        '',
        [
          Validators.required
        ],
      ],
      phonenumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          // Validators.minLength(10),
          Validators.maxLength(15),
        ],
      ],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[A-Z])(?=.*[!@#$%^&_+=]).*$'),
      ],
      ],
      confirm_password: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip_code: [''],
      photo: [''],
      role: [
        '',
        [
          Validators.required
        ],
      ]
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')

    });
    this.validationMessages = this.vs.account_validation_messages;
  }


  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }

  keyPressName(event: any) {
    let value = event.target.value;
    if (value.length === 1) {
      value = value.toUpperCase();
    }
    const pattern = /[ a-z + \ A-Z\ + \ \ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 50 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }


  keyPressPhone(event: any) {
    const value = event.target.value;
    if (this.validPhone) {
      this.validPhone = false;
    }
    if (value.length === 15) {
      this.validPhone = true;
      return;
    }
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 15 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  public toggleconfirmPasswordVisibility(): void {
    this.confirmshowPassword = !this.confirmshowPassword;
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;

  }
  get sign() {
    return this.signupform.controls;

  }
  keyPress(event: any) {
    this.warnMsg = '';
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.submitted = true;
    let result: any;
    const userEmail = encodeURIComponent(this.registerForm.value.email.toLowerCase());
    const pswrd = encodeURIComponent(this.registerForm.value.password || "");

    const loginparams = { userEmail, pswrd };

    this.userService.chkRegisteredUser(loginparams).subscribe(
      (result: any) => {
        // if (result.Msg !== 'User not registered')
        if (result) {
          if (result.Msg === 'User not registered') {
            this.warnMsg = '';
            this.notRegistered = true;
            // let docs = document.getElementById('button')
            // if (docs) {
            //   docs.click();
            // }
          }
          if (result.Msg === 'Wrong password') {
            this.notRegistered = false;
            this.warnMsg = 'User already registered with this email';
            console.log(this.warnMsg);

            return
          }
        }
      },
      (err) => {
        return false;
      }
    );
  }




  submitSignup() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signupform.invalid) {
      return;
    }
    //True if all the fields are filled
    if (this.submitted) {
      let userName = this.registerForm.value.fullName;
      let lastName = this.registerForm.value.lastName;
      userName = userName.replace(/\w\S*/g, function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      lastName = lastName.replace(/\w\S*/g, function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      //(this.registerForm.value.displayName, this.signupform.value.companyName, this.registerForm.value, this.signupform.value, this.imgUrl)
      const displayName = this.registerForm.value.displayName;
      const company = this.signupform.value.companyName;
      const organisationType = this.signupform.value.organisationType;
      const email = this.registerForm.value.email.toLowerCase();
      const phone = this.signupform.value.phonenumber;
      const pswrd = this.signupform.value.password.trim();
      const re_pswrd = this.signupform.value.confirm_password.trim();
      const address1 = this.signupform.value.address1;
      const address2 = this.signupform.value.address2;
      const city = this.signupform.value.city;
      const country = this.signupform.value.country.name;
      isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      const twoLetterCode = this.signupform.value.country.code;
      const threeLetterCode = isoCountries.alpha2ToAlpha3(twoLetterCode);
      const zip_code = this.signupform.value.zip_code;
      let quotationAmount = 0;
      if (this.quotation) {
        quotationAmount = +this.quotationAmount;
      }
      const role = this.signupform.value.role;

      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        const reCAPTCHAToken = token;
        let userData = {
          name: userName,
          displayname: displayName,
          company: company,
          phone: phone,
          email: email,
          password: pswrd,
          photoFile: this.userPhotoFile,
          address1: address1,
          address2: address2,
          city: city,
          country: country,
          countryCode: threeLetterCode,
          zip_code: zip_code,
          lastName: lastName,
          organisationType: organisationType,
          reCAPTCHAToken: reCAPTCHAToken,
          quotation: this.quotation,
          quotationAmount: quotationAmount,
          role: role
         }

        this.userService.addUser(userData).subscribe((res: any) => {
          if (res.Msg != 'Failed captcha verification') {
            const dialogRef = this.matdialog.open(RegistersuccessComponent, {
              width: '60%', backdropClass: 'blurred', disableClose: true,
              data: { user: '', type: 'registration' }
            });
          }
          if (res.Msg.includes('Successfully registered')) {
            if (email) {
              const mailObj = {
                id: email,
                sub: 'Thank you',
                content: 'Thank you for registering with www.risingsunmart.com'
              }
            }
            this.warnMsg = res.Msg;
            this.successMsg = true;
          }
        });
      });

    }

  }
  acceptTerms() {
    this.check = !this.check
  }
  clearImages() {
    this.imgUrl = '';
  }
  onFileSelected(event: any) {
    // this.userPhotoFile = this.sanitize.bypassSecurityTrustResourceUrl( event.target.files[0]);
    const allowedExtensions = /(\.jpg|\.jpeg)$/i;
    this.userPhotoFile = event.target.files[0];
    let docs = document.getElementById('term')
    if (docs) {
      docs.click();
    }
    if (!allowedExtensions.exec(this.userPhotoFile.name)) {
      this.imageType = true;
      this.imgUrl = '';
    } else {
      this.imageType = false;
      if (this.userPhotoFile.size < 20480) {
        /* less than 20kb */
        this.oversize = false;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imgUrl = e.target.result;
          this.uploaded = true
          this.upload = true
        };
        reader.readAsDataURL(this.userPhotoFile);
      } else {
        this.oversize = true;
        this.imgUrl = '';
      }
    }
  }
  OpenPopup() {
    const dialogRef = this.matdialog.open(TermsconditionsComponent, { maxWidth: '77%', height: '85%', backdropClass: 'blurred', disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        this.check = true;
      }
      else
        this.check = false;
    });
    backdropClass: 'backdropBackground' // This is the "wanted" line
  }

  requestQuotation() {
    this.quotationAmount = 0;
    let userEmail = this.registerForm.value.email.toLowerCase();

      const dialogRef = this.matdialog.open(QuotationPopupComponent,
      {
        data: { user: userEmail},
        backdropClass: 'blurred',
        disableClose: true,
        width: '60%',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.type === 1) {
          this.quotation = true;
          this.quotationAmount = result.quotationAmount;
        }
        else
          this.quotation = false;
      });
  }
}