<div class="container">
  <div class="top-head">
    <div class="left">
      <h3>Dashboard</h3>
    </div>
    <div class="right">
      <div class="notification">
        <div class="number">16</div>
        <img src="../../../assets/images/bell.svg" class="bell" alt="" />
      </div>
      <div class="profile-sec">
        <img
          src="../../../assets/images/Avatar.png"
          alt="rfjryjky"
          style="width: 30px; height: 30px; border-radius: 50px"
        />
        <div class="profile-text">
          <p>Isyeanne Larasati</p>
          <span>Isyeannelp@email.com</span>
        </div>
      </div>
    </div>
  </div>
</div>
