import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cancelpaylater',
  templateUrl: './cancelpaylater.component.html',
  styleUrls: ['./cancelpaylater.component.scss']
})
export class CancelpaylaterComponent implements OnInit {

  constructor(
    private router: Router, public dialogRef: MatDialogRef<CancelpaylaterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { satellogic: boolean }
  ) { }

  ngOnInit(): void {
  }
  onNoClick(action): void {
    this.dialogRef.close(action);
  }
}
