import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  rating: number = 1;
  totalStars: number = 5;
  stars: number[] = [];
  formGroup: any = FormGroup;
  content: any = '';

  constructor(public dialogRef: MatDialogRef<StarRatingComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.stars = Array(this.totalStars)
      .fill(0)
      .map((_, i) => i + 1);
    this.formGroup = new FormGroup({
      feedback: new FormControl(this.content, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500),
      ]),
      rating: new FormControl(this.rating,[Validators.required]),
    });

    if (this.data.task?.feedback) {
      this.formGroup.get('rating').setValue(this.data.task?.feedback.rating);
      this.formGroup.get('feedback').setValue(this.data.task?.feedback.feedback);
      this.rating = this.data.task?.feedback.rating;
    }
  }

  rate(star: number): void {
    this.rating = star;
    this.formGroup.get('rating').setValue(star);
  }

  get feedback(): AbstractControl {
    return this.formGroup.get('feedback');
  }

  getErrorMessage(control: AbstractControl): string {
    if (!control || control.valid) {
      return '';
    }
    if (control.hasError('required')) {
      return 'Cannot be empty';
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('minlength').requiredLength;
      return `Must be at least ${limit} characters`;
    }
    if (control.hasError('maxlength')) {
      const limit = control.getError('maxlength').requiredLength;
      return `Must be no more than ${limit} characters`;
    }
    return 'Must be valid';
  }

  onClose() {
    this.dialogRef.close('close');
  }

  onSave() {
    this.apiService.saveRating(this.data.task.id, this.formGroup.value).subscribe((res: any) => { 
      this.dialogRef.close(this.formGroup.value);
    });
  }

  showIcon(index:number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_outline';
    }
  }
}
