<div class="container">
    <div class="card">
      <div class="task-head">
        <h4>Warning!</h4>
      </div>
      <p>
        For the best user experience and access to additional features, please use our desktop version. Visit our website on your computer to enjoy the full range of functionalities.
      </p>
    </div>
  
    <div class="btns">
      <button mat-flat-button (click)="onNoClick()">Ok, Thanks</button>
    </div>
  </div>