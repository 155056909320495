import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-termsconditions',
  templateUrl: './termsconditions.component.html',
  styleUrls: ['./termsconditions.component.scss']
})
export class TermsconditionsComponent implements OnInit {
  currentDate: any;
  date: number = new Date().getFullYear();

  constructor(
    public dialogRef: MatDialogRef<TermsconditionsComponent>,
    private datePipe: DatePipe
  ) {
    this.currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  }

  onNoClick(click): void {
    this.dialogRef.close(click);

  }

  ngOnInit(): void {
  }

  convertToPDF() {
    const element = document.getElementById('terms');

    const options = {
      margin: [10, 15, 10, 15],
      filename: 'EE_terms_conditions.pdf',
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    
    html2pdf().from(element).set(options).save();
  }
}
