<div class="container">
  <div class="card">
    <div class="task-head">
      <h4>Warning!</h4>
    </div>

    <p *ngIf="data?.message === 'countryblock'">The operator has not allowed the tasking of the sensor from your country
      of registration. Please continue with other sensors.</p>
    <p *ngIf="data?.message === 'fail' || data?.message === 'taskFail'">
      An error occurred during processing and ingestion of the request. Please try after sometime.
    </p>
    <p *ngIf=" data?.message === 'outcomeFail' || data?.message === 'budgetFail'">
      {{data?.response}}
    </p>
    <p *ngIf="data?.message === 'target'">
      {{data?.info}}. Please try again.
    </p>
  </div>

  <div class="btns">
    <button *ngIf="data?.message === 'countryblock'" mat-flat-button (click)="onNoClick()">Continue</button>
    <button *ngIf="data?.message === 'fail'" mat-flat-button routerLink="/task" (click)="onNoClick()">Ok, Thanks</button>
    <button *ngIf="data?.message === 'outcomeFail' || data?.message === 'taskFail'" mat-flat-button (click)="onNoClick()">Ok, Thanks</button>
    <button *ngIf="data?.message === 'budgetFail'" mat-flat-button  (click)="onNoClick()">Ok,
      Thanks</button>
    <button *ngIf="data?.message === 'target'" mat-flat-button (click)="onNoClick()">Ok, Thanks</button>
  </div>
</div>