import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FootersuccessComponent } from '../footersuccess/footersuccess.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  selected = 'option1';
  value: string;
  viewValue: string;
  panelOpenState: boolean = false;
  isFirst = true;
  name = '';
  email = '';
  company = '';
  content = '';
  phone = '';
  option = '';

  minMsgLength = 25;
  maxMsgLength = 500;
  formGroup: FormGroup | any;
  options: any = ['Feedback', 'Requirement', 'Suggestions'];
  selectedOption = 'Requirement';
  submitted: boolean = false;
  validPhone = false;
  respMsg = '';
  clicked: boolean = false;
  constructor(private us: UserService, public dialog: MatDialog, private recaptchaV3Service: ReCaptchaV3Service) {
    this.formGroup = new FormGroup({
      nameField: new FormControl(this.name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      companyField: new FormControl(this.company, [
        Validators.required,
      ]),
      emailField: new FormControl(this.email, [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]),
      // phoneField: new FormControl(this.phone),
      phoneField: new FormControl(this.phone, [
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      contentField: new FormControl(this.content, [
        Validators.required,
        Validators.minLength(this.minMsgLength),
        Validators.maxLength(this.maxMsgLength),
      ]),
    });
  }

  ngOnInit(): void {
  }
  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }
  getErrorMessage(control: AbstractControl): string {
    // Don't say anything if control doesn't exist, or is valid
    if (!control || control.valid) {
      return '';
    }

    // Required always comes first
    if (control.hasError('required')) {
      return 'Cannot be empty';
    }
    if (control.hasError('pattern')) {
      return 'Please enter a valid email';
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('minlength').requiredLength;
      return `Must be at least ${limit} characters`;
    }
    if (control.hasError('maxlength')) {
      const limit = control.getError('maxlength').requiredLength;
      return `Must be no more than ${limit} characters`;
    }

    // Default general error message
    return 'Must be valid';
  }
  keyPress(event: any) {
    const value = event.target.value;
    if (this.validPhone) {
      this.validPhone = false;
    }
    if (value.length === 9) {
      this.validPhone = true;
      return;
    }
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 9 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }
  subjectSelection(option: string) {
    this.selectedOption = option;
  }
  async onSubmit() {
    this.clicked = true;
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      const reCAPTCHAToken = token;
      const username = this.formGroup.get('nameField').value;
      const company = this.formGroup.get('companyField').value;
      const email = this.formGroup.get('emailField').value; // to mail
      const phone = this.formGroup.get('phoneField').value; // to mail
      const messages = this.formGroup.get('contentField').value;
      const subject = this.selectedOption;
      const data = 'contacts';

      const feedbackBody = { username, company, email, phone, subject, messages, reCAPTCHAToken };
      this.us.addFeedback(feedbackBody).subscribe((res: any) => {
        this.respMsg = res.Msg;
        if (res.Msg != 'Failed captcha verification') {
          this.dialog.open(FootersuccessComponent, { width: '50%', backdropClass: 'blurr', data });
        }
      });
      this.submitted = true;
      this.formGroup.reset();
    });
  }

  get nameField(): AbstractControl {
    return this.formGroup.get('nameField');
  }
  get companyField(): AbstractControl {
    return this.formGroup.get('companyField');
  }
  get emailField(): AbstractControl {
    return this.formGroup.get('emailField');
  }
  get phoneField(): AbstractControl {
    return this.formGroup.get('phoneField');
  }
  get contentField(): AbstractControl {
    return this.formGroup.get('contentField');
  }
}
