import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-desktop-version',
  templateUrl: './desktop-version.component.html',
  styleUrls: ['./desktop-version.component.scss']
})
export class DesktopVersionComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DesktopVersionComponent>) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
