<div class="container">
  <div class="card">
    <div class="mb-3 mt-3">
      <h4>Confirmation Request</h4>
    </div>
    <p *ngIf="data.message === 'FEED'">Would you like information feeds on your tasked data? <br>
      By selecting Yes, you also agree to authorise Eartheye Space to run machine learning model(s) related to the
      information feed(s) requested on the tasked data. </p>

      <p *ngIf="data.message === OPERATOR.LBAND">
        Do you want to include the audio recording from the VHF signals detected?
         <span>
          <img
          style="margin-left: 8px; cursor: pointer;margin-top: -4px;"
          width="18px"
          [src]="tooltipImagePath"
          [matTooltip]="'Cost of audio recording = ' + convertCostToPoints(2000)"
        />
        </span>
      </p>

      <p *ngIf="data.message === 'overlapSignal'">
        As the overlap between the two circles is more than 50%, the operator will
        deliver data in one of the two and charge for one. If, however, you need
        data in both, you will be charged for both circles. Please indicate your
        preference.
      </p>
  </div>

  <div class="btns" *ngIf="data.message != 'overlapSignal'">
    <button mat-stroke-button class="custom-button" (click)="onNoClick('no')">No</button>
    <button mat-stroke-button class="submit custom-button" (click)="onNoClick('yes')">Yes</button>
  </div>

  <div class="btns" *ngIf="data.message === 'overlapSignal'">
    <button mat-stroke-button class="custom-button" (click)="onNoClick('no')" style="width: 120px;">Cancel</button>
    <button mat-stroke-button class="custom-button" (click)="onNoClick('yes')" style="width: 120px;">Give me either</button>
    <button mat-stroke-button class="submit custom-button" (click)="onNoClick('both')" style="width: 120px;">Give me both</button>
  </div>
</div>