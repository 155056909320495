import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-paypalsuccess',
  templateUrl: './paypalsuccess.component.html',
  styleUrls: ['./paypalsuccess.component.scss']
})
export class PaypalsuccessComponent implements OnInit {
  currentUser: any;
  paymentType: string | null;
  taskId: string | null;
  taskBody: string | null;
  disable: boolean = true;
  topUp: number = 0;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private localStorageService: DataService
  ) {
    this.spinnerService.show();
    this.disable = true;
  }

  ngOnInit(): void {
    const payerId = this.route.snapshot.queryParamMap.get('PayerID')
    const paymentId = this.route.snapshot.queryParamMap.get('paymentId')
    const paymentMethod = localStorage.getItem('paymentMethod');
    const stripeId = localStorage.getItem('stripeId');
    this.paymentType = localStorage.getItem('keyType');

    if ((this.currentUser?.allocated && this.paymentType === 'recharge') || (this.paymentType === 'subscription' && this.topUp > 0)) {
      localStorage.setItem('routeChange', 'notAllowed');
    }

    Promise.all([
      this.authService.castCurrentUser.subscribe(async (res: any) => {
        this.currentUser = await res;
      }),
    ]).then(() => {
      this.paymentType = localStorage.getItem('keyType');
      this.taskId = '';

      if (localStorage.getItem('keyType') === 'paynow') {
        this.taskBody = JSON.parse(localStorage.getItem('taskBodyData') || '{}');
        try {
          if (payerId && paymentId) {
            this.apiService.approvePayment(payerId, paymentId).subscribe((res: any) => {
              this.apiService
                .taskIdResponse('taskaccept', this.taskBody)
                .subscribe((res: any) => {
                  if (res) {
                    const res = this.apiService
                      .updatePaymentStatus('update payment')
                      .subscribe((res: any) => {
                        if (res.message == 'success') {
                          localStorage.removeItem('taskId');
                          localStorage.removeItem('keyType');
                          localStorage.removeItem('orderID');
                          this.spinnerService.hide();
                          this.disable = false;
                        }
                      });
                  }
                });
            })
          } else {
            this.apiService
              .taskIdResponse('taskaccept', this.taskBody)
              .subscribe((res: any) => {
                if (res) {
                  const res = this.apiService
                    .updatePaymentStatus('update payment')
                    .subscribe((res: any) => {
                      if (res.message == 'success') {
                        localStorage.removeItem('taskId');
                        localStorage.removeItem('keyType');
                        localStorage.removeItem('orderID');
                        this.spinnerService.hide();
                        this.disable = false;
                      }
                    });
                }
              });
          }
        } catch (err) {
        }
      } else if (localStorage.getItem('keyType') == 'recharge' || localStorage.getItem('keyType') == 'subscription') {
        if (this.currentUser?.id) {
          this.userService.personalInfo(this.currentUser?.id).subscribe((res: any) => {
            this.currentUser = res;            
            this.topUp = res?.topUp;
            if (payerId && paymentId) {
              this.apiService.approvePayment(payerId, paymentId).subscribe((result: any) => {
                this.apiService.paymentSuccess(paymentId, this.currentUser.id, paymentMethod).subscribe((result: any) => {
                  this.spinnerService.hide();
                  this.disable = false;
                  this.localStorageService.updateSubscription(true);
                  if (localStorage.getItem('keyType') == 'recharge' && localStorage.getItem('taskData')) {
                    this.taskId = localStorage.getItem('taskData')
                    if (!this.currentUser?.allocated) {
                      this.router.navigate(['/payment'], {
                        queryParams: { taskId: this.taskId, type: 'cart' },
                      });
                    }
                  }
                })
              })
            } else {
                this.apiService.paymentSuccess(stripeId, this.currentUser.id, paymentMethod).subscribe((result: any) => {
                  this.spinnerService.hide();
                  this.disable = false;
                  this.localStorageService.updateSubscription(true);
                  if (localStorage.getItem('keyType') == 'recharge' && localStorage.getItem('taskData')) {
                    this.taskId = localStorage.getItem('taskData')
                    if (!this.currentUser?.allocated) {
                      this.router.navigate(['/payment'], {
                        queryParams: { taskId: this.taskId, type: 'cart' },
                      });
                    }
                  }
                })
            }

         
          });
        }
      }
    });
  }

  deletefromCart(taskid: any) {
    this.apiService.delCartdata(taskid).subscribe((res: any) => { });
  }

  deleteCartData() {
    if (this.taskId) {
    this.apiService.deleteCartData(this.taskId).subscribe((res: any) => {
      this.router.navigate(['/cart']);
     });
    }
  }

  removeItem() {
    localStorage.removeItem('routeChange');
  }
}
