import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HttpClient
} from '@angular/common/http';
import { UserService } from './user.service';
import { User } from '../models/api.models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import { ThemeService } from './theme.service';
@Injectable({ providedIn: 'root' })
export class AuthService {
  commonSer: any;
  url: any;
  private cart: any = [];
  cartBS = new BehaviorSubject<any>(this.cart);
  castCartData = this.cartBS.asObservable();
  currentUser: any = undefined;
  currentUserBS = new BehaviorSubject(this.currentUser);
  setCurrentUser: BehaviorSubject<any>;
  castCurrentUser = this.currentUserBS.asObservable();
  taskServer = environment.eeTaskServer;
  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  euroGerman = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private http: HttpClient, private us: UserService, public datepipe: DatePipe,
    private themeService: ThemeService
    ) {
    this.url = environment.usersServer;
    if (localStorage.getItem('key') !== null) {
      this.userSubject = new BehaviorSubject<User>(
        (JSON.parse(localStorage.getItem('key') || ''))
      );
      this.user = this.userSubject.asObservable();
    }
    else {
      this.userSubject = new BehaviorSubject<any>(undefined);
      this.user = this.userSubject.asObservable();
    }
  }

  public get userValue(): User {
    return this.userSubject.getValue();
  }

  public getCurrentUser(): any {
    // //(environment.eeTaskServer);
    this.castCurrentUser = this.currentUserBS.asObservable();
    let retVal: any;
    let tokenStr: any = sessionStorage.getItem('jwttoken')!;
    if (tokenStr) {
      // const decodedToken: any = tokenStr;;
      if (tokenStr.includes('Msg')) return tokenStr;
      const decodedToken: any = new JwtHelperService().decodeToken(tokenStr);
      // //(decodedToken);
      if (decodedToken) {
        this.currentUserBS.next(decodedToken);
        retVal = decodedToken;
        if (decodedToken?.id) {
          this.us.personalInfo(decodedToken?.id).subscribe((result: any) => {
            localStorage.setItem('key', JSON.stringify(result));
            this.userSubject.next(result);
            this.user = this.userSubject.asObservable();

          });
        }
      }
    }
    return retVal;
  }

  public loginwithEmail(loginparams: string): any {
    this.us.getUserWithEmailnPswrd(loginparams).subscribe(
      (result: any) => {
        // if (result.Msg !== 'User not registered')
        //(result)
        this.saveToken(result);
        //(true);
        return true;
      },
      (err) => {

        // err.console.error();
        //('Wrong credential to login', err);
        return false;
      }
    );



  }
  saveToken(token: any) {
    // //(token)
    if (token.Msg === 'User not registered' || token.Msg === 'Wrong password') {
      this.currentUser = undefined
      // sessionStorage.removeItem(this.commonSer.REFRESH_TOKEN);
      this.currentUserBS.next(this.currentUser);
      this.getCurrentUser();
      // //('hello')
      sessionStorage.setItem('jwttoken', JSON.stringify(token));
    } else {
      // //('hello2')
      // sessionStorage.removeItem(this.JWT_TOKEN);
      sessionStorage.setItem(
        'jwttoken',
        JSON.stringify(token.jwt)
      );
      // sessionStorage.setItem(this.commonSer.REFRESH_TOKEN, JSON.stringify(token.refreshToken));
    }
  }
  logout() {
    sessionStorage.removeItem('jwttoken');
    localStorage.removeItem('key');
    this.currentUserBS.next(this.currentUser);
    localStorage.removeItem('darkMode');
    this.themeService.setDarkMode(false);
  }
  addMoneyToWallet(amount: any, user: any, method: any, top_up: any, subscription: any, topUp: any) {
    //(user)
    const key = 'EarthEye';
    let url = `${this.taskServer}/order/addmoney/${key}`;

    const orderBody = {
      user: { name: user.name, email: user.email, userId: user.id },
      amount: amount,
      paymentMethod: method,
      top_up_amount: top_up,
      subscription_amount: subscription,
      topUp: topUp
    };
    //(orderBody)

    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  addQuotationToTopup(quotationAmount: any, userId: any) {
    return this.http.post(`${environment.eeTaskServer}/quotes/addTopUpQuotes`, {
      quotationAmount: quotationAmount,
      userId: userId
    })
  }
  
  delCartdata(cartid: any, userId: any, sensorId: any) {
    let cartdetail = { taskid: cartid, item: -9, userId, sensorId };
    if (cartid.includes('#')) {
      const taskid = cartid.split('#')[0];
      const item = parseInt(cartid.split('#')[1]);
      cartdetail = { taskid, item, userId, sensorId };
    }
    const res = this.http.post(`${this.taskServer}/cart/delete`, { cartdetail });
    return res;
  }

}