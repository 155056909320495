<div class="container">
    <div class="card">
        <p class="firstPara" style="margin: 0 0px 20px;">This Privacy Policy applies to individuals and organisations
            who use any our Services that hyperlinks to the Eartheye website, marketplace or tasking platforms,
            including through mobile devices or applications (&ldquo;app&rdquo;), including products, software or
            service of ours or our geospatial service providers.<br>
            Depending on the Service, we may provide additional or different privacy statements or notices for specific
            interactions you have with us or to highlight how we use your personal information for specific Services.
            Where we do this, it will be clear which statements apply to which interactions and Services.<br>
            Within our Services, there may be links to third-party websites or applications including those of the
            geospatial service providers.&nbsp;We are not responsible for the content or privacy compliance of
            third-party websites or applications.&nbsp;You should check those websites or applications for their privacy
            statements and terms that apply to them.</p>
        <div>
            <div class="heading1">1.&nbsp; Who we are</div>
            <p style="margin: 0px 17px 20px;">Eartheye is a &nbsp;company operating on a global stage providing
                geospatial satellite transmission services across the world and with relationships with numerous
                geospatial satellite operators through one or more of its legal entities.<br>
                For some Services Eartheye may be considered as the controller of personal information in circumstances
                where it is in a position to make decisions on how customers personal information is used when providing
                the Eartheye Services and as such we are responsible for the obligations of a &ldquo;controller&rdquo;
                in connection with the processing of personal information in line with those applicable laws.In other
                circumstances Eartheye in providing certain aspects of the Services will only be acting as a processor
                of personal information and using personal information in a manner as instructed by its customers. Below
                you can find more information on the cases in which Eartheye is a controller of personal information and
                information on how to contact us.<br>
                Further information about when we act as a processor of personal information and business specific
                details can be found&nbsp;on our website or by request.</p>
            <div class="heading1">2.&nbsp; Sources of personal information</div>
            <p>We collect personal information about you from your interactions with us and from certain third parties
                and other sources (such as your employer or the subscriber providing access to our Services or from
                publicly available sources where permissible).</p>
            <p>We obtain personal information&nbsp;from you:</p>
            <ul>
                <li>Through your interactions with us and our Services, such as, when you purchase or use our Services,
                    request information or contact us for support (please note that we may record or monitor any on-line
                    correspondence for compliance and quality assurance purposes).</li>
                <li>Through your system/device and use of our Services. Our servers, logs and other technologies
                    automatically collect system/device and usage information to help us administer, protect and improve
                    our Services, analyse usage and improve users&rsquo; experience.</li>
                <li>Through cookies and similar technologies included on our Services. More information relating to
                    cookies, and how to control their use can be found&nbsp;on-line on the Eartheye website.</li>
            </ul>
            <p>We also collect personal information about you from&nbsp;third parties&nbsp;such as:</p>
            <ul>
                <li>An organisation to which you belong&nbsp;where that organisation provides you access to our
                    Services.</li>
                <li>Partners and service providers who work with us&nbsp;in relation to the Service&nbsp;and products
                    and services acquired through using the Services.</li>
                <li>Publicly available sources&nbsp;such as public websites, open government databases or other data in
                    the public domain, to help us maintain data accuracy and provide and enhance the Services.</li>
            </ul>
            <div class="heading1">3.&nbsp; What types of personal information we collect</div>
            <p>The type of personal information we collect depends on how you are interacting with us and which Services
                you are purchasing or using.<br>
                In many cases, you can choose whether or not to provide us with personal information, but if you choose
                not to, you may not get full functionality from the Services.<br>
                The personal information we collect consists of the following:</p>
            <ul>
                <li>Name and contact data, such as,&nbsp;first and last name, email address, postal address, phone
                    number, and other similar contact data.</li>
                <li>Account Credentials, such as,&nbsp;passwords and other security information for authentication and
                    access.</li>
                <li>User content, such as, communications and files provided by you in relation to your use of the
                    Services.</li>
                <li>Payment information, such as,&nbsp;payment card number (credit or debit card), and the security code
                    associated with your payment instrument, if you make a payment.</li>
                <li>Device information, such as,&nbsp;information about your&nbsp;device, such as IP address, location
                    or provider.</li>
                <li>Usage information and browsing history,&nbsp;such as, information about how you navigate within our
                    Services, your browsing history and which elements of our Services you use the most.</li>
                <li>Location data, for Services with location-enhanced features. If we need your consent to collect
                    geo-location data, we will collect this separately.</li>
                <li>Demographic information, such as,&nbsp;your country, and preferred language.</li>
            </ul>
            <div class="heading1">4.&nbsp; How we use personal information</div>
            <p>This section includes details of the purposes for which we use personal information and also the
                different legal grounds upon which we process that personal information. We use personal information to
                provide and improve Services and for other purposes that are in our legitimate interests&nbsp;,&nbsp;as
                well as for compliance purposes. Further information is set out below.<br>
                Some laws require us to explain our lawful reason for processing your personal information. We process
                personal information about you on the basis that it is:</p>
            <ul>
                <li>Necessary for the performance of a contract:&nbsp;where we have a contract with you, we will process
                    your personal information in order to fulfil that contract (i.e., to provide you with Services).
                </li>
                <li>In our or a third parties&rsquo; legitimate interests: details of those legitimate interests&nbsp;
                    are set out in more detail below (e.g., provision of Services that we are contractually obliged by a
                    third party, such as your employer or geospatial service providers whose products you are acquiring
                    through the Services and which are being delivered to you).</li>
                <li>Where you give us your consent: we only ask for your consent in relation to specific uses of
                    personal information where we need to and, if we need it, we will collect it separately and make it
                    clear that we are asking for consent.</li>
                <li>For compliance with a legal obligation&nbsp;(e.g., to respond to a court order or a regulator).</li>
            </ul>
            <div class="heading1">5.&nbsp; Legitimate interests for use</div>
            <p>We use personal information for a number of legitimate interests, including to provide and improve
                Services, administer our relationship with you and our business, for marketing and in order to exercise
                our rights and responsibilities. More detailed information about these legitimate interests is set out
                below.</p>
            <ul>
                <li>To set up and administer your account, provide technical and customer support and training, verify
                    your identity, and send important account, subscription, transaction and Service information.</li>
                <li>To administer our relationship with you, our business and our third-party providers (e.g.,
                    geospatial services providers providing products and services through the Eartheye platform).</li>
                <li>We may sometimes share your personal information across our Services so that we can make all of the
                    Services we deliver to you more intuitive (e.g., rather than requiring you to enter the same data
                    many times).</li>
                <li>To contact you in relation to, and conduct, surveys or polls you choose to take part in and to
                    analyse the data collected for market research purposes.</li>
                <li>To display information you choose to post, share, upload or make available in chat rooms, messaging
                    services, and community and event forums (including in community and event profiles) and for related
                    collaboration, peer connection, games and information exchange.</li>
                <li>For internal research and development purposes and to improve, test and enhance the features and
                    functions of our Services.</li>
                <li>To provide you with marketing as
                    permitted by law.</li>
                <li>To&nbsp;meet our internal and external audit requirements, including our information security
                    obligations.</li>
                <li>To enforce our terms and conditions.</li>
                <li>To protect our rights, privacy, safety, networks, systems and &nbsp;property, or those of other
                    persons.</li>
                <li>For&nbsp;the prevention, detection or investigation of a crime or other breach of law or
                    requirement, loss prevention or fraud.</li>
                <li>To comply with requests from courts, law enforcement agencies, regulatory agencies, and other public
                    and government authorities, including where they are outside your country of residence.</li>
                <li>In order to exercise our rights, and to defend ourselves from claims and to comply with laws and
                    regulations that apply to us or third parties with whom we work in providing the Services and the
                    products and services through the Eartheye platforms.</li>
                <li>In order to participate in, or be the subject of, any sale, merger, acquisition, restructure, joint
                    venture, assignment, transfer or other disposition of all or any portion of our business, assets or
                    stock (including in connection with any bankruptcy or similar proceedings).</li>
            </ul>
            <p>Where we rely on legitimate interests as a lawful ground for processing your personal information, we
                balance those interests against your interests, fundamental rights and freedoms. For more information on
                how this balancing exercise has been carried out, please contact our&nbsp;Privacy team as identified in
                the How to Contact Us section. <em>&nbsp;</em></p>
            <div class="heading1">6.&nbsp; Marketing</div>
            <p>We deliver marketing and event communications to you across various platforms such as email, text
                messaging, and online. Where required by law, we will ask you to explicitly opt in to receive marketing
                from us. If we send you a marketing communication it will include instructions on how to opt out of
                receiving these communications in the future.<br>
                Honouring your marketing preferences is important to us. You have the right to opt out of receiving
                direct marketing and targeted online advertising.</p>

            <p><strong style="color: #344054;">How to opt Out of Email Marketing</strong></p>
            <p>Where we send marketing emails, we provide unsubscribe options for your use within our emails. To update
                your email marketing preferences, please visit the applicable email preference centre, a link to which
                will normally be included in emails you receive from us. In addition, you can also use the
                &ldquo;Contact Us&rdquo; feature of a particular Service, discuss with your Eartheye contact or contact
                our&nbsp;Privacy team as identified in the How to Contact Us section.<br>
                Even if you opt out of receiving marketing communications by email, we may still send you service
                communications or important transactional information related to your accounts and subscriptions (for
                purposes such as providing customer support).</p>
            <div class="heading1">7.&nbsp;&nbsp; How we share personal information</div>
            <p style="margin: 1px 21px 20px;">We share personal information within the Eartheye group, with our business
                partners and third-party geospatial service providers, the person providing for your access to our
                Services (if that is not you) and in accordance with law. Our third-party geospatial service providers
                are not permitted to share or use personal information we make available to them for any purpose other
                than to provide products or services to you through the Eartheye platform.</p>
            <p style="margin: 0px 21px 20px;">We share your information for the purposes set out in this Statement, with
                the following categories of recipients:</p>
            <ul>
                <li>Eartheye group companies.</li>
                <li>The person providing your access to our Services (e.g., your employer or our subscriber).</li>
                <li>Third parties where we have a duty to or are permitted to disclose your personal information by law
                    (e.g., government agencies, law enforcement, courts and other public authorities).</li>
                <li>Third parties in order to participate in, or be the subject of, any sale, merger, acquisition,
                    restructure, joint venture, assignment, transfer or other disposition of all or any portion of our
                    business, assets or stock (including in connection with any bankruptcy or similar proceedings), in
                    which case we may disclose your personal data to prospective buyers, sellers, advisers or partners
                    and your data may be a transferred asset in a business sale.</li>
                <li>Third parties where reasonably required to protect our rights, users, systems and Services (e.g.,
                    legal counsel and information security professionals).</li>
                <li>Any person you have asked us to share information with (e.g., if you upload information into a
                    public forum, it is shared publicly).</li>
            </ul>
            <div class="heading1">8.&nbsp;&nbsp; International transfers</div>
            <p style="margin: 0px 23px 20px;">When we transfer personal information internationally, we put in place
                safeguards in accordance with applicable law (including Articles 44 to 50 of the EU General Data
                Protection Regulation).&nbsp; If you would like to know more about our data transfer practices and
                obtain copies of any relevant safeguarding measures, please contact our Privacy team as identified in
                the How to Contact Us section.&nbsp;</p>
            <div class="heading1">9.&nbsp;&nbsp; How we secure personal information</div>
            <p style="margin: 0px 23px 20px;">Eartheye takes the security of personal information seriously and we use
                appropriate technologies and procedures to protect personal information (including administrative,
                technical and physical safeguards) according to the risk level and the service provided.<br>
                Our information security policies and procedures are closely aligned with widely accepted international
                standards and are reviewed regularly and updated as necessary to meet the sensitivity of the personal
                information we handle, our business needs, changes in technology and regulatory requirements. We have
                implemented appropriate information security controls.<br>
                More information on the steps we have taken to ensure our information security practices meet the
                requirements of the EU General Data Protection Regulation are available from our Privacy team as
                identified in the How to Contact Us section.</p>
            <div class="heading1">10.&nbsp;&nbsp; How long we keep personal information</div>
            <p style="margin: 0px 29px 20px;">We calculate retention periods for your personal information in accordance
                with the following criteria:</p>
            <ul>
                <li>The length of time necessary to fulfill the purposes we collected it for.</li>
                <li>When you or your employer (or other subscriber providing for your access to our Services) cease to
                    use our Services.</li>
                <li>The length of time it is reasonable to keep records to demonstrate that we have fulfilled our duties
                    and obligations.</li>
                <li>Any limitation periods within which claims might be made.</li>
                <li>Any retention periods prescribed by law or recommended by regulators, professional bodies or
                    associations.</li>
                <li>The existence of any relevant proceedings.</li>
            </ul>
            <div class="heading1">11.&nbsp;&nbsp; Your rights</div>
            <p style="margin: 0px 26px 20px;">You may have rights under European and other laws to have access to your
                personal information and to ask us to rectify, erase and restrict use of your personal information. You
                may also have rights to object to your personal information being used, to ask for the transfer of
                personal information you have made available to us and to withdraw consent to the use of your personal
                information. Further information on how to exercise your rights is set out below.<br>
                We will honour your rights under applicable data protection laws. You have the following rights under
                European laws and may have similar rights under the laws of other countries.</p>
            <ul>
                <li>Right of subject access: The right to make a written request for details of your personal
                    information and a copy of that personal information.</li>
                <li>Right to rectification: The right to have inaccurate information about you corrected or removed.
                </li>
                <li>Right to erasure ('right to be forgotten'): The right to have certain personal information about you
                    erased.</li>
                <li>Right to restriction of processing: The right to request that your personal information is only used
                    for restricted purposes.</li>
                <li>Right to opt out of marketing: You can manage your marketing preferences by unsubscribe links found
                    in the communications you receive from us or by visiting the applicable preference centre.</li>
                <li>Right to object: The right to object to processing of your personal information in cases where our
                    processing is based on the performance of a task carried out in the public interest or we have let
                    you know the processing is necessary for our or a third party&rsquo;s legitimate interests.</li>
                <li>Right to data portability: The right to ask for the personal information you have made available to
                    us to be transferred to you or a third party in machine-readable format.</li>
                <li>Right to withdraw consent: The right to withdraw any consent you have previously given us to handle
                    your personal information. If you withdraw your consent, this will not affect the lawfulness of our
                    use of your personal information prior to the withdrawal of your consent.</li>
            </ul>
            <p>These rights are not absolute and they do not always apply in all cases.<br>
                In response to a request, we will ask you to verify your identity if we need to, and to provide
                information that helps us to understand your request better. If we do not comply with your request,
                whether in whole or in part, we will explain why.</p>
            <div class="heading1">12.&nbsp;&nbsp; Cookies and similar technologies</div>
            <p style="margin: 0px 28px 20px;">Eartheye and our third-party geospatial providers set and use cookies and
                similar technologies to store and manage user preferences, deliver targeted advertising, enable content,
                and gather analytic and usage data, for example. More information about how we use cookies and similar
                technologies and how you can control and manage them is available from our Privacy team as identified in
                the How to Contact Us section.<br>
                Types of cookies and why we use them:</p>
            <ul style="color: #344054;">
                <li><strong>Absolutely necessary cookies:</strong> These cookies are essential to enable you to move
                    around a website and use its features. Without these cookies, Services you have asked for, like
                    adding items to an online shopping cart, cannot be provided.</li>
                <li><strong>Performance cookies:</strong>&nbsp;These cookies collect information about how you use our
                    websites. Information collected includes, for example, the Internet browsers and operating systems
                    used, the domain name of the website previously visited, the number of visits, average duration of
                    visit, and pages viewed. These cookies only collect information in an aggregated format. Performance
                    cookies are used to improve the user-friendliness of a website and enhance your experience.</li>
                <li><strong>Functionality cookies:</strong>&nbsp;These cookies allow the website to remember choices you
                    make (such as your username or ID, language preference, or the area or region you are in) and
                    provide enhanced, more personal features. These cookies can also be used to remember changes you
                    have made to text size, fonts, and other customisable parts of web pages. These cookies cannot track
                    your browsing activity on other websites.</li>
                <li><strong>Targeting and advertising cookies:</strong>&nbsp;These cookies track browsing habits and are
                    used to deliver targeted (interest-based) advertising. They are also used to limit the number of
                    times you see an ad and to measure the effectiveness of advertising campaigns. They remember that
                    you have visited a website and this information is shared with other organisations, such as
                    advertisers.</li>
            </ul>
            <p><strong style="color: #344054;">Managing cookies</strong></p>
            <p>You can manage website cookies in your browser settings, and you always have the choice to change these
                settings by accepting, rejecting, or deleting cookies. If you choose to change your settings, you may
                find that certain functions and features will not work as intended on the Services. All browser settings
                are slightly different, so to manage cookies, you should refer to the relevant settings within your
                browser.</p>
            <p><strong style="color: #344054;">Connecting via social networks</strong></p>
            <p>Some of our Services may include social networking features, such as the Facebook&reg; &ldquo;Like&rdquo;
                button and widgets, &ldquo;Share&rdquo; buttons, and interactive mini programs. Additionally, you may
                choose to use your own social networking logins from, for example, Facebook or LinkedIn&reg;, to log
                into some of our Services. If you choose to connect using a social networking or similar service, we may
                receive and store authentication information from that service to enable you to log in and other
                information that you may choose to share when you connect with these Services. These Services may
                collect information such as the web pages you visited and IP addresses and may set cookies to enable
                features to function properly. We are not responsible for the security or privacy of any information
                collected by these third parties. You should review the privacy statements or policies applicable to the
                third-party services you connect to, use, or access. If you do not want your personal information shared
                with your social media account provider or other users of the social media service, please do not
                connect your social media account with your account for the Services and do not participate in social
                sharing on the Services.</p>
            <div class="heading1">13.&nbsp;&nbsp; How to contact us</div>
            <p style="margin: 0px 28px 20px;">If you have any questions, comments, complaints or suggestions in relation
                to data protection or this Statement, or any other concerns about the way in which we process
                information about you, please contact our Privacy Team at&nbsp;<a
                    href="mailto:privacy@eartheye.space">privacy@eartheye.space</a></p>
            <p style="margin: 0px 28px 20px;"><strong style="color: #344054;">Filing a Complaint:</strong>&nbsp;If you
                are not content with how Eartheye manages your personal information, you can lodge a complaint with a
                privacy supervisory authority. In the European Economic Area, the relevant supervisory authority is the
                one in the country or territory where:</p>
            <ul>
                <li>You are resident</li>
                <li>You work, or</li>
                <li>The alleged infringement took place</li>
            </ul>
            <div class="heading1">14.&nbsp;&nbsp;Updates to this statement</div>
            <p style="margin: 0px 26px 20px;">This Statement may be subject to updates. Any material future changes or
                additions to the processing of personal information as described in this Statement affecting you will be
                communicated to you through an appropriate channel.</p>
        </div>

        <p style="margin: 25px 3px 4px;">
            Eartheye Privacy Policy V03. – 12/2022
        </p>
    </div>
</div>