<div class="container">
  <ngx-spinner color="white" type="ball-clip-rotate" [fullScreen]="false" size="medium"  [ngClass]="disable ? 'spinner' : 'spinner-custom'"></ngx-spinner>
  <div class="card" *ngIf="paymentType === 'paynow'">
    <div class="tasks-head">
      <h4>Success!</h4>

      <p>Thank you for your payment. We will immediately process your order.You can check your receipt on the Orders
        Page. </p>
    </div>

    <div class="btns">
      <button mat-flat-button routerLink="/orders" (click)="removeItem()">Go to Orders</button>
      <button mat-stroked-button routerLink="/cart" (click)="removeItem()">Pay Another Task</button>

    </div>
  </div>

  <div class="card" *ngIf="paymentType === 'recharge'">
    <div class="tasks-head">
      <h4>Success!</h4>

      <p *ngIf="(!this.currentUser?.allocated || (this.currentUser?.allocated && this.currentUser?.cpc) || this.currentUser?.pointsEligible)">Thanks, credit balance is updated successfully. </p>
      <p *ngIf="this.currentUser?.allocated && !this.currentUser?.cpc && !this.currentUser?.pointsEligible">Thanks, credit balance is updated successfully.<br/>
        Do you want to allocate your topped-up amount across a select number of operators? 
      </p>
    </div>

    <div class="btns" *ngIf="!this.currentUser?.allocated || (this.currentUser?.allocated && this.currentUser?.cpc) || this.currentUser?.pointsEligible">
      <button mat-stroked-button routerLink="/profile" style="width: 125px;" (click)="removeItem()">My Profile</button>
      <button mat-flat-button routerLink="/task" style="margin: 0;width: 125px;" (click)="removeItem()">My Task</button>
    </div>

    <div class="btns" *ngIf="this.currentUser?.allocated && !this.currentUser?.cpc && !this.currentUser?.pointsEligible">
      <button mat-stroked-button routerLink="/task" style="width: 125px;" (click)="deleteCartData();removeItem()">No, please</button> 
      <button mat-flat-button routerLink="/allocation" style="margin: 0;width: 125px;" (click)="removeItem()">Yes, please</button>
    </div>
  </div>

  <div class="card" *ngIf="paymentType === 'subscription'">
    <!-- <div class="task-head" *ngIf="this.currentUser?.cpc === false && !this.currentUser?.pointsEligible">
      <h4>Success!</h4>

      <p *ngIf="topUp > 0">Thank you for subscribing to the Sat-Tasking platform. <br />
        Do you want to allocate your topped-up amount across a select number of operators?
      </p>
      <p *ngIf="topUp === 0">Thank you for subscribing to the Sat-Tasking platformn.
      </p>
      <div class="btns" *ngIf="topUp > 0">
        <button mat-stroked-button routerLink="/task" style="width: 125px;" (click)="removeItem()">No, please</button>
        <button mat-flat-button routerLink="/allocation" style="margin: 0;width: 125px;" (click)="removeItem()">Yes,
          please</button>
      </div>
      <div class="btns" *ngIf="topUp === 0">
        <button mat-stroked-button routerLink="/profile" style="width: 125px;" (click)="removeItem()">My Profile</button>
        <button mat-flat-button routerLink="/task" style="margin: 0;width: 125px;" (click)="removeItem()">My Task</button>
      </div>
    </div> -->

    <div class="task-head">
      <h4>Success!</h4>
      <p>Thank you for subscribing to the Sat-Tasking platformn.
      </p>
      <div class="btns">
        <button mat-stroked-button routerLink="/profile" style="width: 125px;" (click)="removeItem()">My Profile</button>
        <button mat-flat-button routerLink="/task" style="margin: 0;width: 125px;" (click)="removeItem()">My Task</button>
      </div>
    </div>
  </div>
</div>