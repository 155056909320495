<div id="scrollcustom" class="scrollbar">
  <div *ngIf="filteredData?.length === 0" class="container no-task">
    <img *ngIf="!darkTheme && !isInIframe" src="../../assets/images/notask.svg">
    <img *ngIf="darkTheme && !isInIframe" src="../../assets/images/whitelogo.png" width="80px"> 
    <h3>You have no transactions</h3>
  </div>
  <mat-card *ngIf="tableDataLength > 0" class="card mat-elevation-z1">
    <table mat-table [dataSource]="dataSource" matSort class="table-class">

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Transaction Date (UTC)</th>
        <td mat-cell *matCellDef="let row" id="orderdate">
          <span class="td-first">{{ row.createdAt | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="gatewayTransactionId">
        <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
        <td mat-cell *matCellDef="let row">
          <span>{{ row.gatewayTransactionId }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="referenceId">
        <th mat-header-cell *matHeaderCellDef>Receipt Number</th>
        <td mat-cell *matCellDef="let row">{{ row.depositNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row">
          <span>$ {{ row.amount | customNumberFormat }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="referenceType">
        <th mat-header-cell *matHeaderCellDef>Reference Type</th>
        <td mat-cell *matCellDef="let row">{{ row.referenceType }}</td>
      </ng-container>

      <ng-container matColumnDef="receipt">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          <span class="th-width"> Receipt</span>
        </th>
        <td mat-cell *matCellDef="let row">
          <span style="display: flex;justify-content: center;">
            <img *ngIf="(row.transactionStatus && row.referenceType !== 'Bank Transfer'
            || (row.transactionType === 'offline' && row.referenceType === 'Bank Transfer'))" [src]="invoiceImagePath" alt="" style="cursor: pointer"
              (click)="generateReceipt(row, 'Receipt')" />
            <img *ngIf="!row.transactionStatus || (row.referenceType === 'Bank Transfer' && row?.transactionType != 'offline')" src="../../../assets/images/receiptdisable.png" alt="" />
          </span>

        </td>
      </ng-container>

      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          <span class="th-width"> Invoice</span>
        </th>
        <td mat-cell *matCellDef="let row">
          <span style="display: flex;justify-content: center;">
            <img *ngIf="row.transactionStatus && (row.referenceType === 'Bank Transfer' && row?.transactionType != 'offline')" [src]="receiptsImagePath" alt="" style="cursor: pointer"
              (click)="generateReceipt(row, 'Invoice')" />
            <img *ngIf="!row.transactionStatus || row.referenceType != 'Bank Transfer' || (row.referenceType === 'Bank Transfer' && row?.transactionType === 'offline')" src="../../../assets/images/receiptdisable.png" alt="" />
          </span>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card>
</div>