import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-debitsuccess',
  templateUrl: './debitsuccess.component.html',
  styleUrls: ['./debitsuccess.component.scss']
})
export class DebitsuccessComponent implements OnInit {
  darkTheme: any;

  constructor(
    private router: Router, public dialogRef: MatDialogRef<DebitsuccessComponent>,
    private dataService: DataService
    ) { 
      this.dataService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;
      });
    }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  async closeNow() {
    await this.dialogRef.close();
    await this.router.navigate(['/cart']);
  }

}
