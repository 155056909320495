import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-footersuccess',
  templateUrl: './footersuccess.component.html',
  styleUrls: ['./footersuccess.component.scss']
})
export class FootersuccessComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<FootersuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
