import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-notask',
  templateUrl: './notask.component.html',
  styleUrls: ['./notask.component.scss']
})
export class NotaskComponent implements OnInit {
  @Input() data: any;
  @Input() order: any;
  @Input() allTask: any;
  darkTheme: boolean;
  isInIframe: boolean;

  constructor(
    private localStorageService: DataService,
    private themeService: ThemeService
  ) {
    this.isInIframe = this.themeService.isInIframe();

    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    });
  }

  ngOnInit(): void {
  }

}
