<div class="container">
  <img *ngIf="!isInIframe" src="../../../assets/images/logo.png" alt="logo" style="width: 40%" />
  <h4>Forgot Password</h4>
  <p>
    Enter your email for the verification process and we will send a link to
    your email.
  </p>
  <form class="form-vertical" [formGroup]="registerForm">

    <div class="form-group">
      <mat-label>Email<span>*</span></mat-label>
      <mat-form-field appearance="outline">
        <input matInput type="text" placeholder="Your email" formControlName="email"
          [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }" id="inputEmail4" (keyup)="keyPress($event)" />
        <mat-error *ngIf="f.email.touched && f.email.errors" style="color: #EF3061;font-size: 12px; font-weight: 600;">
          <mat-error *ngIf="f.email.errors.required">Please enter a valid email</mat-error>
          <mat-error *ngIf="f.email.errors.pattern"> Please enter a valid email</mat-error>
        </mat-error>
        <div *ngIf="warnMsg" class="error">{{ warnMsg }}</div>

      </mat-form-field>
      <button mat-raised-button color="primary" (click)="forgotPswrd()" [disabled]="registerForm.invalid || clicked">
        SUBMIT
      </button>
    </div>
  </form>
</div>