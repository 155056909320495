<div class="container no-task">
    <img *ngIf="!darkTheme && !isInIframe" src="../../assets/images/notask.svg">
    <img *ngIf="darkTheme && !isInIframe" src="../../assets/images/whitelogo.png" width="80px">
    <h3 *ngIf="data && !order">Please wait while we load your tasks</h3>
    <h3 *ngIf="order">You have no orders</h3>
    <div *ngIf="!data && !order && !allTask">
        <h3>You have no tasks </h3> 
        <span>Click on New to start tasking</span>
    </div>
    <div *ngIf="allTask && !data">
        <h3>You have no recent tasks </h3> 
        <span>Please click on All tab to view your previous tasks</span>
    </div>
</div>
