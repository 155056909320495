import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { AdvanceSettingsComponent } from '../taskcost/advance-settings/advance-settings.component';
import { RegistersuccessComponent } from '../userregister/registersuccess/registersuccess.component';
import { environment } from 'src/environments/environment';
import { AllocationNotificationComponent } from './allocation-notification/allocation-notification.component';
import { PopupService } from 'src/app/services/popup.service';
@Component({
  selector: 'app-fund-allocation',
  templateUrl: './fund-allocation.component.html',
  styleUrls: ['./fund-allocation.component.scss']
})
export class FundAllocationComponent implements OnInit {
  displayedColumns: string[] = ['sensorType', 'sensorName', 'taskingTier', 'allocation', 'amount', 'numberOfTasks', 'edit', 'delete'];
  storageData: any;
  click: boolean = false;
  totalAllocation: number = 0;
  dataSource: MatTableDataSource<any>;
  tablelength: any;
  tableData: any;
  addedData: any;
  userDetails: any;
  topUpAmount: number;
  taskId: string | null;
  currentUsers: any;
  darkTheme: boolean;
  deletedImagePath: string;
  topUpBalance: any;
  allocationDisabled: boolean = true;

  constructor(public dialog: MatDialog,
    private dataService: DataService,
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private popupService: PopupService,
    private localStorageService: DataService
    ) { 
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;

        this.deletedImagePath = this.darkTheme
        ? '../../../assets/images/deleted-dark.png'
        : '../../../assets/images/deleted.png';
      });
    }

  ngOnInit() {
   localStorage.setItem('routeChange', 'allocation');
   this.tablelength = 0;
   this.dropAllocation();
   this.storageData = this.dataService.getPayment();
   this.authService.user.subscribe(user => this.currentUsers = user);
   this.userService.personalInfo(this.currentUsers?.id).subscribe(async (res: any) => {
    this.userDetails = res;
    this.topUpAmount = this.userDetails.topUp;
  });
  this.taskId = localStorage.getItem('taskData')
  }

  getData() {
    this.apiService.getAllocation().subscribe((res: any) => {
      if (res?.allocations[0]?.sensor.length > 0) {
        this.tableData = res.allocations[0].sensor;
        this.addedData = this.tableData.filter(data => data.isAmountAllocated === false && data.isDelete === false);
        this.dataSource = new MatTableDataSource<any>(this.addedData);
        this.tablelength = this.addedData?.length;
        this.calculateTotalAmount(this.addedData); 
      } else {
        this.tablelength = 0;
      }
    });
  }

  allocateSensor() {
    let balanceAmount = 100 - this.totalAllocation;
    let topUpBalance = (balanceAmount) ? (this.topUpAmount * balanceAmount / 100).toFixed(2) : 0;
    this.topUpBalance = Number(topUpBalance);
    if (this.totalAllocation == 100) {
      this.allocationDisabled = false;
    }
    if (Number(topUpBalance) < Number(environment.minAllocationAmt)) {
      const message = "You do not have sufficient balance to further allocate."
      this.popupService.openPopup(AllocationNotificationComponent, message, '', '40%');
      this.allocationDisabled = false;
      return;
    }
    const dialog = this.dialog.open(AdvanceSettingsComponent, {
      minWidth: '45%',
      backdropClass: 'blurred',
      disableClose: true,
      data: {type: 'allocation', balance: balanceAmount, allocatedData: this.addedData, topUpAmount: this.topUpAmount, topUpBalance: topUpBalance}
    });

    dialog.afterClosed().subscribe((result) => {
        this.getData(); 
    });
  }

  allocate() {
    this.click = true;
    this.apiService.allocateUser(this.topUpBalance).subscribe((res: any) => {
      if (this.taskId) {
        this.router.navigate(['/payment'], {
          queryParams: { taskId: this.taskId, type: 'cart' },
        });
      } else if (!this.userDetails.subscribed) {
        this.router.navigate(['/deposits']);
      } else {
        const dialogRef = this.dialog.open(RegistersuccessComponent, {
          data: { user: this.userDetails, type: 'success'},
          backdropClass: 'blurred',
          disableClose: true,
          width: '50%',
        });
      }
      localStorage.removeItem('taskData');
    });
  }

  editAllocation(row: any) {
    let balanceAmount = 100 - this.totalAllocation;
    let topUpBalance = (balanceAmount) ? (this.topUpAmount * balanceAmount / 100).toFixed(2) : 0;
    this.topUpBalance = topUpBalance;
    if (this.totalAllocation == 100) {
      this.allocationDisabled = false;
    }
    const dialog = this.dialog.open(AdvanceSettingsComponent, {
      minWidth: '45%',
      backdropClass: 'blurred',
      disableClose: true,
      data: { type: 'allocation', row: row, balance: balanceAmount, allocatedData: this.addedData, topUpAmount: this.topUpAmount, topUpBalance: topUpBalance }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result != 'close') {
        this.getData();
      }
    });
  }

  deleteAllocation(row: any) {
    this.apiService.deleteAllocation(row._id).subscribe((res: any) => {
      this.getData(); 
    });
  }

  calculateTotalAmount(tableData: any) {
    this.totalAllocation = 0;
    this.totalAllocation = tableData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amountPercentage,
      0
    );
    if (this.totalAllocation == 100) {
      this.allocationDisabled = false;
    }  
    
  }

  dropAllocation() {
    this.apiService.deleteAllocationTable().subscribe((res: any) => {
      this.getData();
    });

    if (this.taskId) {
      this.apiService.deleteCartData(this.taskId).subscribe((res: any) => { 
        this.router.navigate(['/cart']);
      });
    }
  }

  removeItem() {
    localStorage.removeItem('routeChange');
  }
}