<div class="head">
  <h4>Success!</h4>
</div>
<hr />
<div class="container">
  <p>
    Your password has been changed. Return to the login screen to sign in.
  </p>

  <div class="btns">
    <button
      mat-flat-button
      color="primary"
      class="accept"
      (click)="onNoClick()"
    >
      Return to Login
    </button>
  </div>
</div>
