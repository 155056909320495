import { Component, OnInit, NgModule } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/services/theme.service';
import { supportEmail } from 'src/app/services/constants';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?!gmail|yahoo|outlook|hotmail)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.scss'],
})
export class UserloginComponent {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 5,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  showPassword: boolean = true;
  registerForm: any = FormGroup;
  verification: any;
  submitted = false;
  otpVerification: boolean = false;
  warnMsg: any;
  warnMsgEmail: any;
  warn = false;
  warnEmail = false;
  rememberMe: boolean;
  verifyMsg: string;
  verify: boolean = false;
  verifyEmail: boolean = false;
  clicked: boolean = false;
  verifyUser: boolean = true;
  check: string | null;
  otp: string;
  otpMsg: string = '';
  display: any;
  public timerInterval: any;
  demoView: boolean = false;
  firstLogin: boolean = false;
  id: any;
  isInIframe: boolean;
  token: any;
  supportEmail: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service, private route: ActivatedRoute,
    private authService: AuthService,
    private themeService: ThemeService
    ) {     
      this.route.queryParams.subscribe(params => {
        this.id = params['token'];
        if (this.id != undefined) {
          this.userService.checkVerifyUser(this.id).subscribe(
            (result: any) => {
                if (result?.Msg != 'Verified') {
                  this.router.navigate(['not-found']);
                }
            });
        }
      });      

      if(environment.demoView === 'true') {
        this.demoView = true;
      }
      else {
        this.demoView = false;
      }

      this.isInIframe = this.themeService.isInIframe();
    }



  get f() { return this.registerForm.controls; }

  keyPress(event: any) {
    this.warnMsgEmail = '';
    this.clicked = false;
    this.warnMsg = '';
    let value = event.target.value;
    if (value.length === 0) {
      this.warnEmail = false;
    }
  }

  keyPressPassword(event: any) {
    this.warnMsg = '';
    this.clicked = false;
    let value = event.target.value;
    if (value.length === 0) {
      this.warn = false;
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //True if all the fields are filled
    if (this.submitted) {
      this.submitted = false;
      this.clicked = true;
      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        const reCAPTCHAToken = encodeURIComponent(token);
        const userEmail = encodeURIComponent(this.registerForm.get('email').value.toLowerCase()); // to mail
        const pswrd = encodeURIComponent(this.registerForm.get('password').value);
        const email = this.registerForm.get('email').value.toLowerCase();

        const loginparams = { userEmail, pswrd, reCAPTCHAToken };
        this.userService.login(loginparams).subscribe(
          (result: any) => {
            if (result){
              if (result?.verified == false) {
                this.verifyEmail = true;
                this.verify = false;
                this.supportEmail = supportEmail
                this.verifyMsg = "Your access has been revoked. Please contact the administrator via "
                return;
              }
              if(result?.Msg ==="click link in the email"){
                this.verify = true;
                this.verifyMsg = "Your account is not verified. Click the link in the email to verify your account."
                return;
              }
            }
             if (this.demoView) {
                sessionStorage.setItem(
                  'jwttoken',
                  JSON.stringify(result.jwt)
                );
                let tokenStr: any = sessionStorage.getItem('jwttoken')!;
                localStorage.removeItem('username');
                localStorage.removeItem('RememberMe');
    
                if (this.rememberMe) {
                  localStorage.setItem('username', email);
                  localStorage.setItem('RememberMe', JSON.stringify(this.rememberMe));
                }
                this.router.navigate(['task']);
              } else {
                this.verifyUser = false;
                this.firstLogin = result?.loggedIn;
                this.start();
              }
          },
          (error: any) => {
            if (error.error.error === 'Failed captcha verification') {
              this.verify = true;
            }
            else if (error.error.error) {
              if (error.error.error === 'Invalid Username') {
                this.warnEmail = true;
                this.warnMsgEmail = error.error.error;
                this.warnMsg = '';
              } else if (error.error.error === 'Invalid password') {
                this.warn = true;
                this.warnMsg = 'Invalid Password';
                this.warnMsgEmail = '';
              }
              else {
                this.warn = true;
                this.warnMsg = error.error.error;
                this.warnMsgEmail = '';
              }
            }

          }
        );
      });
    }
  }

  start() {
    this.timer(1);
  }

  timer(minute) {
    let seconds: number = minute * 60 * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${Math.floor(seconds / 60)}:${textSec}`;
      if (this.display == '0:00') {
        this.verifyUser = true;
      }

      if (seconds == 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.authService.logout();
    this.check = localStorage.getItem('RememberMe');
    localStorage.removeItem('routeChange');
    //Add User form validations
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50),]]
    });

    if (localStorage.getItem('RememberMe') === 'true') {
      this.rememberMe = true;
      this.registerForm.patchValue({
        email: localStorage.getItem('username'),
      });
    }
  }
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  setremember() {
    this.rememberMe = !this.rememberMe;
  }

  handeOtpChange(value: string[]): void {
    this.otpMsg = '';
    this.otpVerification = false;
  }

  handleFillEvent(value: string): void {
    this.otp = value;
    this.otpVerification = true;
  }

  resendOTP() {
    this.otpMsg = '';
    this.onSubmit();
  }

  //otp//
  onNext() {
    this.otpVerification = false;
    const email = this.registerForm.get('email').value.toLowerCase();
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      const reCAPTCHAToken = encodeURIComponent(token);
      const userEmail = encodeURIComponent(this.registerForm.get('email').value.toLowerCase()); // to mail
      const pswrd = encodeURIComponent(this.registerForm.get('password').value);
      const otp = this.otp
      const loginparams = { userEmail, pswrd, reCAPTCHAToken, otp };
      this.userService.loginWithOtp(loginparams).subscribe(

        (result: any) => {
          if (result) {
            sessionStorage.setItem(
              'jwttoken',
              JSON.stringify(result.jwt)
            );
            let tokenStr: any = sessionStorage.getItem('jwttoken')!;
            localStorage.removeItem('username');
            localStorage.removeItem('RememberMe');

            if (this.rememberMe) {
              localStorage.setItem('username', email);
              localStorage.setItem('RememberMe', JSON.stringify(this.rememberMe));
            }
            const decodedToken: any = new JwtHelperService().decodeToken(result?.jwt);
            if (decodedToken?.subscribed === true || this.demoView) {
              this.router.navigate(['task']);
            } else {
              this.router.navigate(['subscription']);
            }
          }
        },
        (error: any) => {
          if (error.error.error === 'Failed captcha verification') {
            this.verify = true;
          } else if (error.error.error === 'OTP verification failed') {
            this.otpMsg = 'Please enter a valid code';
            sessionStorage.removeItem('jwttoken');
          } else if (error.error.error === 'OTP expired after 1h') {
            this.otpMsg = 'The latest OTP has expired since it`s been over one hour.';
            sessionStorage.removeItem('jwttoken');
          }
        }
      );


    });
  }
}
