import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './paymentsuccess.component.html',
  styleUrls: ['./paymentsuccess.component.scss']
})
export class PaymentsuccessComponent implements OnInit {
  paymentType: string | null;

  constructor() { }

  ngOnInit(): void {
    this.paymentType = localStorage.getItem('keyType');
    localStorage.setItem('routeChange', 'notAllowed');

  }

  removeItem() {
    localStorage.removeItem('routeChange');
  }
}
