import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-usl-eula',
  templateUrl: './usl-eula.component.html',
  styleUrls: ['./usl-eula.component.scss']
})
export class UslEulaComponent implements OnInit, AfterViewInit {
  currentUser: any;
  currentDate: any;
  @Input() operator: any;
  @Input() userDetails: any;

  constructor(private authService: AuthService, private userService: UserService,
    private utilsService: UtilsService
  ) {
    this.currentDate = new Date();    
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let eulaContent = document.getElementById('uslEulaContent')!.innerHTML;
    html2pdf().from(eulaContent).toPdf().get('pdf').then((pdf) => {
      const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      this.utilsService.setContent(url);
    });
  }

}
