import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-uploadphoto',
  templateUrl: './uploadphoto.component.html',
  styleUrls: ['./uploadphoto.component.scss']
})
export class UploadphotoComponent implements OnInit {

  imageURL: string;
  uploadForm: FormGroup;
  userid: any;
  currentUser: any;
  userImgUrl: string;
  userPhotoFile: any | Blob;
  imageType: boolean;
  oversize: boolean;
  userDetails: any;
  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<UploadphotoComponent>, private authService: AuthService, private us: UserService) {
    // Reactive Form
    this.uploadForm = this.fb.group({
      avatar: [null],
      name: ['']
    })
  }

  ngOnInit(): void {
    this.authService.user.subscribe(user => this.currentUser = user);
    this.userDetails = this.currentUser;
  }
  onFileSelected(event: any, inputFrom: string) {
    // this.userPhotoFile = this.sanitize.bypassSecurityTrustResourceUrl( event.target.files[0]);
    const allowedExtensions = /(\.jpg|\.jpeg)$/i;
    this.userPhotoFile = event.target.files[0];
    const reader = new FileReader();
    if (!allowedExtensions.exec(this.userPhotoFile.name)) {
      this.imageType = true;
    }

    // if (inputFrom === 'gpay') {
    // this.qrCodePhotoFile = photoFile;
    // reader.onload = (e: any) => {
    //   this.qrImgUrl = (e.target.result);
    // };
    // } else if (inputFrom === 'paytm') {
    // this.qrCodePhotoFile = photoFile;
    // reader.onload = (e: any) => {
    //   this.qrImgUrl = (e.target.result);
    // };
    //} 
    else {
      this.imageType = false;
      if (this.userPhotoFile.size < 20480) {
        /* less than 20kb */
        this.oversize = false;
        const reader = new FileReader();
        reader.readAsDataURL(this.userPhotoFile);
        this.uploadPhoto(this.userPhotoFile)
      } else {
        this.oversize = true;
      }
    }

    // reader.readAsDataURL(userPhotoFile);
    // if (inputFrom === 'gpay') {
    //   this.qrCodePhotoFile = event.target.files[0];
    // }
    // this.uploadPhoto()
  }
  uploadPhoto(userPhotoFile) {
    const formData = new FormData();
    formData.append('phone', this.userDetails.phone);
    formData.append('photoFile', userPhotoFile);
    this.userImgUrl = '';
    this.userDetails.photo =
      'img' +
      this.userDetails.phone +
      '.' +
      this.userPhotoFile.name.split('.')[1];
    this.us.changePhoto(this.currentUser.id, formData).subscribe((res: any) => {
      this.authService.currentUserBS.next(this.userDetails);
      this.dialogRef.close({ msg: "OK" });
    });

    // window.location.reload();
  }

  onNoClick(): void {
    this.dialogRef.close({ msg: "NO" });
  }
}
