import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.scss']
})
export class HowDoesItWorkComponent implements OnInit {
  currentUser: any;
  login: boolean;

  constructor(private authService: AuthService, private userService: UserService
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser();
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;
      if (this.currentUser?.id) {
        this.userService.personalInfo(this.currentUser?.id).subscribe(async (res: any) => {
          this.login = res?.subscribed;
        });
      } else {
        this.login = false;
      }
      castUser.unsubscribe();
    });
  }

}
