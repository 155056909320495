<div class="footer">

  <div class="left">
    <a [routerLink]='[userFlag ? "/task" : "/login"]' routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      Home
    </a>
    <a routerLink="/howitworks" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      How It Works
    </a>

    <a *ngIf="!userFlag" routerLink="/requestdemo" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      Request A Demo
    </a>
    <a *ngIf="userFlag" routerLink="/whats-new" routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }">
    What's New 
    <img class="animated-image" width="25px" src="../../assets/images/new-feature.png">
    </a>
    <a routerLink="/support" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      Contact Us
    </a>
    <!-- <a
        routerLink="/disclaimer"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Disclaimer
      </a> -->
    <a routerLink="/privacypolicy" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      Privacy Policy
    </a>
  </div>
  <div class="rights">
    <div class="copy">
      <span>Copyright {{ date }}</span>
    </div>
    <img *ngIf="!darkTheme && !isInIframe" src="../../../../assets/images/icon.png" alt="logo" class="logo"/>
    <img *ngIf="darkTheme && !isInIframe" src="../../../../assets/images/whitelogo.png" alt="logo" class="dark-logo"/>
  </div>

</div>