import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-collection-popup',
  templateUrl: './collection-popup.component.html',
  styleUrls: ['./collection-popup.component.scss']
})
export class CollectionPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CollectionPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { message: any, type: any}) { }

  ngOnInit(): void {
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
}
