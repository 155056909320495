import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-catalogs-popup',
  templateUrl: './catalogs-popup.component.html',
  styleUrls: ['./catalogs-popup.component.scss'],
})
export class CatalogsPopupComponent implements OnInit {
  catlogData: any[] = [];
  informationServices: any[] = [];
  displayedColumns: string[] = ['name', 'price'];
  load: boolean = false;
  loadMessage: string;
  dataSource = new MatTableDataSource<any>();
  darkTheme: boolean;
  tooltipImagePath: string;
  activeUser: any;
  error: boolean;
  
  constructor(
    private catlogRef: MatDialogRef<CatalogsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private apiService: ApiService,
    private dataService: DataService,
    private authService: AuthService,
  ) {
    this.apiService.getInformationServices(this.data.sensorTypes,this.data.taskId).subscribe((result: any) => {
      if (result.msg === 'Success') {
        this.load = false;
        this.informationServices = result.informationServices;
        this.catlogData = this.informationServices.filter((item) => item.isChecked);
        this.dataSource = new MatTableDataSource<any>(this.informationServices);
      } else {
        this.loadMessage = 'Sorry, no information feeds found';
      }
    });

    this.dataService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
      this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
    });
  }

  ngOnInit(): void {
    this.load = true;
    this.loadMessage = 'Getting information feeds...';
    this.authService.user.subscribe((user) => (this.activeUser = user));
  }

  onNoClick(click): void {
    if (click === 'yes') {
      if (this.data?.isOpenWater && (this.catlogData[0].taskingArea != 'water' && this.catlogData[0].taskingArea != 'both')) {
        this.error = true
      } else if (!this.data?.isOpenWater && (this.catlogData[0].taskingArea != 'land' && this.catlogData[0].taskingArea != 'both')) {
        this.error = true
      } else {
        this.catlogRef.close(this.catlogData);
      }
    } else if (click === 'no') {
      this.catlogRef.close(click);
    }
  }

  onItemChange(item: any): void {
    this.error = false;
    const previouslySelectedItem = this.catlogData.find(i => i.isChecked);
    if (previouslySelectedItem && previouslySelectedItem._id !== item._id) {
      previouslySelectedItem.isChecked = false;
    }

    item.isChecked = !item.isChecked;

    if (item.isChecked) {
      if (this.data?.isOpenWater && (item.taskingArea != 'water' && item.taskingArea != 'both')) {
       this.error = true
      } else if (!this.data?.isOpenWater && (item.taskingArea != 'land' && item.taskingArea != 'both')) {
        this.error = true
      }
      this.catlogData = [item];
    } else {
      this.catlogData = this.catlogData.filter(i => i._id !== item._id);
    }
  }
  getIsToolTip(row: { description: string; f1Score?: number }): string {
    let tooltip = row.description;
    return tooltip;
  }

}
