import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private progressSubject = new Subject<any>();
  downloadObj: any;
  isDownloading: boolean = false; 
  private isDownloadingSubject = new BehaviorSubject<boolean>(false);
  isDownloading$ = this.isDownloadingSubject.asObservable();

  setProgress(sensorObj: any) {
    this.downloadObj = { progress: sensorObj.progress , sensorId: sensorObj?.sensorId, sceneId: sensorObj?.sceneId, taskId: sensorObj?. taskId, downloadClick: sensorObj?.download, product:sensorObj?.product};
    this.progressSubject.next(this.downloadObj);   
    this.isDownloadingSubject.next(true); 
  }

  getProgress(): any {
    return this.downloadObj;
  }

  getProgressObservable() {
    return this.progressSubject.asObservable();
    this.isDownloadingSubject.next(false);
  }
  setDownloadingStatus(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }
}
