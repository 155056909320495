import { Component, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  durationInSeconds = 3;
  constructor(private _snackBar: MatSnackBar, private router: Router) {}

  openSnackBar_old(toast: any, action: string) {
    this._snackBar.open(toast.msg, action, {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openSnackBar(toast: any, action: string) {
    let extraClasses;
    if (toast.type == 'error') {
      extraClasses = ['background-red'];
    } else if (toast.type == 'warning') {
      extraClasses = ['background-Yellow'];
    } else {
      extraClasses = ['background-Green'];
    }

    let snackBarRef = this._snackBar.open(toast.msg, action, {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: extraClasses,
    });

    snackBarRef.afterDismissed().subscribe(() => {
      // console.log('The snackbar was dismissed');
    });

    if (action.includes('Cart')) {
      snackBarRef.onAction().subscribe(() => {
        // console.log('The snackbar action was triggered!');
        this.router.navigate(['/cart']);
      });
    }

    // snackBarRef.dismiss();
  }
}

export interface Toast {
  type: ToastType;
  title: string;
  body: string;
  delay: number;
}
export type ToastType = 'success' | 'error' | 'warning';

@Component({
  selector: 'snack-bar',
  template: ``,
  styles: [
    `
      .example {
        color: hotpink;
      }
      .example {
        color: hotpink;
      }
    `,
  ],
})
export class SnackbarComponent {}
