import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-budgetpopup',
  templateUrl: './budgetpopup.component.html',
  styleUrls: ['./budgetpopup.component.scss']
})

export class BudgetpopupComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  formGroup: FormGroup | any;
  formTaskGroup: FormGroup | any;
 isTask:boolean =false;
 projName: any;
 taskName: any;
 isFormTaskValid = false;
 isBudgetNameFocusedOrHasValue =false;
 isSaveClicked = false;
  intentOption: ({ name: string; value: number; description: string; key: string; } | { name: string; value: number; key: string; description?: undefined; })[];
  strategyOptions: ({ name: string; value: string; description: string; } | { name: string; value: string; description?: undefined; })[];
  TASKFREQUENCY: { name: string; value: number; color: string; selected: boolean; available: boolean; }[];
  purposeOption: { code: string; name: string; }[];
  resetButton: boolean = true;
  isBudgetEqual: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
   if(this.data?.data?.budgetName){
    this.isTask=true
   }

   if (this.data?.data?.budget) {
    this.isBudgetEqual = this.isRangesAreEqual(this.data?.data?.budget);
   }

    this.formGroup = new FormGroup({
      budgetName: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
    });
    this.formTaskGroup = new FormGroup({
      project: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
      taskname: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(35)])
    });
    this.formTaskGroup.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });
    this.intentOption = this.apiService.intentOption;
    this.strategyOptions = this.apiService.strategyOptions;
    this.TASKFREQUENCY = this.apiService.TASKFREQUENCY;
    this.apiService.getPurpose().subscribe((res: any) => {
      this.purposeOption = this.utilsService.sortPurposeOptions(res?.purposeList);
    });
  }

  onNoClick(action): void {
    if(action === "save"){
      this.isSaveClicked = true;
      this.resetButton = false;
      const data = this.formGroup.value.budgetName;
      this.dialogRef.close({ action, data });
    }
    else if(action === "task"){
      this.isTask =true
      this.resetButton = true;;
     this.formGroup.reset();
    }else if(action==="save_task"){
      this.isSaveClicked = true;
      const formValues = this.formTaskGroup.value;
      const data = {
        ...formValues,
        sensors: JSON.stringify(this.data.data.sensors),
        taskStrategy:this.strategyOptions[0],
        intent:this.intentOption[0],
        purpose:this.purposeOption[0]
      };
  
    
      this.dialogRef.close({ action, data });
    }else if(action === "cancel")
      {

        if(this.data.data.budgetName){
          this.dialogRef.close();

        }else{
          this.isTask =false
          this.resetFormGroup(this.formTaskGroup);
        }
         
    }else{
      this.dialogRef.close({ action });
    }
    
  }
  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }
  nameChng(e: any, i: number) {
    if (i === 1) {
     
      if (e.target.value.length > 4) {
        this.projName = e.target.value;
      } else {
        this.projName = undefined;
      }
    } else {
      if (e.target.value.length > 4) {
        this.taskName = e.target.value;
      } else {
        this.taskName = undefined;
      }
    }
    
  }
  get fields() {
    return this.formTaskGroup.controls;
  }
  get budgetField(){
    return this.formGroup.controls;
  }
  resetSorting() {
    if (this.sort) {
      this.sort.sort({ id: '', start: 'asc', disableClear: false });
    }
  }
  updateAllComplete(e: any, i: number) {
    
  }
  checkFormValidity() {
    const formValid = this.formTaskGroup.valid;
  
    this.isFormTaskValid = formValid
  }
  preventFocus(event: Event) {
    event.preventDefault();
  }
  onFocus(controlName: string) {
    this.formGroup.get(controlName).markAsTouched();
    this.updateButtonState();
  }
  onFocusOrInput() {
    this.updateButtonState();
  }
  onBlur() {
    this.updateButtonState();
  }

  updateButtonState() {
    const budgetNameControl = this.formGroup.get('budgetName');
  
    this.isBudgetNameFocusedOrHasValue = budgetNameControl && (budgetNameControl.value?.length > 4 && budgetNameControl.touched);    
    if(this.isBudgetNameFocusedOrHasValue) this.resetButton = false;
  }
  resetFormGroup(formGroup: any) {
    formGroup.reset();
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsUntouched();
    });
  }
  onFocusProject(controlName: string) {
    this.formTaskGroup.get(controlName).markAsTouched();
  }

  isRangesAreEqual(budget: any[]): boolean {
    const budgetItem = budget[0];
    return budgetItem.minRange === budgetItem.maxRange;
  }

}
