import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const API_URL = 'https://eartheye-eetaskapi-dev.xminds.in/api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  url: any;
  currentUser: any;
  server: any;
  constructor(private http: HttpClient) {
    this.server = environment.usersServer;
    this.url = environment.usersServer;
  }
  jwtService: JwtHelperService = new JwtHelperService();

  addUser(formData: any): any {
    return this.http.post(`${this.url}/admin/register`, formData);
  }

  getUserWithEmailnPswrd(loginparams: string) {
    const req = this.http.get(`${this.url}/admin/finduser/${loginparams}`);
    return req;
  }

  login(loginparams: any) {
    const req = this.http.post(`${this.url}/admin/login`, loginparams);
    return req;
  }

  loginWithOtp(loginparams: any) {
    const req = this.http.post(`${this.url}/admin/verifyUser`, loginparams);
    return req;
  }

  refreshToken(refreshToken: any) {
    const rt = new FormData();
    rt.append('refreshToken', refreshToken);
    const req = this.http
      .get(`${API_URL}/admin/refresh/${refreshToken}`);
    return req;
  }

  chkRegisteredUser(email: any) {
    const req = this.http.post(`${this.url}/admin/findUser`, email);
    return req;
  }

  checkVerifyUser(userid: any) {
    const req = this.http.get(`${this.url}/admin/verifyEmail/${userid}`);
    return req;
  }

  sendMail(mailObj: any) {
    return this.http.post(`${this.url}/admin/sendmail`, mailObj);
  }

  forgotPassword(userEmail: string) {
    const obj = { email: userEmail };
    return this.http.post(`${this.url}/admin/forgot`, obj);
  }

  addFeedback(feedbackBody: any) {
    return this.http.post(`${this.url}/admin/addfb`, feedbackBody);
  }

  changePswrd(id: string, pswrdObj: any) {
    return this.http.post(`${this.url}/admin/changePassword/${id}`, pswrdObj);
  }

  changeAddress(id: string, addressObj: any) {
    return this.http.post(`${this.url}/admin/changeAddress/${id}`, addressObj);
  }

  updateStorage(id: string, storageObj: any) {
    return this.http.post(`${this.url}/admin/addStorage/${id}`, storageObj);
  }

  resetPswrd(token: string, pswrdObj: any) {
    return this.http.post(`${this.url}/admin/resetPassword/${token}`, pswrdObj);
  }

  updateField(id: any, email: any, displayName: any) {
    const obj = {email: email, displayName: displayName };
    return this.http.post(`${this.url}/admin/updateField/${id}`, obj);
  }
  getUseCases(purposeId) {
    return this.http.get(`${this.url}/sensors/useCases?purposeId=${purposeId}`);
  }

  getSensors(subscrptn: string, userId: any, intentOption: any, getSensorObj: any) {
    let intent: any;
    if (intentOption === 'Internal Use') intent = 'internalUse';
    else if (intentOption === 'Product For Distribution') intent = 'productForDistribution';
    else if (intentOption === 'Both') intent = 'internalAndDistribution';
    else intent = intentOption;

    return this.http.post(`${this.url}/sensors/${subscrptn}/${userId}/${intent}/`, getSensorObj);
  }

  getSensorsPolicy(subscrptn: string, userId: any) {
    return this.http.get(`${this.url}/sensorPolicy/${subscrptn}/${userId}/`);
  }

  updateAccountBalance(user: any, amount: number, point: any = null) {
    const obj = {
      user: user,
      amount: amount,
      point: point
    };
    const res = this.http.post(`${this.url}/admin/updateAccountBalance`, obj);
    return res;
  }

  updateBalance(user: any, order: any) {
    const orderBody = {
      user: { name: user.name, email: user.email, userId: user.id },
      order,
    };
    const res = this.http.post(`${this.url}/admin/updateCreditBalance`, {orderBody});
    return res;
  }

  changePhoto(id: string, formData: FormData): any {
    return this.http
      .post(`${this.url}/admin/changePhoto/${id}`, formData);

  }

  requestDemo(formData: any): any {
    return this.http.post(`${this.url}/admin/addRequestDemo`, formData);
  }

  personalInfo(userId: any) {
    return this.http.get(`${this.url}/admin/personal-info/${userId}`);
  }

  updateTheme(userId: any, darkMode: boolean): any {
    let theme = {
      customerid: userId,
      darkMode: darkMode
    }
    return this.http.post(`${this.url}/admin/saveColorMode`, theme);
  }

}