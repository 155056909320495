import { Directive, ElementRef, OnInit } from '@angular/core';

import {LiveAnnouncer} from '@angular/cdk/a11y';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit,AfterViewInit {
  panelOpenState = false;
  constructor() { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
 
    
  }
  ngAfterViewInit() {
  
  }

  /** Announce the change in sort state for assistive technology. */


}


